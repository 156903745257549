import { ComponentPortal, Portal } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { Subject, takeUntil, tap } from 'rxjs';
import { EFabricationEndpointType } from '../../../enums/cbp-enum';
import { ELibraryRoutes } from '../../../enums/de-routes.enum';
import { DATA_TOKEN, ICBPFormInputs } from '../../../models/ui/IInjectionToken';
import { IExplorerUI } from '../../../models/ui/i-explorer-ui';
import { CustomBannerPointsActionsService } from '../../../services/custom-banner-points-actions.service';
import { LibraryNavigationService } from '../../../services/library-navigation.service';
import { CustomBannerPointsService } from '../../../services/custom-banner-points.service';
import { ManageTablesPortalComponent } from '../manage-tables-portal/manage-tables-portal.component';

@Component({
  selector: 'manage-tables-layout',
  templateUrl: './manage-tables-layout.component.html',
  styleUrls: ['./manage-tables-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageTablesLayoutComponent implements OnInit, OnDestroy {
  @Input() ui!: IExplorerUI;
  @Input() actions!: any[];

  selectedPortal!: Portal<any>;
  componentPortal!: ComponentPortal<ManageTablesPortalComponent>;

  nextLayout = null;
  unsubscribe = new Subject<void>();

  @ViewChild('formHasChangeDialog', {
    read: IgxDialogComponent,
    static: false,
  })
  formHasChangeDialog!: IgxDialogComponent;

  @Output() navigateTo = new EventEmitter<ELibraryRoutes>();

  constructor(
    public cbpActionsService: CustomBannerPointsActionsService,
    public cbpService: CustomBannerPointsService,
    private libraryNavigation: LibraryNavigationService
  ) {}

  ngOnInit() {
    this.cbpActionsService
      .createSavedBannerDimensions(this.ui.brand_id)
      .subscribe((res) => {
        this.cbpActionsService.bannerDimensions.next(res);
      });

    this.cbpService
      .getFabricationsByType(
        this.ui.brand_id,
        EFabricationEndpointType.Subpopulation
      )
      .subscribe((res) => {
        this.cbpActionsService.bannerSubpopulations.next(res.items);
      });

    this.libraryNavigation.DENavigationStatus$.pipe(
      tap((navigationStatus: any) => {
        if (navigationStatus) {
          switch (navigationStatus.currentRoute) {
            case ELibraryRoutes.ManageTables:
              this.assignComponentPortal(ManageTablesPortalComponent, {
                uiData: this.ui,
                selectedTabIndex: 0,
              });
              break;
          }
        }
      }),
      takeUntil(this.unsubscribe)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  assignComponentPortal(
    component: any,
    injectionTokenData?: ICBPFormInputs
  ): void {
    const tokenData = injectionTokenData
      ? this.createInjector(injectionTokenData)
      : null;

    if (component) {
      this.componentPortal = new ComponentPortal(component, null, tokenData);
      this.selectedPortal = this.componentPortal;
    }
  }

  navigateBack(): void {
    const navStatus = this.libraryNavigation.DENavigationStatus.getValue();
    if (navStatus?.backTo) this.navigateTo.emit(navStatus.backTo);
  }

  createInjector(data: ICBPFormInputs): Injector {
    return Injector.create({
      providers: [
        {
          provide: DATA_TOKEN,
          useValue: { ...data },
        },
      ],
    });
  }

  onComponentRendering(evt: any): void {
    const navigateAway = evt.instance.navigateAway;

    if (navigateAway) {
      navigateAway
        .asObservable()
        .pipe(
          tap((value: boolean) => {
            if (value) {
              this.navigateBack();
            }
          }),
          takeUntil(this.unsubscribe)
        )
        .subscribe();
    }
  }
}
