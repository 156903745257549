import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrowserRouterService {
  navigate(route: string): void {
    this.pushNavigationState(route);
    window.dispatchEvent(new Event('popstate'));
  }

  pushNavigationState(route: string): void {
    window.history.pushState(null, '', route);
  }

  extractUUIDsFromPath(): any {
    let path = window.location.pathname.split('/data-explorer')[1];
    const regex = /\/([a-z]+)\/([0-9a-fA-F-]{36})/g;
    const result = {};
    let match;

    if (path) {
      const firstUUID = path.match(/^\/([0-9a-fA-F-]{36})/);
      if (firstUUID) {
        result['mission'] = firstUUID[1];
        path = path.replace(firstUUID[0], '');
      }

      while ((match = regex.exec(path)) !== null) {
        result[match[1]] = match[2];
      }
      return result;
    } else {
      return null;
    }
  }
}
