import { DEFAULT_DIALOG_CONFIG, DialogModule } from '@angular/cdk/dialog';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule, PercentPipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HammerModule } from '@angular/platform-browser';
import {
  IgxAccordionModule,
  IgxActionStripModule,
  IgxAutocompleteModule,
  IgxButtonModule,
  IgxCardModule,
  IgxCheckboxModule,
  IgxComboModule,
  IgxDialogModule,
  IgxDividerModule,
  IgxDragDropModule,
  IgxDropDownModule,
  IgxGridModule,
  IgxInputGroupModule,
  IgxListModule,
  IgxRadioModule,
  IgxSimpleComboModule,
  IgxSnackbarModule,
  IgxSplitterModule,
  IgxStepperModule,
  IgxSwitchModule,
  IgxTabsModule,
  IgxToggleModule,
  IgxTooltipModule,
  IgxTreeGridModule,
  IgxTreeModule,
} from '@infragistics/igniteui-angular';
import { defineCustomElements } from '@infragistics/igniteui-dockmanager/loader';
import { TranslateModule } from '@ngx-translate/core';
import {
  IgxAnnotationLayerProxyModule,
  IgxCalloutLayerModule,
  IgxDataChartAnnotationModule,
  IgxDataChartCategoryCoreModule,
  IgxDataChartCategoryModule,
  IgxDataChartCoreModule,
  IgxDataChartInteractivityModule,
  IgxDataChartStackedModule,
  IgxDataChartVerticalCategoryModule,
  IgxLegendModule,
  IgxStackedFragmentSeriesModule,
} from 'igniteui-angular-charts';
import { BuilderComponent } from './components/builder/builder.component';
import { CalculationsComponent } from './components/builder/calculations/calculations.component';
import { FiltersComponent } from './components/builder/filters/filters.component';
import { AdvancedFilterComponent } from './components/builder/modals/advanced-filter/advanced-filter.component';
import { AgeRangeEditorComponent } from './components/builder/modals/age-range-editor/age-range-editor.component';
import { CalculationsEditorComponent } from './components/builder/modals/calculations-editor/calculations-editor.component';
import { DimensionFilterComponent } from './components/builder/modals/dimension-filter/dimension-filter.component';
import { CrosstabQuestionComponent } from './components/builder/providers/advanced/crosstab-question/crosstab-question.component';
import { QuestionSelectorComponent } from './components/builder/providers/advanced/crosstab-question/question-selector/question-selector.component';
import { CustomAgeRangesComponent } from './components/builder/providers/advanced/custom-age-ranges/custom-age-ranges.component';
import { ColumnTotalComponent } from './components/builder/providers/calculations/column-total/column-total.component';
import { MeanScoreComponent } from './components/builder/providers/calculations/mean-score/mean-score.component';
import { ScaleRankTopBottomComponent } from './components/builder/providers/calculations/scale-rank-top-bottom/scale-rank-top-bottom.component';
import { SpamResponsesComponent } from './components/builder/providers/calculations/spam-trimmed-responses/spam-responses.component';
import { StatTestingComponent } from './components/builder/providers/calculations/stat-testing/stat-testing.component';
import { CleanedResponsesFilterComponent } from './components/builder/providers/filters/cleaned-responses/cleaned-responses-filter.component';
import { ClusterGroupsFilterComponent } from './components/builder/providers/filters/cluster-groups/cluster-groups-filter.component';
import { CompletesFilterComponent } from './components/builder/providers/filters/completes/completes-filter.component';
import { DemographicsFilterComponent } from './components/builder/providers/filters/demographics/demographics-filter.component';
import { ProfilingFilterComponent } from './components/builder/providers/filters/profiling/profiling-filter.component';
import { RespondentsFilterComponent } from './components/builder/providers/filters/respondents/respondents-filter.component';
import { TirmmedResponsesFilterComponent } from './components/builder/providers/filters/trimmed-responses/trimmed-responses-filter.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { StackedBarChartComponent } from './components/charts/stacked-bar-chart/stacked-bar-chart.component';
import { CbpConditionalSelectorFieldComponent } from './components/custom-banner-points/cbp-conditional-selector-field/cbp-conditional-selector-field.component';

import { CbpFabricationFormComponent } from './components/custom-banner-points/cbp-fabrication-form/cbp-fabrication-form.component';

import { CbpConfirmActionDialogComponent } from './components/custom-banner-points/cbp-confirm-action-dialog/cbp-confirm-action-dialog.component';
import { CbpParametersSelectorComponent } from './components/custom-banner-points/cbp-parameters-selector/cbp-parameters-selector.component';
import { CbpSearchBarComponent } from './components/custom-banner-points/cbp-search-bar/cbp-search-bar.component';
import { CbpTabHeaderComponent } from './components/custom-banner-points/cbp-tab-header/cbp-tab-header.component';
import { CustomBannerPointsGridComponent } from './components/custom-banner-points/custom-banner-points-grid/custom-banner-points-grid.component';
import { CustomBannerPointsLayoutComponent } from './components/custom-banner-points/custom-banner-points-layout/custom-banner-points-layout.component';
import { CustomBannerPointsTabsComponent } from './components/custom-banner-points/custom-banner-points-tabs/custom-banner-points-tabs.component';
import { ActionIconComponent } from './components/explorer/action-icon/action-icon.component';
import { ExplorerComponent } from './components/explorer/explorer.component';
import { MainHeaderComponent } from './components/explorer/main-header/main-header.component';
import { MissionRemoveDialogComponent } from './components/explorer/mission-remove-dialog/mission-remove-dialog.component';
import { SelectedMissionDropdownComponent } from './components/explorer/selected-mission-dropdown/selected-mission-dropdown.component';
import { SelectedMissionItemComponent } from './components/explorer/selected-mission-item/selected-mission-item.component';
import { SelectedMissionQuestionsListComponent } from './components/explorer/selected-mission-questions-list/selected-mission-questions-list.component';
import { SurveyExporterComponent } from './components/explorer/survey-exporter/survey-exporter.component';
import { DuplicateTableComponent } from './components/grid/duplicate-table/duplicate-table.component';
import { GridComponent } from './components/grid/grid.component';
import { CrosstabMayProcessDialogComponent } from './components/grid/modals/crosstab-may-process-dialog/crosstab-may-process-dialog.component';
import { CrosstabWontProcessDialogComponent } from './components/grid/modals/crosstab-wont-process-dialog/crosstab-wont-process-dialog.component';
import { TabelInformationComponent } from './components/grid/table-information/table-information.component';
import { TabelViewValidatorComponent } from './components/grid/tableview-validator/tabelview-validator.component';
import { ConditionalTextPipe } from './pipes/conditional-text.pipe';
import { Markdown2HtmlPipe } from './pipes/markdown2html.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { TableViewFilterTagPipe } from './pipes/table-view-filter-tag.pipe';
import { TextOrDefaultPipe } from './pipes/text-or-default.pipe';
import { CrosstabConfigService } from './services/crosstab-config.service';
import { CrosstabDataService } from './services/crosstab-data.service';
import { SuzyDataService } from './services/suzy-data.service';
import { SuzySDK } from './services/suzy-sdk';
import { TableViewService } from './services/table-view.service';

import { CdkTableModule } from '@angular/cdk/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { DerivedQuestionSelectorComponent } from './components/builder/providers/advanced/crosstab-question/derived-question-selector/derived-question-selector.component';
import { SubpopulationsFiltersComponent } from './components/builder/providers/filters/subpopulations/subpopulations-filters.component';
import { BaseFabricationFormComponent } from './components/custom-banner-points/base-fabrication-form/base-fabrication-form.component';
import { CbpFabricationDetailDialogComponent } from './components/custom-banner-points/cbp-fabrication-detail-dialog/cbp-fabrication-detail-dialog.component';
import { CbpSelectTreeFieldComponent } from './components/custom-banner-points/cbp-select-tree-field/cbp-select-tree-field.component';
import { ActionDropdownItemComponent } from './components/explorer/action-dropdown-item/action-dropdown-item.component';
import { ManageTablesGridComponent } from './components/manage-tables/manage-tables-grid/manage-tables-grid.component';
import { ManageTablesLayoutComponent } from './components/manage-tables/manage-tables-layout/manage-tables-layout.component';
import { ManageTablesPortalComponent } from './components/manage-tables/manage-tables-portal/manage-tables-portal.component';
import { StringGeneratorPipe } from './pipes/string-generator.pipe';
import { AsyncSetupNotifyComponent } from './components/grid/async-setup-notify/async-setup-notify.component';
import { ObscureEmailPipe } from './pipes/obscure-email.pipe';
import { LongPollingDataService } from './services/long-polling-data.service';

defineCustomElements();
@NgModule({
  imports: [
    CommonModule,
    HammerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IgxTooltipModule,
    IgxStepperModule,
    IgxDividerModule,
    IgxInputGroupModule,
    IgxDialogModule,
    IgxDragDropModule,
    IgxSwitchModule,
    IgxListModule,
    IgxAutocompleteModule,
    IgxButtonModule,
    IgxDropDownModule,
    IgxSplitterModule,
    IgxTreeModule,
    IgxSimpleComboModule,
    IgxComboModule,
    IgxGridModule,
    IgxActionStripModule,
    IgxCheckboxModule,
    IgxLegendModule,
    IgxDataChartCoreModule,
    IgxDataChartCategoryCoreModule,
    IgxDataChartCategoryModule,
    IgxDataChartAnnotationModule,
    IgxDataChartInteractivityModule,
    IgxDataChartVerticalCategoryModule,
    IgxDataChartStackedModule,
    IgxStackedFragmentSeriesModule,
    IgxAnnotationLayerProxyModule,
    IgxCalloutLayerModule,
    IgxDataChartAnnotationModule,
    IgxSnackbarModule,
    IgxAccordionModule,
    IgxCardModule,
    TranslateModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    IgxTabsModule,
    PortalModule,
    DialogModule,
    CdkTableModule,
    MatSortModule,
    MatTableModule,
    MatSelectModule,
    IgxRadioModule,
    MatButtonToggleModule,
    MatTreeModule,
    MatAutocompleteModule,
    IgxToggleModule,
  ],
  declarations: [
    ExplorerComponent,
    GridComponent,
    BuilderComponent,
    DimensionFilterComponent,
    QuestionSelectorComponent,
    CrosstabQuestionComponent,
    BarChartComponent,
    StackedBarChartComponent,
    DuplicateTableComponent,
    TabelViewValidatorComponent,
    MeanScoreComponent,
    FiltersComponent,
    AdvancedFilterComponent,
    CompletesFilterComponent,
    DemographicsFilterComponent,
    ClusterGroupsFilterComponent,
    RespondentsFilterComponent,
    SurveyExporterComponent,
    ProfilingFilterComponent,
    CustomAgeRangesComponent,
    AgeRangeEditorComponent,
    CalculationsComponent,
    CalculationsEditorComponent,
    ScaleRankTopBottomComponent,
    StatTestingComponent,
    ColumnTotalComponent,
    TabelInformationComponent,
    SpamResponsesComponent,
    TirmmedResponsesFilterComponent,
    TableViewFilterTagPipe,
    CleanedResponsesFilterComponent,
    SelectedMissionDropdownComponent,
    SelectedMissionQuestionsListComponent,
    ActionIconComponent,
    MainHeaderComponent,
    SelectedMissionItemComponent,
    MissionRemoveDialogComponent,
    CustomBannerPointsLayoutComponent,
    CbpTabHeaderComponent,
    CbpSearchBarComponent,
    CustomBannerPointsGridComponent,
    CustomBannerPointsTabsComponent,
    CbpFabricationFormComponent,
    TextOrDefaultPipe,
    CbpConditionalSelectorFieldComponent,
    CbpConfirmActionDialogComponent,
    CbpParametersSelectorComponent,
    ManageTablesLayoutComponent,
    ManageTablesGridComponent,
    ManageTablesPortalComponent,
    ConditionalTextPipe,
    StringGeneratorPipe,
    ActionDropdownItemComponent,
    CrosstabWontProcessDialogComponent,
    CrosstabMayProcessDialogComponent,
    SubpopulationsFiltersComponent,
    BaseFabricationFormComponent,
    CbpFabricationDetailDialogComponent,
    DerivedQuestionSelectorComponent,
    CbpSelectTreeFieldComponent,
    AsyncSetupNotifyComponent,
    ObscureEmailPipe,
  ],
  exports: [ExplorerComponent],
  providers: [
    {
      provide: SuzySDK,
      deps: [HttpClient],
    },
    {
      provide: CrosstabDataService,
      deps: [HttpClient],
    },
    CrosstabConfigService,
    SuzyDataService,
    TableViewService,
    PercentPipe,
    StripHtmlPipe,
    Markdown2HtmlPipe,
    TableViewFilterTagPipe,
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: {
        hasBackdrop: true,
        disableClose: true,
        autoFocus: false,
        minWidth: 450,
        minHeight: 250,
        backdropClass: ['dialog-backdrop'],
      },
    },
    LongPollingDataService
  ],
  entryComponents: [
    ManageTablesLayoutComponent,
    CustomBannerPointsLayoutComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DataExplorerModule {}
