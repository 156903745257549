import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ActionKind,
  ActionStructureMultipleChoiceKind,
  MissionKind,
} from '@asksuzy/typescript-sdk';
import {
  ConnectedPositioningStrategy,
  HorizontalAlignment,
  VerticalAlignment,
} from '@infragistics/igniteui-angular';
import { IMission } from '../../../models/suzy/IMission';

@Component({
  selector: 'selected-mission-item',
  templateUrl: './selected-mission-item.component.html',
  styleUrls: ['./selected-mission-item.component.scss'],
})
export class SelectedMissionItemComponent implements OnChanges {
  overlaySettings = {
    positionStrategy: new ConnectedPositioningStrategy({
      horizontalDirection: HorizontalAlignment.Left,
      horizontalStartPoint: HorizontalAlignment.Right,
      verticalStartPoint: VerticalAlignment.Bottom,
    }),
  };

  canExportSurvey = false;
  isBuilderView = false;

  @Input() mission!: IMission;
  @Input() canExport = true;

  @Output() exportSurvey = new EventEmitter<any>();
  @Output() remove = new EventEmitter<boolean>(false);
  @Output() navigateTo = new EventEmitter<IMission | null>();

  ngOnChanges(changes: SimpleChanges): void {
    const missionChanges = changes['mission'];

    if (missionChanges?.currentValue) {
      this.canExportSurvey =
        missionChanges.currentValue.mission_kind !== MissionKind.standard;
    }
  }

  onExportSurvey(type: string): void {
    this.exportSurvey.emit(type);
  }

  removeMission(): void {
    this.remove.emit(true);
  }

  navigateToRoute(mission: IMission): void {
    this.navigateTo.emit(mission);
  }

  getActionType(mission: any): string {
    let actionType = '';

    if (mission.mission_kind === MissionKind.standard) {
      if (mission.first_action.action_kind === ActionKind.multiplechoice) {
        if (
          mission.first_action.multiple_choice.multiple_choice_kind ===
          ActionStructureMultipleChoiceKind.rating
        ) {
          actionType = 'rating';
        }
      }

      actionType = ActionKind[mission.first_action.action_kind];
    } else {
      if (
        mission.mission_kind === MissionKind.external_cint &&
        mission.monadic_enabled
      ) {
        actionType = 'external_cint_monadic';
      }

      if (MissionKind[mission.mission_kind] === 'splittesting') {
        actionType = 'monadictesting';
      }

      actionType = MissionKind[mission.mission_kind];
    }

    return `actionType.${actionType}`;
  }
}
