import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  SecurityContext,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ActionKind,
  ActionKindVariant,
  ActionStructureGridKind,
  ActionStructureMultipleChoiceKind,
  MissionKind,
} from '@asksuzy/typescript-sdk';
import {
  catchError,
  finalize,
  forkJoin,
  map,
  merge,
  Observable,
  of,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { IAction } from '../../../models/suzy/IAction';
import { IExplorerUI } from '../../../models/ui/i-explorer-ui';
import { ITableView, ITableViewName } from '../../../models/ui/ITableView';
import { SuzyDataService } from '../../../services/suzy-data.service';
import { TableViewService } from '../../../services/table-view.service';
import {
  ESegmentTrackActionKind,
  ISegmentTrackApplyTableViewData,
} from '../../../models/ui/i-segment-track-action';
import { SegmentService } from '../../../services/segment.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

interface DuplicateTableViewForm {
  includeDemographics: FormControl<boolean>;
  includeSegmentsPanels: FormControl<boolean>;
  includeQuotas: FormControl<boolean>;
  includeCrosstabQuestion: FormControl<boolean>;
  includeExternalAttributes: FormControl<boolean>;
  includeFilters: FormControl<boolean>;
  includeCalculations: FormControl<boolean>;
  includeFabrications: FormControl<boolean>;
  actions: FormArray<FormGroup<DuplicateActions | null>>;
}

interface DuplicateActions {
  ActionId: FormControl<string | null>;
  Included: FormControl<boolean | null>;
  IsCalculationsApplicable: FormControl<boolean | null>;
  IsStatTestingApplicable: FormControl<boolean | null>;
  TableViewName: FormControl<string | null>;
}

interface DuplicateActionItem {
  tableViewName: string;
  tableViewConfigKey: string;
  defaultTableView: ITableView;
  newTableView?: ITableView;
  inprogress?: boolean;
  failed?: boolean;
  success?: boolean;
}

@Component({
  selector: 'data-explorer-duplicate-table',
  templateUrl: './duplicate-table.component.html',
  styleUrls: ['./duplicate-table.component.scss'],
})
export class DuplicateTableComponent implements OnInit, OnDestroy {
  private unsubscribeAll = new Subject<void>();
  private tableViewHasNetOrMeans!: boolean;
  private tableViewHasStatTesting!: boolean;

  public get masterCheckbox() {
    const options = {
      checked: false,
      include: 0,
      indeterminate: false,
    };
    this.actionsFormArray?.controls.forEach((option) => {
      if (option.controls?.Included.value) {
        options.include++;
        options.checked = true;
      }
    });
    if (options.include === this.actionsFormArray.controls.length) {
      options.indeterminate = false;
    } else {
      if (options.include > 0) {
        options.indeterminate = true;
      }
    }
    return options;
  }

  ui!: IExplorerUI;
  tableView!: ITableView;
  defatultTableViews!: Array<ITableView>;
  pageForm!: FormGroup<DuplicateTableViewForm>;
  actionsFormArray!: FormArray<FormGroup<DuplicateActions | null>>;
  actions!: Array<{ action: IAction; duplicate?: DuplicateActionItem }>;
  actionsLoaded!: boolean;
  hasIncludeCrosstabQuestion!: boolean;
  hasIncludeQuotas!: boolean;
  hasIncludeSegmentsPanels!: boolean;
  hasIncludeDemographics!: boolean;
  hasIncludeExternalAttributes!: boolean;
  hasIncludeFilters!: boolean;
  hasIncludeCalculations!: boolean;
  hasIncludeFabrications!: boolean;
  actionKind = ActionKind;
  gridKind = ActionStructureGridKind;
  missionKind = MissionKind;
  multipleChoiceKind = ActionStructureMultipleChoiceKind;
  inprogress!: boolean;
  includesSelected!: boolean;
  actionsSelected!: boolean;
  cancelClick: EventEmitter<void> = new EventEmitter();
  saveComplete: EventEmitter<number> = new EventEmitter();

  constructor(
    private tableViewService: TableViewService,
    private suzyData: SuzyDataService,
    private formBuilder: FormBuilder,
    private segmentService: SegmentService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (
      !this.ui?.activeTableView ||
      !this.ui?.mission ||
      !this.ui?.defaultTableViews
    ) {
      return;
    }

    this.tableView = this.ui.activeTableView;
    this.defatultTableViews = this.ui.defaultTableViews.data;

    this.hasIncludeCrosstabQuestion =
      this.tableViewService.tableViewHasCrosstabQuestion(this.tableView);
    this.hasIncludeQuotas = this.tableViewService.tableViewHasQuotas(
      this.tableView
    );
    this.hasIncludeSegmentsPanels =
      this.tableViewService.tableViewHasSegmentsPanels(this.tableView);
    this.hasIncludeDemographics =
      this.tableViewService.tableViewHasDemographics(this.tableView);
    this.hasIncludeExternalAttributes =
      this.tableViewService.tableViewHasExternalAttributes(
        this.ui.mission,
        this.tableView
      );
    this.hasIncludeFilters = this.tableViewService.tableViewHasFilters(
      this.tableView
    );
    this.tableViewHasNetOrMeans = this.tableViewService.tableViewHasNetOrMeans(
      this.tableView
    );
    this.tableViewHasStatTesting =
      this.tableViewService.tableViewHasStatTesting(this.tableView);

    this.hasIncludeCalculations =
      this.tableViewHasNetOrMeans || this.tableViewHasStatTesting;

    this.hasIncludeFabrications =
      this.tableViewService.tableViewHasFabrications(this.tableView);

    this.pageForm = this.createForm();
    this.onInlcudeOptionsChanged();
    this.loadActionsForMission();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onSelectAllChange(): void {
    if (this.masterCheckbox.checked) {
      for (const option of this.actionsFormArray.controls) {
        option.controls?.Included.setValue(false);
      }
    } else {
      const isCalculationsIncluded =
        this.pageForm.controls.includeCalculations.value;
      const isCalculationsIncludedOnly = !(
        this.pageForm.controls.includeCrosstabQuestion.value ||
        this.pageForm.controls.includeQuotas.value ||
        this.pageForm.controls.includeSegmentsPanels.value ||
        this.pageForm.controls.includeDemographics.value ||
        this.pageForm.controls.includeExternalAttributes.value ||
        this.pageForm.controls.includeFilters.value
      );
      for (const option of this.actionsFormArray.controls) {
        if (isCalculationsIncluded && isCalculationsIncludedOnly) {
          if (
            (option.controls?.IsCalculationsApplicable.value &&
              this.tableViewHasNetOrMeans) ||
            (option.controls?.IsStatTestingApplicable.value &&
              this.tableViewHasStatTesting)
          ) {
            option.controls?.Included.setValue(true);
          }
        } else {
          option.controls?.Included.setValue(true);
        }
      }
    }
  }

  onSaveClick(): void {
    this.inprogress = true;
    this.pageForm.disable();

    const includeCrosstabQuestion =
      this.pageForm.controls.includeCrosstabQuestion.value;
    const includeQuotas = this.pageForm.controls.includeQuotas.value;
    const includeSegmentsPanels =
      this.pageForm.controls.includeSegmentsPanels.value;
    const includeDemographics =
      this.pageForm.controls.includeDemographics.value;
    const includeExternalAttributes =
      this.pageForm.controls.includeExternalAttributes.value;
    const includeFilters = this.pageForm.controls.includeFilters.value;
    const includeFabrications =
      this.pageForm.controls.includeFabrications.value;

    this.actionsFormArray.controls.forEach((formGroup) => {
      if (!formGroup.controls?.Included.value) {
        return;
      }

      const actionId = formGroup.controls.ActionId.value ?? '';
      const duplicateToActionItem = this.actions.find(
        (x) => x.action.action_id === actionId
      );
      if (!duplicateToActionItem) {
        return;
      }

      const duplicateToAction = duplicateToActionItem.action;

      const tableViewName = formGroup.controls.TableViewName.value ?? '';
      const tableViewConfigKey = this.ui.mission
        ? this.tableViewService.getInterfaceConfigKeyForAction(
            this.ui.mission,
            duplicateToAction
          )
        : '';

      const defaultTableViewsForAction = (this.ui.defaultTableViews.data ?? [])
        .filter((x) => x.key === tableViewConfigKey)
        .sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0));
      if (!defaultTableViewsForAction?.length) {
        return;
      }

      const defaultTableView = defaultTableViewsForAction[0];
      const newTableView: ITableView = JSON.parse(
        JSON.stringify(defaultTableView)
      );
      newTableView.table_view_id = '';
      newTableView.brand_id = this.ui.brand_id ?? '';
      newTableView.mission_id = this.tableView.mission_id ?? '';
      newTableView.action_id = duplicateToAction.action_id ?? '';
      newTableView.display_name = tableViewName;
      newTableView.key = tableViewConfigKey;
      newTableView.isGlobal = false;
      if (includeFilters) {
        newTableView.config.data_filter = this.tableView.config.data_filter;
      }

      const canCustomizeCalculations =
        this.tableViewService.canCustomizeCalculations(
          this.ui?.mission,
          duplicateToActionItem?.action
        );

      const canCustomizeStatTesting =
        this.tableViewService.canCustomizeStatTesting(
          this.ui?.statTestingEnabled ?? false,
          duplicateToActionItem?.action
        );

      const includeCalculations =
        this.pageForm.controls.includeCalculations.value &&
        (canCustomizeCalculations || canCustomizeStatTesting);

      if (
        includeDemographics ||
        includeSegmentsPanels ||
        includeQuotas ||
        includeCrosstabQuestion ||
        includeExternalAttributes ||
        includeCalculations ||
        includeFabrications
      ) {
        const sourceColumns = defaultTableView.config.columns ?? [];
        const duplicateColumns = this.tableView.config.columns ?? [];
        const newColumns = this.tableViewService.duplicateBannerConfigs(
          sourceColumns,
          duplicateColumns,
          {
            includeDemographics,
            includeSegmentsPanels,
            includeQuotas,
            includeCrosstabQuestion,
            includeExternalAttributes,
            includeFabrications,
          },
          newTableView.action_id,
          true
        );

        const firstElement = newColumns[0].dimensions[0];
        const checkDuplicateColumns = duplicateColumns.find(
          (obj) => obj.dimensions
        );
        const grabIfColAttributes = checkDuplicateColumns?.dimensions.some(
          (obj) =>
            obj.label === 'Attributes' &&
            firstElement.field?.field === 'action_setting_id_root'
        );

        if (
          (grabIfColAttributes &&
            newTableView.key === 'scale_survey_standard') ||
          (grabIfColAttributes &&
            newTableView.key === 'ranking_survey_standard') ||
          (grabIfColAttributes &&
            newTableView.key === 'customgrid_survey_standard')
        ) {
          this.reorderColDimensions(duplicateColumns, newColumns);
          newTableView.config.columns = newColumns;
        }
        if (
          grabIfColAttributes &&
          newTableView.key === 'multiplechoice_survey_standard'
        ) {
          this.reorderColDimensions(duplicateColumns, newColumns);
          newTableView.config.columns = newColumns;
        } else {
          newTableView.config.columns = newColumns;
        }

        const sourceRows = defaultTableView.config.rows ?? [];
        const duplicateRows = this.tableView.config.rows ?? [];
        const newRows = this.tableViewService.duplicateBannerConfigs(
          sourceRows,
          duplicateRows,
          {
            includeDemographics,
            includeSegmentsPanels,
            includeQuotas,
            includeCrosstabQuestion,
            includeExternalAttributes,
            includeFabrications,
          },
          newTableView.action_id,
          true
        );
        const newRowDimensionsDepths = newRows.map(
          (row) => row.dimensions?.length ?? 0
        );
        // const allEqual = (arr: number[]) => arr.every((val) => val === arr[0]);
        // if (allEqual(newRowDimensionsDepths)) {
        //   newTableView.config.rows = newRows;
        // }

        const checkDuplicateRows = duplicateRows.find((obj) => obj.dimensions);
        const grabIfRowHasSecondaryAttributes =
          checkDuplicateRows?.dimensions.some(
            (obj) => obj.field?.field === 'action_setting_id_secondary_root'
          );
        const grabIfColHasPrimaryAttributes =
          checkDuplicateColumns?.dimensions.some(
            (obj) => obj.field?.field === 'action_setting_id_root'
          );
        //console.log(newRows[0].dimensions.length);
        // 1. If rows have two natives and are going to a non-native
        // 2. If rows have two natives and are going to another two natives
        // 3. Else, treat rows normal
        if (
          (grabIfRowHasSecondaryAttributes &&
            grabIfColHasPrimaryAttributes &&
            newTableView.key === 'multiplechoice_survey_standard') ||
          (grabIfRowHasSecondaryAttributes &&
            grabIfColHasPrimaryAttributes &&
            newTableView.key === 'openend_survey_standard') ||
          (grabIfRowHasSecondaryAttributes &&
            grabIfColHasPrimaryAttributes &&
            newTableView.key === 'turf_survey_standard') ||
          (grabIfRowHasSecondaryAttributes &&
            grabIfColHasPrimaryAttributes &&
            newTableView.key === 'autoassign_survey_standard')
        ) {
          // Find and remove 'action_setting_id_secondary_root' from rows
          let removedItem;
          newRows.forEach((row) => {
            row.dimensions = row.dimensions.filter((dimension) => {
              if (
                dimension.field?.field === 'action_setting_id_secondary_root'
              ) {
                removedItem = dimension; // Save the removed item for tracking position
                return false; // Remove this item
              }
              return true; // Keep other items
            });
          });

          // Find 'action_setting_id_root' in columns
          let actionSettingIdRoot;
          newColumns.forEach((column) => {
            column.dimensions.forEach((dimension) => {
              if (dimension.field?.field === 'action_setting_id_root') {
                actionSettingIdRoot = dimension; // Store this dimension for relocation
              }
            });
          });

          // Move 'action_setting_id_root' to the position of 'action_setting_id_secondary_root' in rows
          if (removedItem && actionSettingIdRoot) {
            newRows.forEach((row) => {
              row.dimensions.push(actionSettingIdRoot); // Move the 'action_setting_id_root' to the rows
            });

            // Remove 'action_setting_id_root' from the original position if needed
            newColumns.forEach((column) => {
              column.dimensions = column.dimensions.filter(
                (dimension) =>
                  dimension.field?.field !== 'action_setting_id_root'
              );
            });

            const originalOrder = duplicateRows[0].dimensions.map(
              (dim) => dim.field?.field
            );

            // Map equivalence between old and new `field.field` values
            const fieldMapping = {
              action_setting_id_secondary_root: 'action_setting_id_root',
            };

            // Function to get the mapped or original field for comparison
            const getComparableField = (fieldValue: string) => {
              // Return the mapped field value if it exists, otherwise return the original value
              return (
                Object.keys(fieldMapping).find(
                  (key) => fieldMapping[key] === fieldValue
                ) || fieldValue
              );
            };

            // Reorder the modified array based on the original order, with field mappings

            newRows[0].dimensions.sort((a, b) => {
              const fieldA = getComparableField(a.field?.field ?? '');
              const fieldB = getComparableField(b.field?.field ?? '');
              return (
                originalOrder.indexOf(fieldA) - originalOrder.indexOf(fieldB)
              );
            });

            newTableView.config.rows = newRows;
            //console.log('NEW: ', newRows);
          }
        } else if (newRows[0].dimensions.length > 1) {
          // Store the original order of `field.field` values
          const originalOrder = duplicateRows[0].dimensions.map(
            (dim) => dim.field?.field
          );

          // Map equivalence between old and new `field.field` values
          const fieldMapping = {
            action_setting_id_root: 'action_setting_id_secondary_root',
          };

          const fieldMappingAlt = {
            action_setting_id_secondary_root:
              'action_setting_id_secondary_root',
          };

          // Function to get the mapped or original field for comparison
          const getComparableField = (fieldValue: string) => {
            // Return the mapped field value if it exists, otherwise return the original value
            return (
              Object.keys(fieldMapping).find(
                (key) => fieldMapping[key] === fieldValue
              ) || fieldValue
            );
          };

          const getComparableFieldAlt = (fieldValue: string) => {
            // Return the mapped field value if it exists, otherwise return the original value
            return (
              Object.keys(fieldMappingAlt).find(
                (key) => fieldMappingAlt[key] === fieldValue
              ) || fieldValue
            );
          };

          // Reorder the modified array based on the original order, with field mappings
          if (
            newTableView.key === 'ranking_survey_standard' ||
            newTableView.key === 'customgrid_survey_standard' ||
            newTableView.key === 'scale_survey_standard'
          ) {
            newRows[0].dimensions.sort((a, b) => {
              const fieldA = getComparableFieldAlt(a.field?.field ?? '');
              const fieldB = getComparableFieldAlt(b.field?.field ?? '');
              return (
                originalOrder.indexOf(fieldA) - originalOrder.indexOf(fieldB)
              );
            });
          } else {
            newRows[0].dimensions.sort((a, b) => {
              const fieldA = getComparableField(a.field?.field ?? '');
              const fieldB = getComparableField(b.field?.field ?? '');
              return (
                originalOrder.indexOf(fieldA) - originalOrder.indexOf(fieldB)
              );
            });
          }

          newTableView.config.rows = newRows;
   /*        console.log('DUP: ', duplicateRows);
          console.log('originalOrder: ', originalOrder);
          console.log('NEW: ', newRows); */
        } else {
          newTableView.config.rows = newRows;
        }
      }

      let calculationExpansions = this.tableView.config.expansions?.filter(
        (x) =>
          ['scale_rank.top', 'scale_rank.bottom', 'means'].some(
            (e) => e === x.name
          )
      );

      if (
        includeCalculations &&
        calculationExpansions?.length &&
        canCustomizeCalculations
      ) {
        if (!newTableView.config.expansions?.length) {
          newTableView.config.expansions = calculationExpansions;
        } else {
          newTableView.config.expansions =
            newTableView.config.expansions.concat(calculationExpansions);
        }
      }

      const statTestingAddition = this.tableView.config.additions?.filter((x) =>
        ['stat_test_column'].some((e) => e === x.name)
      );
      if (
        includeCalculations &&
        statTestingAddition?.length &&
        canCustomizeStatTesting &&
        newTableView.config.columns.length
      ) {
        if (!newTableView.config.additions?.length) {
          newTableView.config.additions = statTestingAddition;
        } else {
          newTableView.config.additions =
            newTableView.config.additions.concat(statTestingAddition);
        }

        const netOrMeansRequired =
          this.actionReqiresNetOrMeansForStatTesting(duplicateToAction);

        if (netOrMeansRequired && !calculationExpansions?.length) {
          calculationExpansions =
            this.tableViewService.getDefaultCalculationsExpansions();

          if (!newTableView.config.expansions?.length) {
            newTableView.config.expansions = calculationExpansions;
          } else {
            newTableView.config.expansions =
              newTableView.config.expansions.concat(calculationExpansions);
          }
        }
      }

      delete newTableView.priority;
      delete newTableView.config.action_id_primary;
      if (!includeCrosstabQuestion) {
        delete newTableView.config.action_id_crosstabs;
      } else {
        newTableView.config.action_id_crosstabs = (
          this.tableView.config.action_id_crosstabs ?? []
        ).filter((x) => x !== newTableView.action_id);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (newTableView.config as any).brand_id;

      if (
        includeDemographics &&
        this.tableView.config.orchestration?.custom_age_group
      ) {
        newTableView.config.orchestration = {
          custom_age_group: JSON.parse(
            JSON.stringify(this.tableView.config.orchestration.custom_age_group)
          ),
        };
      }

      if (includeFabrications && this.tableView.config.fabrications) {
        newTableView.config.fabrications = this.tableView.config.fabrications;
      }

      duplicateToActionItem.duplicate = {
        tableViewName,
        tableViewConfigKey,
        defaultTableView,
        newTableView,
      };
    });

    const duplicateToActions = (
      this.actions.filter((x) => x.duplicate?.newTableView?.key) ?? []
    ).map((x) => x.duplicate);

    if (!duplicateToActions.length) {
      this.inprogress = false;
      return;
    }

    const create$: Array<Observable<ITableView>> = [];

    duplicateToActions.forEach((duplicate) => {
      if (!duplicate || !duplicate?.newTableView) {
        return;
      }
      duplicate.inprogress = true;
      duplicate.failed = false;
      duplicate.success = false;
      const { newTableView } = duplicate;

      const duplicateCreate$ = this.tableViewService
        .createTableView(this.ui.brand_id, newTableView)
        .pipe(
          takeUntil(this.unsubscribeAll),
          take(1),
          catchError(() => {
            duplicate.failed = true;
            return of(newTableView);
          }),
          finalize(() => {
            duplicate.inprogress = false;
            if (!duplicate.failed) {
              duplicate.success = true;
            }
          }),
          map((resp) => {
            if (duplicate.failed || !resp.table_view_id || !resp?.action_id) {
              return resp;
            }
            if (duplicate.newTableView) {
              duplicate.newTableView.mission_id = resp.mission_id;
              duplicate.newTableView.action_id = resp.action_id;
              duplicate.newTableView.table_view_id = resp.table_view_id;
            }
            this.tableViewService.saveTableViewPref(
              resp.action_id,
              resp.table_view_id
            );
            return resp;
          })
        );
      create$.push(duplicateCreate$);
    });

    forkJoin(create$)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => {
        setTimeout(() => {
          this.inprogress = false;
          const newTableViews =
            duplicateToActions.filter((x) => x?.success) ?? [];
          const newTableViewsCount = newTableViews.length;
          newTableViews.forEach((x) => {
            if (!x?.newTableView) {
              return;
            }
            this.ui.tableViewNamesDict.data.push({
              mission_id: x.newTableView.mission_id ?? '',
              action_id: x.newTableView.action_id ?? '',
              table_view_id: x.newTableView.table_view_id ?? '',
              display_name: x.newTableView.display_name ?? '',
            });
          });
          this.saveComplete.emit(newTableViewsCount);
        }, 500);
      });

    const destinationActions = (
      this.actions.filter((x) => x.duplicate?.newTableView?.key) ?? []
    ).map((x) => x.action);

    const trackData = <ISegmentTrackApplyTableViewData>{
      brand_id: this.ui?.brand_id ?? '',
      brand_name: this.ui?.brand_name ?? '',
      primary_mission_id: this.ui?.mission ? this.ui.mission.mission_id : '',
      primary_mission_kind: this.ui?.mission
        ? MissionKind[this.ui.mission.mission_kind]
        : '',
      primary_mission_name: this.ui?.mission ? this.ui.mission.search_text : '',
      applied_attributes: [],
      source_action_id: this.ui.action ? this.ui.action.action_id : '',
      source_action_kind: this.ui.action
        ? ActionKind[this.ui.action.action_kind]
        : '',
      source_action_text: this.ui.action ? this.ui.action.search_text : '',
      source_tableview_id: this.ui.activeTableView
        ? this.ui.activeTableView.table_view_id
        : '',
      source_tableview_name: this.ui.activeTableView
        ? this.ui.activeTableView.display_name
        : '',
      destination_action_ids: [],
      destination_action_kinds: [],
      destination_action_text: [],
    };

    destinationActions.forEach((destinationAction) => {
      trackData.destination_action_ids.push(destinationAction.action_id);
      trackData.destination_action_kinds.push(
        ActionKind[destinationAction.action_kind]
      );
      trackData.destination_action_text.push(destinationAction.search_text);
    });

    if (includeCrosstabQuestion) {
      trackData.applied_attributes.push(
        this.translate.instant('builder.crosstabQuestion')
      );
    }
    if (includeQuotas) {
      trackData.applied_attributes.push(
        this.translate.instant('builder.dimensionQuotas')
      );
    }
    if (includeSegmentsPanels) {
      trackData.applied_attributes.push(
        this.translate.instant('builder.dimensionSegmentsPanels')
      );
    }
    if (includeDemographics) {
      trackData.applied_attributes.push(
        this.translate.instant('crosstab.demographics')
      );
    }
    if (includeFabrications) {
      trackData.applied_attributes.push(
        this.translate.instant('customBannerPoints.subPopulations.tabTitle')
      );
    }
    if (includeExternalAttributes) {
      trackData.applied_attributes.push(
        this.translate.instant('builder.dimensionExternalAttributes')
      );
    }
    if (includeFilters) {
      trackData.applied_attributes.push(
        this.translate.instant('crosstab.filters')
      );
    }

    const missionText$ = this.suzyData
      .processMissionText(this.ui.mission)
      .pipe(take(1));
    const sourceActionText$ = this.suzyData
      .processMissionText(this.ui.action)
      .pipe(take(1));
    const textSubs$ = [missionText$, sourceActionText$];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackData.destination_action_text.forEach((value: any) =>
      textSubs$.push(value.pipe(take(1)))
    );
    forkJoin(textSubs$)
      .pipe(take(1))
      .subscribe((values) => {
        trackData.primary_mission_name =
          this.sanitizer.sanitize(SecurityContext.HTML, values[0]) ?? '';
        trackData.source_action_text =
          this.sanitizer.sanitize(SecurityContext.HTML, values[1]) ?? '';
        trackData.destination_action_text = values
          .slice(2)
          .map(
            (value) =>
              this.sanitizer.sanitize(SecurityContext.HTML, value) ?? ''
          );
        this.segmentService.trackAction(
          ESegmentTrackActionKind.applyTableView,
          trackData
        );
      });
  }

  reorderColDimensions(referenceArray: any[], arrayToReorder: any[]) {
    // Get the order of labels from the reference array
    const referenceOrder = referenceArray[0].dimensions.map(
      (dimension: any) => dimension.label
    );

    // Reorder dimensions in arrayToReorder based on referenceOrder
    arrayToReorder[0].dimensions.sort((a: any, b: any) => {
      return referenceOrder.indexOf(a.label) - referenceOrder.indexOf(b.label);
    });
  }

  copyArrayWithoutSpecificObject(array, excludeLabel) {
    // Use the filter method to create a new array excluding the object with the specified label
    return array.filter((item) => item.label !== excludeLabel);
  }

  private createForm(): FormGroup<DuplicateTableViewForm> {
    this.actionsFormArray = this.formBuilder.array<
      FormGroup<DuplicateActions | null>
    >([]);
    const crosstabQuestionControl = new FormControl(
      this.hasIncludeCrosstabQuestion
    );
    const quotasControl = new FormControl(this.hasIncludeQuotas);
    const segmentsPanelsControl = new FormControl(
      this.hasIncludeSegmentsPanels
    );
    const demographicsControl = new FormControl(this.hasIncludeDemographics);
    const filtersControl = new FormControl(this.hasIncludeFilters);
    const calculationsControl = new FormControl(this.hasIncludeCalculations);
    const externalAttributesControl = new FormControl(
      this.hasIncludeExternalAttributes
    );
    const fabricationsControl = new FormControl(this.hasIncludeFabrications);

    merge(
      crosstabQuestionControl.valueChanges,
      quotasControl.valueChanges,
      segmentsPanelsControl.valueChanges,
      demographicsControl.valueChanges,
      externalAttributesControl.valueChanges,
      filtersControl.valueChanges,
      calculationsControl.valueChanges,
      fabricationsControl.valueChanges
    )
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => {
        if (this.hasIncludeCalculations) {
          if (this.tableViewHasStatTesting) {
            this.checkStatTestingApplicability();
          }
          this.onCalculationsOptionChanged();
        }
        this.onInlcudeOptionsChanged();
      });

    return this.formBuilder.group({
      includeDemographics: demographicsControl,
      includeSegmentsPanels: segmentsPanelsControl,
      includeQuotas: quotasControl,
      includeCrosstabQuestion: crosstabQuestionControl,
      includeExternalAttributes: externalAttributesControl,
      includeFilters: filtersControl,
      includeCalculations: calculationsControl,
      includeFabrications: fabricationsControl,
      actions: this.actionsFormArray,
    } as DuplicateTableViewForm);
  }

  private loadActionsForMission(): void {
    const loader$ =
      this.ui?.mission?.mission_kind === MissionKind.external_link
        ? this.suzyData.getActionsForExternalLinkMission(
            this.tableView.mission_id ?? '',
            this.ui.brand_id
          )
        : this.suzyData.getActionsForMission(
            this.tableView.mission_id ?? '',
            this.ui.brand_id
          );

    loader$.pipe(takeUntil(this.unsubscribeAll), take(1)).subscribe((resp) => {
      if (!resp?.length) {
        return;
      }

      this.actions = (resp ?? [])
        .filter((x) => x.action_id !== this.tableView.action_id)
        .map((x) => {
          return { action: x } as {
            action: IAction;
            duplicate?: DuplicateActionItem;
          };
        });

      this.actions.forEach((actionItem) => {
        const { action } = actionItem;
        const newTableViewName = this.generateTableViewNameForAction(
          this.tableView.display_name,
          action
        );
        const canApplyCalculations =
          this.tableViewService.canCustomizeCalculations(
            this.ui?.mission,
            action
          );
        const canApplyStatTesting =
          this.tableViewService.canCustomizeStatTesting(
            this.ui?.statTestingEnabled ?? false,
            action
          );
        const group = {
          ActionId: new FormControl(action.action_id),
          Included: new FormControl(false),
          IsCalculationsApplicable: new FormControl(canApplyCalculations),
          IsStatTestingApplicable: new FormControl(canApplyStatTesting),
          TableViewName: new FormControl(newTableViewName),
        } as DuplicateActions;

        group.Included.valueChanges
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe(() => {
            this.onActionSelectionChanged();
          });

        this.actionsFormArray.push(
          this.formBuilder.group<DuplicateActions>(
            group
          ) as FormGroup<DuplicateActions | null>
        );
      });

      this.actionsLoaded = true;
      if (this.hasIncludeCalculations) {
        setTimeout(() => {
          if (this.tableViewHasStatTesting) {
            this.checkStatTestingApplicability();
          }
          this.onCalculationsOptionChanged();
        });
      }
    });
  }

  private onActionSelectionChanged(): void {
    this.actionsFormArray.controls.forEach((formGroup) => {
      const included = formGroup.controls?.Included.value ?? false;
      if (included) {
        formGroup.controls?.TableViewName.setValidators(
          Validators.compose([
            Validators.required,
            Validators.maxLength(100),
            this.tableViewNameValidator(
              this.ui.mission?.mission_id ?? '',
              this.ui.tableViewNamesDict.data
            ),
          ])
        );
      } else {
        formGroup.controls?.TableViewName.setValidators(null);
      }
      setTimeout(() => {
        formGroup.controls?.TableViewName.updateValueAndValidity();
      }, 0);
    });

    this.actionsSelected = this.actionsFormArray.controls.some(
      (x) => x.controls?.Included.value
    );
  }

  private onInlcudeOptionsChanged(): void {
    this.includesSelected =
      this.pageForm.controls.includeCrosstabQuestion.value ||
      this.pageForm.controls.includeQuotas.value ||
      this.pageForm.controls.includeSegmentsPanels.value ||
      this.pageForm.controls.includeDemographics.value ||
      this.pageForm.controls.includeExternalAttributes.value ||
      this.pageForm.controls.includeFilters.value ||
      this.pageForm.controls.includeCalculations.value ||
      this.pageForm.controls.includeFabrications.value;
  }

  private generateTableViewNameForAction(
    namePrefix: string,
    action: IAction
  ): string {
    const newName = `${namePrefix}-${(action.question_number ?? '').replace(
      '. ',
      ''
    )}`;
    const found = this.ui.tableViewNamesDict.data.filter(
      (x) =>
        x.mission_id === action.mission_id &&
        x.action_id === action.action_id &&
        x.display_name.startsWith(newName)
    );
    if (found?.length) {
      for (let ix = 1; ix <= found.length + 1; ix++) {
        const tmpName = `${newName} (${ix})`;
        const tmpNameExists =
          this.ui.tableViewNamesDict.data.findIndex(
            (x) =>
              x.mission_id === action.mission_id &&
              x.action_id === action.action_id &&
              x.display_name === tmpName
          ) >= 0;
        if (!tmpNameExists) {
          return tmpName;
        }
      }
      return `${newName} (${found.length + 2})`;
    }
    return `${namePrefix}-${(action.question_number ?? '').replace('. ', '')}`;
  }

  private onCalculationsOptionChanged(): void {
    const isCalculationsIncluded =
      this.pageForm.controls.includeCalculations.value;

    const isCalculationsIncludedOnly = !(
      this.pageForm.controls.includeCrosstabQuestion.value ||
      this.pageForm.controls.includeQuotas.value ||
      this.pageForm.controls.includeSegmentsPanels.value ||
      this.pageForm.controls.includeDemographics.value ||
      this.pageForm.controls.includeFabrications.value ||
      this.pageForm.controls.includeExternalAttributes.value ||
      this.pageForm.controls.includeFilters.value
    );

    this.actionsFormArray.controls.forEach((actionFormGroup) => {
      if (!actionFormGroup.controls) {
        return;
      }

      if (
        isCalculationsIncluded &&
        isCalculationsIncludedOnly &&
        !(
          (actionFormGroup.controls.IsCalculationsApplicable.value &&
            this.tableViewHasNetOrMeans) ||
          (actionFormGroup.controls.IsStatTestingApplicable.value &&
            this.tableViewHasStatTesting)
        )
      ) {
        if (actionFormGroup.controls.Included.value) {
          actionFormGroup.controls.Included.patchValue(false);
        }
        actionFormGroup.controls.Included.disable();
        actionFormGroup.controls.TableViewName.disable();
      } else {
        if (actionFormGroup.controls.Included.disabled) {
          actionFormGroup.controls.Included.enable();
          actionFormGroup.controls.TableViewName.enable();
        }
      }
    });
  }

  tableViewNameValidator(
    missionId: string,
    namesDict: ITableViewName[]
  ): ValidatorFn {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.parent || !control.value) {
        return null;
      }

      const group = control.parent as FormGroup<DuplicateActions>;
      const tableViewName = control.value;
      const actionId = group.controls.ActionId.value;
      const nameExists =
        namesDict.findIndex(
          (x) =>
            x.mission_id === missionId &&
            x.action_id === actionId &&
            x.display_name === tableViewName.trim()
        ) >= 0;

      if (!nameExists) {
        return null;
      }
      return { duplicateName: true };
    };
  }

  private checkStatTestingApplicability(): void {
    const defaultTableViews = this.ui.defaultTableViews ?? [];
    const includeCrosstabQuestion =
      this.pageForm.controls.includeCrosstabQuestion.value;
    const includeQuotas = this.pageForm.controls.includeQuotas.value;
    const includeSegmentsPanels =
      this.pageForm.controls.includeSegmentsPanels.value;
    const includeDemographics =
      this.pageForm.controls.includeDemographics.value;
    const includeExternalAttributes =
      this.pageForm.controls.includeExternalAttributes.value;
    const includeFabrications =
      this.pageForm.controls.includeFabrications.value;

    this.actionsFormArray.controls.forEach((formGroup) => {
      if (!formGroup.controls?.ActionId.value) {
        return;
      }

      const actionId = formGroup.controls.ActionId.value ?? '';
      const duplicateToActionItem = this.actions.find(
        (x) => x.action.action_id === actionId
      );
      if (!duplicateToActionItem) {
        return;
      }
      const duplicateToAction = duplicateToActionItem.action;
      const tableViewConfigKey = this.ui.mission
        ? this.tableViewService.getInterfaceConfigKeyForAction(
            this.ui.mission,
            duplicateToAction
          )
        : '';
      const defaultTableViewsForAction = (defaultTableViews.data ?? [])
        .filter((x) => x.key === tableViewConfigKey)
        .sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0));
      if (!defaultTableViewsForAction?.length) {
        return;
      }
      const defaultTableView = defaultTableViewsForAction[0];
      const newTableView: ITableView = JSON.parse(
        JSON.stringify(defaultTableView)
      );
      newTableView.action_id = duplicateToAction.action_id ?? '';

      if (
        includeDemographics ||
        includeSegmentsPanels ||
        includeQuotas ||
        includeExternalAttributes ||
        includeCrosstabQuestion ||
        includeFabrications
      ) {
        const sourceColumns = defaultTableView.config.columns ?? [];
        const duplicateColumns = this.tableView.config.columns ?? [];
        const newColumns = this.tableViewService.duplicateBannerConfigs(
          sourceColumns,
          duplicateColumns,
          {
            includeDemographics,
            includeSegmentsPanels,
            includeQuotas,
            includeCrosstabQuestion,
            includeExternalAttributes,
            includeFabrications,
          },
          newTableView.action_id,
          true
        );
        newTableView.config.columns = newColumns;
      }

      const statTestingApplicableValue =
        formGroup.controls.IsStatTestingApplicable.value;
      const canCustomizeStatTesting =
        this.tableViewService.canCustomizeStatTesting(
          this.ui?.statTestingEnabled ?? false,
          duplicateToAction
        );
      const isStatTestingApplicable = newTableView.config.columns.length > 0;

      if (statTestingApplicableValue && !isStatTestingApplicable) {
        formGroup.controls.IsStatTestingApplicable.setValue(false);
        return;
      }

      if (
        !statTestingApplicableValue &&
        canCustomizeStatTesting &&
        isStatTestingApplicable
      ) {
        formGroup.controls.IsStatTestingApplicable.setValue(true);
        return;
      }
    });
  }

  private actionReqiresNetOrMeansForStatTesting(action: IAction): boolean {
    const { action_kind } = action;
    return (
      action_kind === ActionKind.gridrankscale ||
      (action_kind === ActionKind.grid &&
        this.tableViewService.getGridKind(action) ===
          ActionKindVariant.grid_rank) ||
      (action_kind === ActionKind.grid &&
        this.tableViewService.getGridKind(action) ===
          ActionKindVariant.grid_scale) ||
      (action_kind === ActionKind.multiplechoice &&
        this.tableViewService.getMultipleChoiceKind(action) ===
          ActionKindVariant.multiplechoice_rating)
    );
  }
}
