import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ActionKind,
  ActionStructureGridKind,
  ActionStructureMultipleChoiceKind,
  MissionKind,
} from '@asksuzy/typescript-sdk';
import { IMission } from '../../../../../../models/suzy/IMission';
import {
  debounceTime,
  distinctUntilChanged,
  of,
  Subject,
  Subscription,
  take,
  takeUntil,
} from 'rxjs';
import { SuzyDataService } from '../../../../../../services/suzy-data.service';
import { IgxListComponent } from '@infragistics/igniteui-angular';

@Component({
  selector: 'app-question-selector',
  templateUrl: './question-selector.component.html',
  styleUrls: ['./question-selector.component.scss'],
})
export class QuestionSelectorComponent implements OnInit, OnDestroy {
  private readonly missionFilterSubjectChanged = new Subject<void>();
  private readonly missionFilterSubject = new Subject<string>();
  private defaultMissionsList!: IMission[];
  private missionFilterSubscription?: Subscription;

  loading!: boolean;
  searchText!: string;
  selectedMission?: IMission;
  filteredMissions: IMission[] = [];

  isSearchActive!: boolean;
  missionKind = MissionKind;
  actionKind = ActionKind;
  gridKind = ActionStructureGridKind;
  multipleChoiceKind = ActionStructureMultipleChoiceKind;

  @ViewChild('searchBar', { static: false })
  searchBarRef!: ElementRef;

  @ViewChild('searchInput', { static: false })
  searchInputRef!: ElementRef;

  @ViewChild('searchResults', { static: false })
  searchResultsRef!: IgxListComponent;

  @Input() brandId!: string;
  @Input() global!: boolean;
  @Input() externalLink!: boolean;
  @Input() mission!: IMission;
  @Input() showBrandFolderInTooltip!: boolean;
  @Output() missionSelected: EventEmitter<{
    missionId: string;
    missionKind?: MissionKind;
  }> = new EventEmitter<{ missionId: string; missionKind?: MissionKind }>();

  @HostListener('document:click', ['$event'])
  detectOutsideClick(event) {
    const isSearchClick = (): boolean => {
      if (this.searchResultsRef?.element.nativeElement.contains(event.target))
        return true;
      if (this.searchBarRef.nativeElement.contains(event.target)) return true;

      return false;
    };

    const isSearch = isSearchClick();

    if (!this.isSearchActive) {
      if (isSearch && this.searchInputRef?.nativeElement) {
        setTimeout(() => {
          this.searchInputRef.nativeElement.focus();
          this.isSearchActive = true;
        });
      }

      return;
    }

    if (!isSearch) {
      this.toggleSearch();
    }
  }

  constructor(private suzy: SuzyDataService) {}

  ngOnInit(): void {
    if (!this.mission) {
      return;
    }

    const { mission_kind } = this.mission;
    if (mission_kind !== MissionKind.standard) {
      const defaultMissions = this.prepareMissions([{ ...this.mission }]);
      if (defaultMissions.length) {
        this.onMissionSelected(defaultMissions[0]);
      }
    }

    this.missionFilterSubscription = this.missionFilterSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((search) => this.searchForMission(search));

    this.filterMissionsDelayed('');
  }

  ngOnDestroy(): void {
    this.missionFilterSubjectChanged.next();
    this.missionFilterSubjectChanged.complete();
    this.missionFilterSubject.complete();
    this.missionFilterSubscription?.unsubscribe();
  }

  onMissionSelected(mission: IMission, toggleSearch?: boolean): void {
    this.selectedMission = mission;
    this.missionSelected.emit({
      missionId: this.selectedMission.mission_id,
      missionKind: this.selectedMission.mission_kind,
    });
    if (toggleSearch) {
      this.toggleSearch();
    }
  }

  onRemoveSelectedMission(): void {
    this.selectedMission = undefined;
    this.missionSelected.emit({ missionId: '' });
    setTimeout(() => {
      this.searchInputRef.nativeElement.focus();
    });
  }

  filterMissionsDelayed(keyword: string): void {
    this.missionFilterSubject.next(keyword.trim());
    if (keyword && !this.isSearchActive) {
      this.isSearchActive = true;
    }
  }

  toggleSearch(): void {
    this.searchText = '';
    this.isSearchActive = !this.isSearchActive;
    this.filteredMissions = this.defaultMissionsList ?? [];
    if (!this.isSearchActive) {
      setTimeout(() => {
        this.missionFilterSubjectChanged.next();
        this.missionFilterSubject.next('');
      }, 0);
    }
  }

  private searchForMission(searchText: string): void {
    this.missionFilterSubjectChanged.next();
    if (!searchText && this.defaultMissionsList?.length > 0) {
      this.filteredMissions = this.defaultMissionsList;
      return;
    }
    this.loading = true;
    this.suzy
      .missionsFindForCrosstab(this.brandId, this.global, searchText ?? '', 10)
      .pipe(takeUntil(this.missionFilterSubjectChanged), take(1))
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.filteredMissions = this.prepareMissions(data);
          if (!searchText && !this.defaultMissionsList) {
            this.defaultMissionsList = this.filteredMissions;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        error: (error) => {
          this.loading = false;
        },
      });
  }

  private prepareMissions(missions: Array<IMission>): Array<IMission> {
    for (const item of missions) {
      if (item.brand_tags && item.brand_tags.length) {
        item.brand_tags_text = item.brand_tags.map((x) => x.name).join(', ');
      }
      item.search_text = this.suzy.processMissionText(item);
      if (this.showBrandFolderInTooltip) {
        if (item.brand_folder_name && item.brand_folder_name?.length > 0) {
          item.folder_name$ = of(item.brand_folder_name[0].name ?? '');
        } else if (
          item.brand_folders_ids &&
          item.brand_folders_ids.length > 0
        ) {
          item.folder_name$ = this.suzy.getBrandFolderName(
            item.brand_folders_ids[0]
          );
        } else {
          item.folder_name$ = of('');
        }
      }
    }
    return missions.filter(
      (mission) =>
        mission.mission_kind !== MissionKind.focusgroup &&
        mission.mission_kind !== MissionKind.openended_video &&
        mission.first_action?.action_kind !== ActionKind.focusgroup &&
        mission.first_action?.action_kind !== ActionKind.photoacquisition &&
        mission.first_action?.action_kind !== ActionKind.openended_video
    );
  }
}
