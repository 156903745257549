import { ComponentPortal, Portal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { Subject, take, takeUntil, tap } from 'rxjs';
import {
  EFabricationEndpointType,
  EFabricationType,
} from '../../../enums/cbp-enum';
import { ELibraryRoutes } from '../../../enums/de-routes.enum';
import { DATA_TOKEN, ICBPFormInputs } from '../../../models/ui/IInjectionToken';
import { IExplorerUI } from '../../../models/ui/i-explorer-ui';
import { CustomBannerPointsActionsService } from '../../../services/custom-banner-points-actions.service';
import { CustomBannerPointsService } from '../../../services/custom-banner-points.service';
import { LibraryNavigationService } from '../../../services/library-navigation.service';
import { ManageTablesPortalComponent } from '../../manage-tables/manage-tables-portal/manage-tables-portal.component';
import { CbpFabricationFormComponent } from '../cbp-fabrication-form/cbp-fabrication-form.component';
import { CustomBannerPointsTabsComponent } from '../custom-banner-points-tabs/custom-banner-points-tabs.component';

@Component({
  selector: 'custom-banner-points-layout',
  templateUrl: './custom-banner-points-layout.component.html',
  styleUrls: ['./custom-banner-points-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomBannerPointsLayoutComponent implements OnInit, OnDestroy {
  @Input() ui!: IExplorerUI;
  @Input() actions!: any[];

  selectedPortal!: Portal<any>;
  componentPortal!: ComponentPortal<
    CustomBannerPointsTabsComponent | CbpFabricationFormComponent
  >;

  nextLayout = null;
  unsubscribe = new Subject<void>();

  @ViewChild('formHasChangeDialog', {
    read: IgxDialogComponent,
    static: false,
  })
  formHasChangeDialog!: IgxDialogComponent;

  @Output() navigateTo = new EventEmitter<ELibraryRoutes>();

  constructor(
    public cbpActionsService: CustomBannerPointsActionsService,
    public cbpService: CustomBannerPointsService,
    private libraryNavigation: LibraryNavigationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.cbpActionsService
      .createSavedBannerDimensions(this.ui.brand_id)
      .subscribe((res) => {
        this.cbpActionsService.bannerDimensions.next(res);
      });

    this.cbpService
      .getFabricationsByType(
        this.ui.brand_id,
        EFabricationEndpointType.Subpopulation
      )
      .pipe(take(1))
      .subscribe((res) => {
        this.cbpActionsService.bannerSubpopulations.next(res.items);
      });

    this.cbpService
      .getFabricationsByType(
        this.ui.brand_id,
        EFabricationEndpointType.DerivedQuestions
      )
      .pipe(take(1))
      .subscribe((res) => {
        this.cbpActionsService.bannerDerivedQuestions.next(res.items);
      });

    this.libraryNavigation.DENavigationStatus$.pipe(
      tap((navigationStatus: any) => {
        if (navigationStatus) {
          switch (navigationStatus.currentRoute) {
            case ELibraryRoutes.CBPDerivedQuestion:
              this.assignComponentPortal(CbpFabricationFormComponent, {
                fabricationType: EFabricationType.DerivedQuestions,
                translations: {
                  title: 'customBannerPoints.derivedQuestions.form.title',
                  subtitle: 'customBannerPoints.derivedQuestions.form.subtitle',
                  labelName:
                    'customBannerPoints.derivedQuestions.form.labelName',
                  validations: {
                    nameRequired:
                      'customBannerPoints.derivedQuestions.form.validations.nameRequired',
                    nameExists:
                      'customBannerPoints.derivedQuestions.form.validations.nameExists',
                    cantValidateName:
                      'customBannerPoints.derivedQuestions.form.validations.cantValidateName',
                  },
                  namePlaceholder:
                    'customBannerPoints.derivedQuestions.form.namePlaceholder',
                },
                uiData: this.ui,
                actions: this.actions,
                type: EFabricationEndpointType.DerivedQuestions,
                toEdit: this.cbpActionsService.selectedSavedBanner.getValue(),
                toClone: this.cbpActionsService.savedBannerToClone.getValue(),
                bannerDimensions:
                  this.cbpActionsService.bannerDimensions.getValue(),
                selectedTabIndex: 0,
              });
              break;
            case ELibraryRoutes.CBPSubpopulation:
              this.assignComponentPortal(CbpFabricationFormComponent, {
                fabricationType: EFabricationType.Subpopulation,
                translations: {
                  title:
                    'customBannerPoints.subPopulations.form.titleSavedBanner',
                  subtitle:
                    'customBannerPoints.subPopulations.form.subtitleSavedBanner',
                  labelName: 'customBannerPoints.subPopulations.form.labelName',
                  validations: {
                    nameRequired:
                      'customBannerPoints.subPopulations.form.validations.nameRequired',
                    nameExists:
                      'customBannerPoints.subPopulations.form.validations.nameExists',
                    cantValidateName:
                      'customBannerPoints.subPopulations.form.validations.cantValidateName',
                  },
                  namePlaceholder:
                    'customBannerPoints.subPopulations.form.namePlaceholder',
                },
                uiData: this.ui,
                actions: this.actions,
                type: EFabricationEndpointType.Subpopulation,
                toEdit: this.cbpActionsService.selectedSavedBanner.getValue(),
                toClone: this.cbpActionsService.savedBannerToClone.getValue(),
                bannerDimensions:
                  this.cbpActionsService.bannerDimensions.getValue(),
                selectedTabIndex: 1,
              });
              break;
            case ELibraryRoutes.CBPTabs:
              this.assignComponentPortal(CustomBannerPointsTabsComponent, {
                uiData: this.ui,
                selectedTabIndex:
                  this.cbpActionsService.savedBannerSelectedTab.getValue(),
              });
              break;
            case ELibraryRoutes.ManageTables:
              this.assignComponentPortal(ManageTablesPortalComponent, {
                uiData: this.ui,
                selectedTabIndex: 0,
              });
              break;
          }
        }
      }),
      takeUntil(this.unsubscribe)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.cbpActionsService.savedBannerSelectedTab.next(0);
  }

  assignComponentPortal(
    component: any,
    injectionTokenData?: ICBPFormInputs
  ): void {
    const tokenData = injectionTokenData
      ? this.createInjector(injectionTokenData)
      : null;

    if (component) {
      this.componentPortal = new ComponentPortal(component, null, tokenData);
      this.selectedPortal = this.componentPortal;
    }
  }

  navigateBack(): void {
    const navStatus = this.libraryNavigation.DENavigationStatus.getValue();
    if (navStatus?.backTo) this.navigateTo.emit(navStatus.backTo);
  }

  createInjector(data: ICBPFormInputs): Injector {
    return Injector.create({
      providers: [
        {
          provide: DATA_TOKEN,
          useValue: { ...data },
        },
      ],
    });
  }

  onComponentRendering(evt: any): void {
    const navigateAway = evt.instance.navigateAway;

    if (navigateAway) {
      navigateAway
        .asObservable()
        .pipe(
          tap((value: boolean) => {
            if (value) {
              this.navigateBack();
            }
          }),
          takeUntil(this.unsubscribe)
        )
        .subscribe();
    }
  }
}
