import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ActionKindVariant } from '@asksuzy/typescript-sdk';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  distinctUntilChanged,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import {
  AT_LEAST_CRITERIA,
  AT_MOST_CRITERIA,
  BASE_OPERATORS,
  DEMOGRAPHIC_OPERATORS,
  RULE_TWO_CRITERIA,
  SEGMENTS_OPERATORS,
} from '../../../constants/cbp.constant';
import { ECBPCategory } from '../../../enums/cbp-enum';
import { ELibraryRoutes } from '../../../enums/de-routes.enum';
import { IDimension } from '../../../models/data/request/IDimension';
import { IDPair } from '../../../models/suzy/IDPair';
import { IMission } from '../../../models/suzy/IMission';
import { CustomBannerPointsActionsService } from '../../../services/custom-banner-points-actions.service';
import { SuzyDataService } from '../../../services/suzy-data.service';
import { IgxSimpleComboComponent } from '@infragistics/igniteui-angular';

@Component({
  selector: 'cbp-parameters-selector',
  templateUrl: './cbp-parameters-selector.component.html',
  styleUrls: ['./cbp-parameters-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CbpParametersSelectorComponent implements OnInit, OnDestroy {
  @Input() ageClassificationId!: string;
  @Input() brandId!: string;
  @Input() actions!: any[];
  @Input() builderDimensions!: any;
  @Input() id!: string;
  @Input() currentRoute!: string;

  CATEGORY_TYPES = [
    { name: 'Demographics', value: 'demographic' },
    { name: 'Segments & Panels', value: 'segments' },
    { name: 'Responses', value: 'answer' },
  ];

  GENDERS = [
    { name: 'Female', id: '1' },
    { name: 'Male', id: '2' },
  ];

  attributeList: any[] = [];
  parametersList: Observable<IDPair[] | any[]> = of([]);
  operators: any[] = [];
  isLoading = false;
  operatorText = '';
  selectedParameters!: any[] | undefined;
  categorySelected = '';
  criteriaForm!: FormGroup;
  formValue!: any;
  categories: typeof ECBPCategory = ECBPCategory;
  actionKindVariants: typeof ActionKindVariant = ActionKindVariant;
  selectedActionKindVariant!: ActionKindVariant;
  libRoutes: typeof ELibraryRoutes = ELibraryRoutes;
  attributeSourceHasValue!: boolean;
  selectedMission!: IMission;

  unsubscribe = new Subject<void>();

  ROWSCOLUMNS = [
    { name: 'Rows', value: 'rows' },
    { name: 'Columns', value: 'columns' },
  ];

  attributeSources!: Observable<any[]>;
  ruleTwo = true;
  scaleRankLessLegend = '';
  scaleRankMostLegend = '';

  brandMissions: IMission[] = [];
  searchingMissions = new BehaviorSubject<boolean>(false);

  @ViewChild(IgxSimpleComboComponent, {
    read: IgxSimpleComboComponent,
    static: true,
  })
  public attrCombo!: IgxSimpleComboComponent;

  @Output() selectedQuestionId = new EventEmitter<string | null>();

  constructor(
    private suzyData: SuzyDataService,
    private cbpService: CustomBannerPointsActionsService,
    private parentForm: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.criteriaForm = this.parentForm.control.get('criteria') as FormGroup;

    this.formValue = this.parentForm.control.get('criteria')?.value;
    if (this.formValue) {
      const fieldValue =
        this.formValue.field.source !== ECBPCategory.Responses
          ? this.cbpService.getValueFromDimensions(
              this.builderDimensions,
              this.formValue.field
            )
          : { ...this.formValue.field };

      if (fieldValue) {
        this.categoryChange({ newSelection: this.formValue.field.source });

        if (fieldValue.mission_id) {
          this.suzyData
            .missionsGet(fieldValue.brand_id, fieldValue.mission_id)
            .pipe(
              tap((mission) => {
                this.selectedMission = mission;
              }),
              switchMap((mission) =>
                this.suzyData.getMissionActions(
                  mission.brand_id,
                  mission.mission_id
                )
              ),
              tap((actions) => {
                this.attributeList = this.createQuestionsList(actions);
                this.actions = actions;
                this.prepareForm(fieldValue);
              }),
              take(1)
            )
            .subscribe();
        } else {
          this.prepareForm(fieldValue);
        }
      } else {
        this.criteriaForm.patchValue(this.formValue);
      }

      this.criteriaForm.updateValueAndValidity();
    } else {
      this.criteriaForm.reset();
      this.clearParametersList();
    }

    this.parameters.valueChanges
      .pipe(
        distinctUntilChanged(),
        map((params) => params.filter((p) => p.checked)),
        tap((res) => this.criteriaForm.get('at_most')?.patchValue(res.length)),
        takeUntil(this.unsubscribe)
      )
      .subscribe();

    if (this.currentRoute === ELibraryRoutes.CBPSubpopulation) {
      this.CATEGORY_TYPES.pop();
    }

    if (this.currentRoute === ELibraryRoutes.CBPDerivedQuestion) {
      this.loadMissions(this.brandId);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get parameters(): FormArray {
    return this.criteriaForm.get('parameters') as FormArray;
  }

  newParameter(): FormGroup {
    return new FormGroup({
      type: new FormControl('value'),
      value: new FormControl(null),
      display: new FormControl(null),
      value_secondary: new FormControl(null),
      display_secondary: new FormControl(null),
      checked: new FormControl(false),
    });
  }

  buildParametersFormArray(paramList: any): void {
    this.parameters.clear();

    if (paramList.length > 0) {
      // Get legend for scale

      if (this.formContainsRankScaleControls()) {
        this.scaleRankLessLegend = paramList.at(0).ui_text;
        this.scaleRankMostLegend = paramList.at(-1).ui_text;
      }
      // Create entire parameters list
      paramList.forEach((item: any) => {
        this.parameters.push(this.newParameter());
      });

      let mappedParamList = paramList.map((item) => {
        return {
          value: item.id,
          display: item.name,
        };
      });

      if (this.selectedParameters) {
        mappedParamList = mappedParamList.map((mapped) => {
          if (this.selectedParameters && this.selectedParameters.length > 0) {
            const found = this.selectedParameters.find(
              (sp) => sp.value === mapped.value
            );
            if (found) {
              mapped = found;
            }
          }

          return mapped;
        });
      }
      this.parameters.patchValue(mappedParamList);
    }
  }

  categoryChange(evt: any): void {
    this.categorySelected = evt.newSelection;

    if (!this.categorySelected) {
      this.clearData();
      this.clearParametersList();
      this.removeIdentifierControl();
      this.removeGridControls();
      this.removeRankScaleControls();
    } else {
      if (this.builderDimensions) {
        if (this.categorySelected === ECBPCategory.Demographics) {
          this.attributeList = this.builderDimensions.demographics;
          this.operators = DEMOGRAPHIC_OPERATORS;
          this.removeIdentifierControl();
        }

        if (this.categorySelected === ECBPCategory.SegmentsPanels) {
          this.attributeList = this.builderDimensions.segments;
          this.operators = SEGMENTS_OPERATORS;
          this.addIdentifierControl();
        }

        if (
          this.categorySelected === ECBPCategory.Responses &&
          this.currentRoute !== ELibraryRoutes.CBPDerivedQuestion
        ) {
          this.attributeList = this.createQuestionsList(this.actions);
          this.removeIdentifierControl();
        }
      }
    }
  }

  attributeChange(evt: any): void {
    const selection = evt.owner.data.find(
      (item) => item._drag_id === evt.newSelection
    );

    if (!selection) {
      this.removeGridControls();
      this.removeRankScaleControls();
      this.clearParametersList();
    } else {
      this.isLoading = true;
      const type = selection.field.source;

      this.selectedQuestionId.emit(null);

      if (this.formContainsIdentifier()) {
        this.criteriaForm
          .get('field')
          ?.get('identifier')
          ?.setValue(selection.field.identifier);
      }

      if (this.formContainsGridControls()) {
        this.removeGridControls();
      }

      if (this.formContainsRankScaleControls()) {
        this.removeRankScaleControls();
      }

      this.criteriaForm.patchValue({
        operator: BASE_OPERATORS[0].operator,
        ui_operator: BASE_OPERATORS[0].name,
        at_least: 1,
        at_most: null,
      });

      this.operatorText = BASE_OPERATORS[0].name;

      if (type === ECBPCategory.Demographics) {
        switch (selection.field.field) {
          case 'ethnicity_id':
            this.parametersList = this.suzyData.ethnicityGetValues(
              this.brandId
            );
            break;
          case 'gender':
            this.parametersList = of(this.GENDERS);
            break;
          case 'relationship_id':
            this.parametersList = this.suzyData.relationshipGetValues(
              this.brandId
            );
            break;
          case 'state_id':
            this.parametersList = this.suzyData.stateGetValues(this.brandId);
            break;
          case 'age_group_id':
            this.parametersList = this.suzyData.ageGroupGetValues(
              this.ageClassificationId
            );
            break;
          case 'education_id':
            this.parametersList = this.suzyData.educationLevelGetValues();
            break;
          case 'employment_id':
            this.parametersList = this.suzyData.employmentStatusGetValues();
            break;
          case 'household_id':
            this.parametersList = this.suzyData.householdSizeGetValues();
            break;
          case 'income_level_id':
            this.parametersList = this.suzyData.incomeLevelGetValues();
            break;
          case 'parenting_id':
            this.parametersList = this.suzyData.parentingStatusGetValues();
            break;
          case 'region_id':
            this.parametersList = this.suzyData.regionGetValues();
            break;
        }
      } else if (type === ECBPCategory.Profile) {
        this.parametersList = this.suzyData.brandClusterGroupGetValues(
          this.brandId,
          selection.field.identifier
        );
      } else if (type === ECBPCategory.Responses) {
        const actionKind = selection.actionKind;
        this.operators = this.cbpService.getOperatorsByActionKind(
          selection.action
        );

        this.selectedQuestionId.emit(selection._drag_id);

        this.selectedActionKindVariant = selection.actionKind;

        switch (actionKind) {
          case ActionKindVariant.grid_open:
            this.attributeSources = of(
              this.getGridColumnsFromActions(selection._drag_id)
            );
            this.parametersList = of(
              this.getGridRowsFromActions(selection._drag_id)
            );

            this.addGridControls();
            this.criteriaForm.get('field')?.get('rows_columns')?.setValue(null);
            break;
          case ActionKindVariant.grid_scale:
          case ActionKindVariant.grid_rank:
            this.attributeSources = of(
              this.getGridRowsFromActions(selection._drag_id)
            );
            this.parametersList = of(
              this.getGridColumnsFromActions(selection._drag_id)
            );
            this.addRankScaleControls();
            break;

          default:
            this.parametersList = this.suzyData.getAnswerChoicesForAction(
              this.brandId,
              selection._drag_id
            );
            this.attributeSources = of([]);
            break;
        }
      } else {
        this.parametersList = of([]);
      }
    }

    this.updateParametersView();
  }

  clearParametersList(): void {
    this.parametersList = of([]);
    this.selectedParameters = undefined;
    this.criteriaForm.updateValueAndValidity();
  }

  clearData(): void {
    this.attributeList = [];
    this.operators = [];
    this.buildParametersFormArray([]);
    this.operatorText = '';
  }

  addIdentifierControl() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    if (!fieldGroup.contains('identifier'))
      fieldGroup.addControl('identifier', new FormControl(''));
  }

  removeIdentifierControl() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    if (fieldGroup.contains('identifier'))
      fieldGroup.removeControl('identifier');
  }

  formContainsIdentifier() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    return fieldGroup.contains('identifier');
  }

  createQuestionsList(actions: any): IDimension[] {
    let questions = actions.map((action) => {
      return {
        action: action,
        actionKind: action.action_kind_variant,
        label: `${action.question_number}. ${action.originalSearchText}`,
        field: {
          action_id: 'primary',
          field: 'action_setting_id',
          source: 'answer',
          identifier: action.action_id,
        },
        type: 'field',
        _drag_id: action.action_id,
      };
    });

    if (this.currentRoute === ELibraryRoutes.CBPDerivedQuestion) {
      questions = questions.filter(
        (action) =>
          action.actionKind !==
          (ActionKindVariant.openended || ActionKindVariant.maxdiff)
      );
    }

    return questions;
  }

  selectedOperator(evt: any): void {
    const itemText = evt.newSelection.itemText;
    this.operatorText = itemText;
    const operator = this.operators.find((op) => op.name === itemText);
    this.criteriaForm.get('operator')?.setValue(operator.operator);

    this.ruleTwo = RULE_TWO_CRITERIA.includes(itemText);
  }

  showAtLeastField(text: string): boolean {
    return AT_LEAST_CRITERIA.includes(text);
  }

  showAtMostField(text: string): boolean {
    return AT_MOST_CRITERIA.includes(text);
  }

  addGridControls() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    if (!fieldGroup.contains('rows_columns')) {
      this.addRowsColumnsControl();
      this.addAttributeSourceControl();
    }
  }

  removeGridControls() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    if (fieldGroup.contains('rows_columns')) {
      this.removeRowsColumnsControl();
      this.removeAttributeSourceControl();
    }
  }

  formContainsGridControls() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    return (
      fieldGroup.contains('rows_columns') &&
      fieldGroup.contains('attribute_source')
    );
  }

  getGridRowsFromActions(selectedAction: string): IDPair[] {
    const gridData = this.actions.find(
      (action: any) => action.action_id === selectedAction
    );
    return (
      gridData.grid.rows.map((row) => {
        return {
          id: row.answer_id,
          name: row.answer_text,
          ui_text: row.ui_text,
        };
      }) ?? []
    );
  }

  getGridColumnsFromActions(selectedAction: string): IDPair[] {
    const gridData = this.actions.find(
      (action: any) => action.action_id === selectedAction
    );
    return (
      gridData.grid.columns.map((column) => {
        return {
          id: column.answer_id,
          name: column.answer_text,
          ui_text: column.ui_text,
        };
      }) ?? []
    );
  }

  updateRowsColumns(evt: any): void {
    const value = evt.value;
    const actionId = this.criteriaForm.get('field')?.get('field')?.value;

    if (evt.radio) {
      this.criteriaForm.get('field')?.get('attribute_source')?.reset();
      this.clearParametersList();
    }

    if (value) {
      if (value === 'columns') {
        this.parametersList = of(this.getGridRowsFromActions(actionId));
        this.attributeSources = of(this.getGridColumnsFromActions(actionId));
        this.attributeSourceHasValue = false;
      }
      if (value === 'rows') {
        this.parametersList = of(this.getGridColumnsFromActions(actionId));
        this.attributeSources = of(this.getGridRowsFromActions(actionId));
        this.attributeSourceHasValue = false;
      }

      this.updateParametersView();
    }
  }

  updateParametersView(): void {
    this.parametersList
      .pipe(
        tap((value) => {
          this.buildParametersFormArray(value);
          this.isLoading = false;
        }),
        take(1),
        catchError(() => {
          this.isLoading = false;
          return EMPTY;
        })
      )
      .subscribe();
  }

  attributeSourceChange(evt: any): void {
    this.parameters.controls.forEach((param, idx) => {
      this.parameters.at(idx).patchValue({
        value_secondary: evt.newSelection,
        display_secondary: evt.displayText,
      });
    });
    this.criteriaForm
      .get('field')
      ?.get('attribute_source')
      ?.setValue(evt.newSelection);
  }

  isRowsOrColumns(selectedAction: string, valueSecondary: string): string {
    if (
      this.getGridRowsFromActions(selectedAction).find(
        (row) => row.id === valueSecondary
      )
    ) {
      return 'rows';
    }

    if (
      this.getGridColumnsFromActions(selectedAction).find(
        (column) => column.id === valueSecondary
      )
    ) {
      return 'columns';
    }

    return '';
  }

  /* Rows Columns eield */
  addRowsColumnsControl() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    if (!fieldGroup.contains('rows_columns')) {
      fieldGroup.addControl(
        'rows_columns',
        new FormControl(null, [Validators.required])
      );
    }
  }

  removeRowsColumnsControl() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    if (fieldGroup.contains('rows_columns')) {
      fieldGroup.removeControl('rows_columns');
    }
  }

  formContainsRowsColumnsControl() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    return fieldGroup.contains('rows_columns');
  }

  /* Attribute Source field */
  addAttributeSourceControl() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    if (!fieldGroup.contains('attribute_source')) {
      fieldGroup.addControl(
        'attribute_source',
        new FormControl(null, [Validators.required])
      );
    }
    if (fieldGroup.get('rows_columns')?.invalid) {
      this.attributeSourceHasValue = true;
    }
  }

  removeAttributeSourceControl() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    if (fieldGroup.contains('attribute_source')) {
      fieldGroup.removeControl('attribute_source');
    }
  }

  formContainsAttributeSourceControl() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    return fieldGroup.contains('attribute_source');
  }

  addRankScaleControls(): void {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    if (!fieldGroup.contains('attribute_source')) {
      this.addAttributeSourceControl();
    }
  }

  removeRankScaleControls() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    if (fieldGroup.contains('attribute_source')) {
      this.removeAttributeSourceControl();
    }
  }

  formContainsRankScaleControls() {
    const fieldGroup = this.criteriaForm.get('field') as FormGroup;
    return (
      fieldGroup.contains('attribute_source') &&
      !fieldGroup.contains('rows_columns')
    );
  }

  itIsRankScale(): boolean {
    return (
      this.formContainsRankScaleControls() &&
      (this.selectedActionKindVariant === ActionKindVariant.grid_rank ||
        this.selectedActionKindVariant === ActionKindVariant.grid_scale)
    );
  }

  searchMissions(searchTerm: string): void {
    this.loadMissions(this.brandId, searchTerm);
  }

  loadMissions(brandId, searchTerm: string = ''): void {
    this.searchingMissions.next(true);
    this.suzyData
      .searchMissions(searchTerm, brandId)
      .pipe(
        tap((missions) => {
          this.brandMissions = missions;
          this.searchingMissions.next(false);
        }),
        take(1),
        catchError(() => {
          this.searchingMissions.next(false);
          return EMPTY;
        })
      )
      .subscribe();
  }

  getSelectedMissionActions(selectedMission: IMission): void {
    if (selectedMission) {
      this.suzyData
        .getMissionActions(this.brandId, selectedMission.mission_id)
        .pipe(
          tap((actions) => {
            this.attributeList = this.createQuestionsList(actions);
            this.actions = actions;
          }),
          take(1),
          catchError((err) => {
            return err;
          })
        )
        .subscribe();
    } else {
      this.clearData();
      this.removeIdentifierControl();
      this.removeGridControls();
      this.removeRankScaleControls();
      this.criteriaForm.get('field')?.get('field')?.reset();
      this.clearParametersList();
    }
  }

  prepareForm(fieldValue: any): void {
    this.selectedParameters = this.formValue.parameters.map((param) => {
      return { ...param, checked: true };
    });

    this.attributeChange({
      newSelection:
        this.formValue.field.source !== ECBPCategory.Responses
          ? fieldValue._drag_id
          : fieldValue.action_id,
      owner: {
        data: this.attributeList,
      },
    });

    const { parameters, ...form } = this.formValue;

    const itemToPatch = {
      ...form,
      field: {
        ...this.formValue.field,
        field:
          this.formValue.field.source !== ECBPCategory.Responses
            ? fieldValue._drag_id
            : fieldValue.action_id,
      },
    };

    delete itemToPatch.field.field.mission_id;

    const action = this.attributeList.find((item) => {
      return this.formValue.field.source === ECBPCategory.Responses
        ? item._drag_id === fieldValue.action_id
        : item._drag_id === fieldValue.identifier;
    });

    const valueSecondary = parameters[0].value_secondary;
    const displaySecondary = parameters[0].display_secondary;

    if (this.formValue.field.source === ECBPCategory.Responses) {
      itemToPatch.field.attribute_source = valueSecondary;

      if (action && action.actionKind === ActionKindVariant.grid_open) {
        itemToPatch.field.rows_columns = this.isRowsOrColumns(
          itemToPatch.field.action_id,
          valueSecondary
        );

        this.addGridControls();
      }
    }

    this.criteriaForm.patchValue(itemToPatch);

    if (this.formValue.field.source === ECBPCategory.Responses) {
      this.operators = this.cbpService.getOperatorsByActionKind(action.action);

      if (action && action.actionKind === ActionKindVariant.grid_open) {
        this.updateRowsColumns({ value: itemToPatch.field.rows_columns });
      }

      this.selectedOperator({
        newSelection: {
          itemText: this.formValue.ui_operator,
        },
      });

      if (this.formContainsAttributeSourceControl()) {
        this.attributeSourceChange({
          newSelection: valueSecondary,
          displayText: displaySecondary,
        });
      }
    }
  }
}
