<div class="loading" *ngIf="!(ui.config && ui.logged_in && ui.defaultTableViews.loaded)">
  <igx-circular-bar [animate]="false" [indeterminate]="true" [textVisibility]="false"></igx-circular-bar>
</div>

<ng-container *ngIf="ui.config && ui.logged_in && ui.defaultTableViews.loaded">
  <data-explorer-main-header [title]="getHeaderTitle() | translate" [badgeLabel]="'Beta'"
    [showBadge]="!ui.custom_banners_points || !ui.manage_tables" [showHelpCenter]="true"
    [useBackLocation]="ui.building || ui.custom_banners_points! || ui.manage_tables!" (backClicked)="goBack($event)"
    [showManageDataButton]="config.showManageData ?? true" [brandId]="ui.brand_id"
    [helpCenterUrl]="'https://support.suzy.com/hc/en-us/articles/13739480587028-Overview-of-Data-Explorer'"
    (openManageData)="gotoManageData($event)" (openCustomBanners)="goToCustomBannerPointsUI()">
  </data-explorer-main-header>
  <div class="explorer-container">
    <!-- missions dropdowns -->
    <div class="missions-dropdown" *ngIf="!ui.building  && !ui.custom_banners_points && !ui.manage_tables">
      <div class="mission-header-container">
        <div class="w-full">
          <ng-container *ngIf="ui.mission; else elseSelector">
            <selected-mission-item [mission]="ui.mission" (remove)="openConfirm($event)"
              (exportSurvey)="exportSurvey.emit($event)"
              (navigateTo)="navigateToDetail($event)"></selected-mission-item>
          </ng-container>
          <ng-template #elseSelector>
            <selected-mission-dropdown label="{{ 'dataExplorer.missionSearchTitle' | translate }}"
              [placeholder]="'Select Mission'" [missions]="brandMissions" [searching]="searchingMissions"
              [cssClass]="'search-container'"
              (missionSelected)="getSelectedMissionActions($event)"
              (searchMissionsByTerm)="searchMissions($event)"></selected-mission-dropdown>
          </ng-template>
        </div>
        <!-- <igx-divider [vertical]="true"></igx-divider> -->
        <!-- Custom Banner Points -->
        <!-- <button *ngIf="cbpActive" class="suzy-secondary-text min-width-auto" (click)="goToCustomBannerPointsUI()">
          <span class="material-symbols-rounded">insights</span>
          {{ 'customBannerPoints.btnTitle' | translate }}
        </button> -->
      </div>
    </div>
    <!-- Actions + table -->
    <div class="grid-container">
      <div class="grid-cols-1"
        *ngIf="!ui.building && !ui.chart_expanded && !ui.custom_banners_points && !ui.manage_tables">
        <!-- questions/actions list -->
        <selected-mission-questions-list [mission]="ui.mission" [actions]="missionActions" [activeItem]="activeAction"
          [loading]="questionLoading" (actionSelected)="actionSelected($event)"></selected-mission-questions-list>
      </div>
      <div class="grid-cols-4"
        [ngClass]="{'grid-cols-5 pt-16': ui.building || ui.custom_banners_points || ui.manage_tables, 'full-chart': ui.chart_expanded}">
          <ng-container *ngIf="ui.custom_banners_points; else elseManageTables;">
            <!-- Custom Banner Points -->
            <custom-banner-points-layout class="de-routes" [ui]="ui" [actions]="missionActions" (navigateTo)="navigateTo($event)">
            </custom-banner-points-layout>
          </ng-container>
          <ng-template #elseManageTables>
              <!-- Manage Tables -->
              <manage-tables-layout  *ngIf="ui.manage_tables; else elseGrid;" class="de-routes" [ui]="ui" [actions]="missionActions" (navigateTo)="navigateTo($event)">
              </manage-tables-layout>
          </ng-template>
        <ng-template #elseGrid>
          <data-explorer-grid [ui]="ui" [actions]="missionActions" [question]="question" [tableViewIdfromPath]="idsFromPath?.tableview"
            (builderToggle)="builderToggle($event)" (expandChart)="expandChart.emit($event)"
            (manageTableViews)="openManageTableView($event)" (openSPForm)="navigateToSPForm($event)"
            (saveFabrication)="handleFabricationChange($event)" #explorerGrid></data-explorer-grid>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>

<igx-dialog #surveyExporterDialog>
  <data-explorer-survey-exporter [dialog]="surveyExporterDialog" [exportKind]="selectedExportKind"
    [mission]="ui.mission" [brandId]="ui.brand_id" [brandName]="ui.brand_name"
    (closed)="onExportDialogClosed($event)"></data-explorer-survey-exporter>
</igx-dialog>

<igx-snackbar #snackbar [actionText]="'builder.confirm_close' | translate" (clicked)="snackbar.close()">{{
  ui.snackbar_msg ?? '' }}</igx-snackbar>

<igx-dialog #missionRemoveConfirm *ngIf="ui.mission">
  <mission-remove-dialog [dialog]="missionRemoveConfirm" (remove)="removeMission($event)"></mission-remove-dialog>
</igx-dialog>