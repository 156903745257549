/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnDestroy } from '@angular/core';
import {
  MissionKind,
  QuotaEntity,
  QuotaProfileField,
} from '@asksuzy/typescript-sdk';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import {
  Observable,
  Subject,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  map,
} from 'rxjs';
import {
  EFabricationEndpointType,
  EFabricationType,
} from '../../../../enums/cbp-enum';
import { IDimension } from '../../../../models/data/request/IDimension';
import { IField } from '../../../../models/data/request/IField';
import { IOrchestrationAgeGroupClassification } from '../../../../models/data/request/IOrchestrationAgeGroupClassification';
import { IFabricationDetail } from '../../../../models/fabrication/IFabricationDetail';
import { IFabItem } from '../../../../models/fabrication/IFabricationResponse';
import { IDPair } from '../../../../models/suzy/IDPair';
import { ICrosstabConfigUI } from '../../../../models/ui/i-crosstab-config-ui';
import { CustomBannerPointsActionsService } from '../../../../services/custom-banner-points-actions.service';
import { CustomBannerPointsService } from '../../../../services/custom-banner-points.service';
import { SuzyDataService } from '../../../../services/suzy-data.service';
import {
  IDimensionFilterData,
  IDimensionFilterKind,
} from './i-dimension-filter-data';
import { IDimensionFilterUI } from './i-dimension-filter-ui';

@Component({
  selector: 'app-dimension-filter',
  templateUrl: './dimension-filter.component.html',
  styleUrls: ['./dimension-filter.component.scss'],
})
export class DimensionFilterComponent implements OnDestroy {
  public filter_options = {
    keyword: '',
    raw: [] as Array<IDPair>,
    filtered: [] as Array<IDPair>,
  };

  public get masterCheckbox() {
    const options = {
      checked: false,
      include: 0,
      indeterminate: false,
    };
    this.filter_options.filtered.forEach((option) => {
      if (option.include) {
        options.include++;
        options.checked = true;
      }
    });
    if (options.include === this.filter_options.filtered.length) {
      options.indeterminate = false;
    } else {
      if (options.include > 0) {
        options.indeterminate = true;
      }
    }
    return options;
  }

  private _data?: IDimensionFilterData;
  get data(): IDimensionFilterData | undefined {
    return this._data;
  }
  @Input() set data(value: IDimensionFilterData | undefined) {
    this.initialize(value);
    this._data = value;
  }

  @Input()
  public dialog: IgxDialogComponent | undefined;

  @Input()
  public crosstabConfig?: ICrosstabConfigUI;

  public ui: IDimensionFilterUI = {
    loading: false,
    removeable: false,
    brand_id: '',
  };

  private readonly optionFilterSubject = new Subject<string>();
  private fieldValueCache: { [id: string]: Array<IDPair> } = {};
  private isExternalLinkMission?: boolean;
  private missionId?: string;

  private searchSubscription?: Subscription;
  dialogKinds = IDimensionFilterKind;
  dialogKind!: IDimensionFilterKind;
  dialogTitle!: string;
  dialogSubTitle!: string;
  inputLabel!: string;

  constructor(
    private suzy: SuzyDataService,
    private cbpActions: CustomBannerPointsActionsService,
    private cbpService: CustomBannerPointsService
  ) {}

  public ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
    this.optionFilterSubject.complete();
  }

  initialize(filterData?: IDimensionFilterData): void {
    this.searchSubscription?.unsubscribe();
    if (!filterData) {
      return;
    }

    if (filterData.renaming) {
      this.dialogKind = IDimensionFilterKind.rename;
      this.filter_options = {
        keyword: '',
        raw: [] as Array<IDPair>,
        filtered: [] as Array<IDPair>,
      };
      this.inputLabel = 'builder.dimensionFilterLabel';
      this.dialogTitle = 'builder.renameDimensionLabel';
      return;
    }

    this.ui.brand_id = filterData.brand_id;
    this.ui.removeable =
      !!filterData.dimension.selection || filterData.dimension.type == 'net';
    if (filterData.type === 'field') {
      this.dialogKind = IDimensionFilterKind.filter;
    } else if (filterData.type === 'net') {
      this.dialogKind = IDimensionFilterKind.net;
    }
    this.filter_options = {
      keyword: '',
      raw: [] as Array<IDPair>,
      filtered: [] as Array<IDPair>,
    };

    switch (this.dialogKind) {
      case IDimensionFilterKind.filter:
        this.dialogTitle = 'builder.dimensionFilterTitle';
        this.dialogSubTitle = 'builder.dimensionFilterSubTitle';
        this.inputLabel = 'builder.dimensionFilterLabel';
        break;
      case IDimensionFilterKind.net:
        this.dialogSubTitle = 'builder.dimensionNetSubTitle';
        if (!filterData.is_row) {
          this.dialogTitle = 'builder.dimensionNetTitle';
          this.inputLabel = 'builder.dimensionNetLabel';
        } else {
          this.dialogTitle = 'builder.dimensionNetTitleRow';
          this.inputLabel = 'builder.dimensionNetLabelRow';
        }
        break;
    }

    this.isExternalLinkMission =
      filterData.mission?.mission_kind === MissionKind.external_link;
    this.missionId = filterData.mission?.mission_id ?? '';

    this.loadDimensionValuesCached(
      filterData.dimension,
      filterData.action_id_primary,
      {
        age_classification_id:
          filterData.age_classification_id ??
          this.suzy.standardAgeClassificationId,
        region_classification_id:
          filterData.region_classification_id ??
          this.suzy.standardRegionClassificationId,
        custom_age_group: filterData.custom_age_group,
      }
    ).subscribe({
      next: (data) => {
        const copy = JSON.parse(JSON.stringify(data));
        if (filterData.dimension.selection) {
          // prepare defaults
          if (
            filterData.dimension.selection.includes &&
            filterData.dimension.selection.includes.length > 0
          ) {
            for (const item of copy) {
              item.include = false;
            }
          } else {
            for (const item of copy) {
              item.include = true;
            }
          }
          // apply selection
          for (const item of copy) {
            if (
              filterData.dimension.selection?.includes &&
              filterData.dimension.selection.includes.find(
                (x: any) => x == item.id
              )
            ) {
              item.include = true;
            }
            if (
              filterData.dimension.selection?.excludes &&
              filterData.dimension.selection.excludes.find(
                (x: any) => x == item.id
              )
            ) {
              item.include = false;
            }
          }
        } else {
          for (const item of copy) {
            item.include = true;
          }
        }
        this.filter_options.keyword = '';
        this.filter_options.raw = copy;
        this.filter_options.filtered = copy;
      },
    });

    setTimeout(() => {
      this.searchSubscription = this.optionFilterSubject
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((search) => this.applyOptionFilter(search));
    });
  }

  removeFilter() {
    if (this.data?.source) {
      this.data.source.type = 'field';
      delete this.data.source.selection;
    }
    this.dialog?.close();
  }

  saveToDimension(destination: IDimension): boolean {
    if (!this.data?.dimension) {
      return true;
    }

    if (!this.data.renaming && this.filter_options) {
      if (!this.data.dimension.selection) {
        this.data.dimension.selection = {};
      }
      this.data.dimension.selection.excludes = [];
      this.data.dimension.selection.includes = [];

      let includeCount = 0;
      let excludeCount = 0;
      let includes = [] as Array<string>;
      const excludes = [] as Array<string>;
      for (const item of this.filter_options.raw) {
        if (item.include) {
          //includes.push(item.id);  // we can't provide both include and exclude, presume exclude is the true intent
          includeCount++;
        } else {
          excludes.push(item.id);
          excludeCount++;
        }
      }

      if (excludeCount === this.filter_options.raw.length) {
        //TODO: Show Warning Message?
        // Cannot exclude all items.. remove the dimension instead.
        return false;
      }

      if (this.data.type === 'field') {
        if (includeCount === this.filter_options.raw.length) {
          includes = [];
        }
        if (includes.length === 0 && excludes.length === 0) {
          delete destination.selection;
        } else {
          destination.selection = {
            includes: includes.length ? includes : undefined,
            excludes: excludes.length ? excludes : undefined,
          };
        }
        destination.type = this.data.type;
      }
      if (this.data.type === 'net') {
        if (includes.length === 0 && excludes.length === 0) {
          destination.selection = {
            includes_all: true,
          };
        } else {
          destination.selection = {
            includes: includes.length ? includes : undefined,
            excludes: excludes.length ? excludes : undefined,
          };
        }
        destination.type = this.data.type;
      }
    }
    destination.label = this.data.dimension.label;
    return true;
  }

  closeSelf(save: boolean): void {
    if (save) {
      if (this.data?.source) {
        if (!this.saveToDimension(this.data.source)) {
          return;
        }
      }
    }
    this.filter_options = {
      keyword: '',
      raw: [] as Array<IDPair>,
      filtered: [] as Array<IDPair>,
    };
    this.dialog?.close();
  }

  filterOptionsDelayed(keyword: string): void {
    this.optionFilterSubject.next(keyword?.trim());
  }

  applyOptionFilter(keyword: string): void {
    this.filter_options.keyword = keyword;
    this.filter_options.filtered = this.filter_options.raw.filter(
      (x) => x.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1
    );
  }

  loadDimensionValuesCached(
    dimension: IDimension,
    action_id_primary?: string,
    params?: {
      age_classification_id?: string;
      region_classification_id?: string;
      custom_age_group?: IOrchestrationAgeGroupClassification;
    }
  ): Observable<Array<IDPair>> {
    const dim = JSON.parse(JSON.stringify(dimension)) as IDimension;
    return new Observable<Array<IDPair>>((observable) => {
      if (!dim.field) {
        observable.next([]);
        return;
      }
      if (
        dim.field.source === 'answer' &&
        dim.field.field.startsWith('quota_field_')
      ) {
        const mappedField = this.getFieldForQuotaField(dim.field.field);
        if (mappedField) {
          dim.field.source = mappedField.source;
          dim.field.field = mappedField.field;
          if (mappedField.field === 'targeted_brand_cluster_id') {
            dim.field.identifier = mappedField.identifier;
          }
          if (mappedField.field === 'action_setting_id_cross') {
            dim.field.action_id = mappedField.action_id;
          }
        }
      }
      const { source, field, action_id, identifier } = dim.field;
      if (
        source === 'answer' &&
        field !== 'targeted_brand_cluster_id' &&
        !field.startsWith('quota_field_')
      ) {
        const isPrimaryResponse =
          field === 'action_setting_id_root' && action_id_primary;
        const isCrosstabQuestion =
          field === 'action_setting_id_cross' && action_id;
        const isPrimarySelection =
          field === 'action_setting_id_secondary_root' && action_id_primary;

        if (isPrimaryResponse || isCrosstabQuestion) {
          const tmpActionId = isPrimaryResponse
            ? action_id_primary
            : action_id ?? '';
          const tmpKey = `action_${tmpActionId}`;
          if (this.fieldValueCache[tmpKey]) {
            observable.next(this.fieldValueCache[tmpKey]);
          } else {
            this.ui.loading = true;
            this.suzy
              .getAnswerChoicesForAction(this.ui.brand_id, tmpActionId ?? '')
              .subscribe({
                next: (data) => {
                  this.ui.loading = false;
                  this.fieldValueCache[tmpKey] = data;
                  observable.next(data);
                },
                error: (error) => {
                  this.ui.loading = false;
                  this.ui.error = error;
                },
              });
          }
        } else if (isPrimarySelection) {
          const tmpKey = `action_selection_${action_id_primary}`;
          if (this.fieldValueCache[tmpKey]) {
            observable.next(this.fieldValueCache[tmpKey]);
          } else {
            this.ui.loading = true;
            this.suzy
              .getPrimarySelectionForAction(
                this.ui.brand_id,
                action_id_primary ?? ''
              )
              .subscribe({
                next: (data) => {
                  this.ui.loading = false;
                  this.fieldValueCache[tmpKey] = data;
                  observable.next(data);
                },
                error: (error) => {
                  this.ui.loading = false;
                  this.ui.error = error;
                },
              });
          }
        } else {
          observable.next([]);
        }
      } else if (source === 'demographic' && field === 'age_group_id') {
        const ageClassificationId =
          params?.age_classification_id ??
          this.suzy.standardAgeClassificationId;
        const tmpKey = `age_classification_${ageClassificationId}`;
        if (this.fieldValueCache[tmpKey]) {
          observable.next(this.fieldValueCache[tmpKey]);
        } else {
          this.ui.loading = true;
          this.suzy.ageGroupGetValues(ageClassificationId).subscribe({
            next: (data) => {
              this.ui.loading = false;
              this.fieldValueCache[tmpKey] = data;
              observable.next(data);
            },
            error: (error) => {
              this.ui.loading = false;
              this.ui.error = error;
            },
          });
        }
      } else if (source === 'demographic' && field === 'custom_age_group_id') {
        if (!params?.custom_age_group) {
          observable.next([]);
          return;
        }

        const { custom_age_group } = params;
        const agrGroups = custom_age_group.age_groups.map((ageGroup) => {
          return {
            id: ageGroup.age_group_id,
            name: ageGroup.age_group_name,
          } as IDPair;
        });
        observable.next(agrGroups);
      } else if (source === 'demographic' && field === 'region_id') {
        const regionClassificationId =
          params?.region_classification_id ??
          this.suzy.standardRegionClassificationId;
        const tmpKey = `region_classification_${regionClassificationId}`;
        if (this.fieldValueCache[tmpKey]) {
          observable.next(this.fieldValueCache[tmpKey]);
        } else {
          this.ui.loading = true;
          this.suzy.regionGetValues(regionClassificationId).subscribe({
            next: (data) => {
              this.ui.loading = false;
              this.fieldValueCache[tmpKey] = data;
              observable.next(data);
            },
            error: (error) => {
              this.ui.loading = false;
              this.ui.error = error;
            },
          });
        }
      } else if (
        source === 'profile' &&
        field === 'attributed_brand_cluster_group_id'
      ) {
        if (identifier) {
          if (this.fieldValueCache[identifier]) {
            observable.next(this.fieldValueCache[identifier]);
          } else {
            this.ui.loading = true;
            this.suzy
              .brandClusterGroupGetValues(this.ui.brand_id, identifier)
              .subscribe({
                next: (data) => {
                  this.ui.loading = false;
                  this.fieldValueCache[identifier] = data;
                  observable.next(data);
                },
                error: (error) => {
                  this.ui.loading = false;
                  this.ui.error = error;
                },
              });
          }
        } else {
          observable.next([]);
        }
      } else if (source === 'fabrication' || source === 'derived') {
        const fabricationId = field;

        const fabrications =
          dim.fabricationType === EFabricationType.Subpopulation
            ? this.cbpActions.builderSubpopulations.getValue()
            : this.cbpActions.bannerDerivedQuestions.getValue();
        const fabrication = fabrications.find(
          (fab: IFabItem) => fab.known_items[0]!.item_id === fabricationId
        );

        if (fabrication) {
          this.cbpService
            .getFabricationDetails(
              fabrication.fabrication_id,
              this.ui.brand_id,
              dim.fabricationType === EFabricationType.Subpopulation
                ? EFabricationEndpointType.Subpopulation
                : EFabricationEndpointType.DerivedQuestions,
              fabrication.mission_id
            )
            .pipe(
              map((fabDetail: IFabricationDetail) => {
                const payload = JSON.parse(fabDetail.item.json_payload);
                return this.cbpActions.getFabricationOutcomes(payload);
              })
            )
            .subscribe({
              next: (data) => {
                this.ui.loading = false;
                this.fieldValueCache[dim.field!.field] = data;
                observable.next(data);
              },
              error: (error) => {
                this.ui.loading = false;
                this.ui.error = error;
              },
            });
        } else {
          observable.next([]);
        }
      } else {
        switch (field) {
          case 'ethnicity_id':
            if (this.fieldValueCache[field]) {
              observable.next(this.fieldValueCache[field]);
            } else {
              this.ui.loading = true;
              this.suzy.ethnicityGetValues(this.ui.brand_id).subscribe({
                next: (data) => {
                  this.ui.loading = false;
                  this.fieldValueCache[dim.field!.field] = data;
                  observable.next(data);
                },
                error: (error) => {
                  this.ui.loading = false;
                  this.ui.error = error;
                },
              });
            }
            break;
          case 'state_id':
            if (this.fieldValueCache[field]) {
              observable.next(this.fieldValueCache[field]);
            } else {
              this.ui.loading = true;
              this.suzy.stateGetValues(this.ui.brand_id).subscribe({
                next: (data) => {
                  this.ui.loading = false;
                  this.fieldValueCache[dim.field!.field] = data;
                  observable.next(data);
                },
                error: (error) => {
                  this.ui.loading = false;
                  this.ui.error = error;
                },
              });
            }
            break;
          case 'relationship_id':
            if (this.fieldValueCache[field]) {
              observable.next(this.fieldValueCache[field]);
            } else {
              this.ui.loading = true;
              this.suzy.relationshipGetValues(this.ui.brand_id).subscribe({
                next: (data) => {
                  this.ui.loading = false;
                  this.fieldValueCache[dim.field!.field] = data;
                  observable.next(data);
                },
                error: (error) => {
                  this.ui.loading = false;
                  this.ui.error = error;
                },
              });
            }
            break;
          case 'gender':
            observable.next([
              {
                id: '1',
                name: 'Female', // support language when the others do
              },
              {
                id: '2',
                name: 'Male', // support language when the others do
              },
            ]);
            break;
          case 'education_id':
            if (this.fieldValueCache[field]) {
              observable.next(this.fieldValueCache[field]);
            } else {
              this.ui.loading = true;
              this.suzy.educationLevelGetValues().subscribe({
                next: (data) => {
                  this.ui.loading = false;
                  this.fieldValueCache[dim.field!.field] = data;
                  observable.next(data);
                },
                error: (error) => {
                  this.ui.loading = false;
                  this.ui.error = error;
                },
              });
            }
            break;
          case 'employment_id':
            if (this.fieldValueCache[field]) {
              observable.next(this.fieldValueCache[field]);
            } else {
              this.ui.loading = true;
              this.suzy.employmentStatusGetValues().subscribe({
                next: (data) => {
                  this.ui.loading = false;
                  this.fieldValueCache[dim.field!.field] = data;
                  observable.next(data);
                },
                error: (error) => {
                  this.ui.loading = false;
                  this.ui.error = error;
                },
              });
            }
            break;
          case 'household_id':
            if (this.fieldValueCache[field]) {
              observable.next(this.fieldValueCache[field]);
            } else {
              this.ui.loading = true;
              this.suzy.householdSizeGetValues().subscribe({
                next: (data) => {
                  this.ui.loading = false;
                  this.fieldValueCache[dim.field!.field] = data;
                  observable.next(data);
                },
                error: (error) => {
                  this.ui.loading = false;
                  this.ui.error = error;
                },
              });
            }
            break;
          case 'income_level_id':
            if (this.fieldValueCache[field]) {
              observable.next(this.fieldValueCache[field]);
            } else {
              this.ui.loading = true;
              this.suzy.incomeLevelGetValues().subscribe({
                next: (data) => {
                  this.ui.loading = false;
                  this.fieldValueCache[dim.field!.field] = data;
                  observable.next(data);
                },
                error: (error) => {
                  this.ui.loading = false;
                  this.ui.error = error;
                },
              });
            }
            break;
          case 'parenting_id':
            if (this.fieldValueCache[field]) {
              observable.next(this.fieldValueCache[field]);
            } else {
              this.ui.loading = true;
              this.suzy.parentingStatusGetValues().subscribe({
                next: (data) => {
                  this.ui.loading = false;
                  this.fieldValueCache[dim.field!.field] = data;
                  observable.next(data);
                },
                error: (error) => {
                  this.ui.loading = false;
                  this.ui.error = error;
                },
              });
            }
            break;
          case 'targeted_brand_cluster_id':
            const group_key = identifier;
            if (group_key) {
              if (this.fieldValueCache[group_key]) {
                observable.next(this.fieldValueCache[group_key]);
              } else {
                this.ui.loading = true;
                this.suzy
                  .brandClusterGroupGetValues(this.ui.brand_id, group_key)
                  .subscribe({
                    next: (data) => {
                      this.ui.loading = false;
                      this.fieldValueCache[group_key!] = data;
                      observable.next(data);
                    },
                    error: (error) => {
                      this.ui.loading = false;
                      this.ui.error = error;
                    },
                  });
              }
            } else {
              observable.next([]);
            }
            break;
          case 'brand_profile_question_id':
            const profile_key = identifier;
            if (profile_key) {
              if (this.fieldValueCache[profile_key]) {
                observable.next(this.fieldValueCache[profile_key]);
              } else {
                this.ui.loading = true;
                this.suzy
                  .getBrandProfileQuestionValues(
                    this.ui.brand_id,
                    profile_key,
                    this.isExternalLinkMission ?? false,
                    this.missionId ?? ''
                  )
                  .subscribe({
                    next: (data) => {
                      this.ui.loading = false;
                      this.fieldValueCache[profile_key!] = data;
                      observable.next(data);
                    },
                    error: (error) => {
                      this.ui.loading = false;
                      this.ui.error = error;
                    },
                  });
              }
            }
            break;
          default:
            observable.next([]);
        }
      }
    });
  }

  toggleAllFilterOptions(): void {
    if (this.masterCheckbox.checked) {
      for (const option of this.filter_options.filtered) {
        option.include = false;
      }
    } else {
      for (const option of this.filter_options.filtered) {
        option.include = true;
      }
    }
  }

  private getFieldForQuotaField(field: string): IField | undefined {
    if (!this.crosstabConfig || !this.crosstabConfig.meta.quota_fields) {
      return;
    }

    const { quota_fields } = this.crosstabConfig.meta;
    const quotaFieldIx = parseInt(field.replace('quota_field_', ''));
    if (isNaN(quotaFieldIx) || quotaFieldIx > quota_fields.length) {
      return;
    }

    const quotaField = quota_fields[quotaFieldIx - 1];
    const { quota_entity, quota_profile_field, quota_custom_field, action_id } =
      quotaField;

    switch (quota_entity) {
      case QuotaEntity.cluster_group:
        if (!quota_custom_field) {
          return;
        }
        return {
          source: 'answer',
          field: 'targeted_brand_cluster_id',
          identifier: quota_custom_field,
        } as IField;
      case QuotaEntity.previous_action:
        if (!action_id) {
          return;
        }
        return {
          source: 'answer',
          field: 'action_setting_id_cross',
          action_id,
        } as IField;
      case QuotaEntity.profile:
        switch (quota_profile_field) {
          case QuotaProfileField.age_group:
            return {
              source: 'demographic',
              field: 'age_group_id',
            } as IField;
          case QuotaProfileField.gender:
            return {
              source: 'demographic',
              field: 'gender',
            } as IField;
          case QuotaProfileField.physical_ethnicity:
            return {
              source: 'demographic',
              field: 'ethnicity_id',
            } as IField;
          case QuotaProfileField.region:
            return {
              source: 'demographic',
              field: 'region_id',
            } as IField;
          case QuotaProfileField.income:
            return {
              source: 'demographic',
              field: 'income_level_id',
            } as IField;
        }
        return;
    }
  }
}
