import { Dialog } from '@angular/cdk/dialog';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  Subject,
  distinctUntilChanged,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { BASE_OPERATORS } from '../../../constants/cbp.constant';
import {
  ECBPCategory,
  EFabricationEndpointType,
  EFabricationName,
  EFabricationType,
} from '../../../enums/cbp-enum';
import {
  IFabrication,
  IFabricationItem,
} from '../../../models/fabrication/IFabrication';
import { IFabItemDetail } from '../../../models/fabrication/IFabricationDetail';
import { IFabricationResponse } from '../../../models/fabrication/IFabricationResponse';
import { ICBPFormTranslation } from '../../../models/ui/IInjectionToken';
import { IExplorerUI } from '../../../models/ui/i-explorer-ui';
import { CustomBannerPointsActionsService } from '../../../services/custom-banner-points-actions.service';
import { CustomBannerPointsService } from '../../../services/custom-banner-points.service';
import { atLeastOneValidator } from '../../../utilities/at-least-one.validator';
import { DataUtility } from '../../../utilities/data-utility';
import { CbpConfirmActionDialogComponent } from '../cbp-confirm-action-dialog/cbp-confirm-action-dialog.component';
import { ELibraryRoutes } from '../../../enums/de-routes.enum';
import { LibraryNavigationService } from '../../../services/library-navigation.service';
import { justOneResponseValidator } from '../../../utilities/just-one-response.validator';
import { TableViewsRequestsService } from '../../../services/table-views-requests.service';

@Component({
  selector: 'base-fabrication-form',
  templateUrl: './base-fabrication-form.component.html',
  styleUrls: ['./base-fabrication-form.component.scss'],
})
export class BaseFabricationFormComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() fabricationType?: EFabricationType;
  @Input() translations?: ICBPFormTranslation;
  @Input() uiData?: IExplorerUI;
  @Input() actions?: any[];
  @Input() isSaving!: boolean;
  @Input() editValue?: IFabrication | IFabItemDetail | null;
  @Input() toClone?: IFabrication | IFabItemDetail | null;
  @Input() builderDimensions?: unknown;
  @Input() isDialog = false;

  @Output() formValues = new EventEmitter<any>();
  @Output() navigateAway = new EventEmitter<boolean>(false);

  IS_OR_OPTIONS = [
    { label: 'And', value: 'false' },
    { label: 'Or', value: 'true' },
  ];
  fabricationItemForm!: FormGroup;

  fabrications: typeof EFabricationType = EFabricationType;
  actionIdIdentifier: string | null = null;

  selectedSavedBannerItem!: any;
  savedBannerList!: Observable<IFabricationResponse>;
  libRoutes: typeof ELibraryRoutes = ELibraryRoutes;
  currentRoute!: any;

  private unsubscribe = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private cbpActionsService: CustomBannerPointsActionsService,
    private cbpService: CustomBannerPointsService,
    private translate: TranslateService,
    private dialog: Dialog,
    private navigationStatus: LibraryNavigationService,
    private tableViewService: TableViewsRequestsService
  ) {
    this.fabricationItemForm = this.fb.group({
      title: [null, [Validators.required]],
      item_id: [null], // uuid generated
      item_priority: [1],
      allow_multiple: [true],
      outcomes: this.fb.array([], Validators.required),
      remainder: this.fb.group({
        include: [false],
        outcome: this.fb.group({
          outcome_priority: [2],
          outcome_id: [null], // uuid
          outcome_text: ['Leftovers'],
          source_group: null,
        }),
      }),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const uiDataChanges = changes['uiData'];
    const fabTypeChanges = changes['fabricationType'];
    const editValueChanges = changes['editValue'];
    const isDialogChanges = changes['isDialog'];

    const brandId = uiDataChanges.currentValue.brand_id;
    this.currentRoute =
      this.navigationStatus.DENavigationStatus.getValue()?.currentRoute;

    if (
      isDialogChanges?.currentValue &&
      !editValueChanges?.currentValue &&
      brandId
    ) {
      this.savedBannerList = this.cbpService.getFabricationsByType(
        brandId,
        EFabricationEndpointType.Subpopulation
      );
    }

    if (
      uiDataChanges?.currentValue &&
      fabTypeChanges?.currentValue.toString() &&
      !isDialogChanges?.currentValue
    ) {
      /*       this.fabricationItemForm
        .get('title')
        ?.addAsyncValidators([
          CheckBannerNameAsyncValidator.validator(
            brandId,
            fabType,
            this.cbpService
          ),
        ]); 
       this.fabricationItemForm.updateValueAndValidity();*/
    }

    if (editValueChanges?.currentValue) {
      this.populateForm(editValueChanges.currentValue);
    }
  }

  ngOnInit(): void {
    if (!this.editValue) {
      this.addOutcome();
    }
    this.fabricationItemForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(() => {
          if (
            this.fabricationItemForm.touched ||
            this.fabricationItemForm.dirty
          ) {
            this.cbpActionsService.formHasChanges.next(true);
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.cbpActionsService.formHasChanges.next(false);
    this.cbpActionsService.formHasChanges.complete();
  }

  newOutcome(): FormGroup {
    return this.fb.group({
      outcome_priority: [null], // index based + 1
      outcome_id: [null], // uuid generated by the UI
      outcome_text: [null, Validators.required], // TODO: Add checkGroupNameValidator() back here once corrected
      source_group: this.fb.group({
        sources: this.fb.array([this.newSource()]),
      }),
    });
  }

  newSource(): FormGroup {
    return this.fb.group({
      type: [null],
      identifier: ['primary'],
      is_or: ['false'],
      rule_group: this.fb.group({
        group_summary: [''],
        rules: this.fb.array(
          [this.newRule()],
          [justOneResponseValidator(this.currentRoute)]
        ),
      }),
    });
  }

  newRule(): FormGroup {
    return this.fb.group({
      is_or: ['false'],
      criteria_summary: [''],
      criteria: this.fb.group({
        operator: [BASE_OPERATORS[0].operator, Validators.required],
        ui_operator: [BASE_OPERATORS[0].name],
        field: this.fb.group({
          source: [null, Validators.required],
          mission_id: [null],
          brand_id: [null],
          field: [null, Validators.required],
          identifier: [null],
          action_id: [null],
        }),
        at_least: [1],
        at_most: [null],
        parameters: this.fb.array(
          [],
          [Validators.required, atLeastOneValidator]
        ),
      }),
    });
  }

  newParameter(): FormGroup {
    return this.fb.group({
      type: ['value'],
      value: [null],
      display: [null],
      value_secondary: [null],
      display_secondary: [null],
      checked: [false],
    });
  }

  get outcomes(): FormArray {
    return this.fabricationItemForm.get('outcomes') as FormArray;
  }

  getOutcomeSources(outcomeIdx: number): FormArray {
    return this.outcomes
      .at(outcomeIdx)
      .get('source_group')
      ?.get('sources') as FormArray;
  }

  getSourceRules(outcomeIdx: number, sourceIdx: number): any {
    return this.getOutcomeSources(outcomeIdx)
      .at(sourceIdx)
      .get('rule_group')
      ?.get('rules') as FormArray;
  }

  getActiveSourceRuleIsOrValue(outcomeIdx, sourceIdx, ruleIdx): string {
    return this.getSourceRules(outcomeIdx, sourceIdx).at(ruleIdx).get('is_or')
      ?.value;
  }

  getActiveSourceIsOrValue(outcomeIdx, sourceIdx): string {
    return this.getOutcomeSources(outcomeIdx).at(sourceIdx).get('is_or')?.value;
  }

  addOutcome(): void {
    this.outcomes.push(this.newOutcome());
  }

  addOutcomeSource(outcomeIdx: number): void {
    this.getOutcomeSources(outcomeIdx)?.push(this.newSource());
  }

  addSourceRule(outcomeIdx: number, sourceIdx: number): void {
    this.getSourceRules(outcomeIdx, sourceIdx).push(this.newRule(), {
      emitEvent: false,
    });
  }

  addCriteriaParam(
    outcomeIdx: number,
    sourceIdx: number,
    ruleIdx: number
  ): void {
    this.getSourceRules(outcomeIdx, sourceIdx)
      .at(ruleIdx)
      .get('criteria')
      .get('parameters')
      .push(this.newParameter(), { emitEvent: false });
  }

  removeOutcome(outcomeIdx: number): void {
    this.outcomes.removeAt(outcomeIdx);
  }

  removeSource(outcomeIdx: number, sourceIdx: number): void {
    this.getOutcomeSources(outcomeIdx).removeAt(sourceIdx);
  }

  removeRule(outcomeIdx: number, sourceIdx: number, ruleIdx: number): void {
    this.getSourceRules(outcomeIdx, sourceIdx).removeAt(ruleIdx);
  }

  clickCancel(): void {
    this.cbpActionsService.formHasChanges.next(true);
    this.navigateAway.emit(true);
  }

  createFabrication(): void {
    if (this.fabricationItemForm.valid) {
      if (this.editValue) {
        this.confirmBeforeProcess(this.editValue);
      } else {
        this.processFabrication();
      }
    }
  }

  confirmBeforeProcess(fabItem: IFabrication | IFabItemDetail): void {
    if (this.uiData?.brand_id) {
      this.tableViewService
        .verifyFabricationImpact(this.uiData.brand_id, fabItem.fabrication_id, {
          take: 100,
        })
        .pipe(
          tap((result) => {
            if (result.items.length > 0) {
              const listItems = result.items
                .map((item) => `<li>${item.display_name}</li>`)
                .join('');
              this.dialog.open(CbpConfirmActionDialogComponent, {
                data: {
                  title: this.translate.instant(
                    'customBannerPoints.formText.validations.confirmEdit.title',
                    {
                      fabrication:
                        this.fabricationType === EFabricationType.Subpopulation
                          ? EFabricationName.Subpopulation
                          : EFabricationName.DerivedQuestions,
                    }
                  ),
                  subtitle: this.translate.instant(
                    'customBannerPoints.formText.validations.confirmEdit.subtitle'
                  ),
                  customBody: `<ul class="ul-bullet">${listItems}</ul>`,
                  ctaBtn: this.translate.instant(
                    'customBannerPoints.formText.ctas.confirm'
                  ),
                  cancelBtn: this.translate.instant(
                    'customBannerPoints.formText.ctas.cancel'
                  ),
                  callback: () => {
                    this.processFabrication();
                  },
                },
              });
            } else {
              this.processFabrication();
            }
          }),
          take(1)
        )
        .subscribe();
    }
  }

  processFabrication(): void {
    const fabrication: IFabrication = {
      fabrication_id:
        this.editValue?.fabrication_id ?? DataUtility.generateGUID(),
      fabrication_name: '',
      fabrication_type: this.fabricationType,
      items: [],
    };
    const fabId = fabrication.fabrication_id;

    const mappedItem = this.matchPayload(this.fabricationItemForm.value, fabId);
    fabrication.items.push(mappedItem);

    this.formValues.emit(fabrication);
    this.cbpActionsService.formHasChanges.next(false);
    this.navigateAway.emit(true);
    this.resetAll();
  }

  confirmItemDelete(type: string, ...params: number[]): void {
    this.dialog.open(CbpConfirmActionDialogComponent, {
      data: {
        title: this.translate.instant(
          'customBannerPoints.formText.validations.confirmDeleteFormItem.title'
        ),
        ctaBtn: this.translate.instant(
          'customBannerPoints.formText.validations.confirmDeleteFormItem.ctaBtn'
        ),
        cancelBtn: this.translate.instant(
          'customBannerPoints.formText.validations.confirmDeleteFormItem.cancelBtn'
        ),
        callback: () => {
          if (type === 'outcome') {
            this.removeOutcome(params[0]);
          }

          if (type === 'source') {
            this.removeSource(params[0], params[1]);
          }

          if (type === 'rule') {
            this.removeRule(params[0], params[1], params[2]);
          }
        },
      },
    });
  }

  matchPayload(data: IFabricationItem, fabId: string) {
    return {
      ...data,
      item_id: data.item_id ?? DataUtility.generateGUID(),
      fabrication_id: fabId,
      remainder: {
        ...data.remainder,
        outcome: {
          ...data.remainder.outcome,
          outcome_id:
            data.remainder.outcome.outcome_id ?? DataUtility.generateGUID(),
        },
      },
      outcomes: data.outcomes.map((outcome) => {
        outcome.outcome_id = outcome.outcome_id ?? DataUtility.generateGUID();
        const sourceGroup = outcome.source_group;
        if (sourceGroup) {
          const isOr = sourceGroup.sources[1]
            ? sourceGroup.sources[1].is_or
            : 'false';
          sourceGroup.is_or = isOr === 'true';

          sourceGroup.sources = sourceGroup.sources.map((source) => {
            source.type = 'action';

            if (this.actionIdIdentifier) {
              source.identifier = this.actionIdIdentifier;
            }

            const ruleGroup = source.rule_group;

            if (ruleGroup) {
              const isOr = ruleGroup.rules[1]
                ? ruleGroup.rules[1].is_or
                : 'false';
              ruleGroup.is_or = isOr === 'true';

              ruleGroup.rules = ruleGroup.rules.map((rule) => {
                if (rule.criteria && rule.criteria.parameters) {
                  rule.criteria.parameters = rule.criteria.parameters
                    .filter((parameter) => parameter.checked)
                    .map((parameter) => {
                      const { checked, ...param } = parameter;

                      return param;
                    });
                }
                const { is_or, ...ruleItem } = rule;

                if (rule.criteria.field.source !== ECBPCategory.Responses) {
                  const uiId = rule.criteria.field.field;
                  const fieldValue =
                    this.cbpActionsService.getDimensionFieldValue(
                      this.builderDimensions,
                      uiId
                    );

                  ruleItem.criteria.field.field = fieldValue.field.field;
                  delete ruleItem.criteria.field.action_id;
                  delete ruleItem.criteria.field.brand_id;
                  delete ruleItem.criteria.field.mission_id;
                } else {
                  ruleItem.criteria.field.action_id =
                    ruleItem.criteria.field.field;

                  ruleItem.criteria.field.field = 'action_setting_id';
                  ruleItem.criteria.field.brand_id = this.uiData?.brand_id;

                  delete ruleItem.criteria.field.rows_columns;
                  delete ruleItem.criteria.field.attribute_source;
                }
                return ruleItem;
              });
            }
            const { is_or, ...sourceItem } = source;
            return sourceItem;
          });
        }
        return outcome;
      }),
    };
  }

  resetAll(): void {
    this.fabricationItemForm.reset();
    this.fabricationItemForm.markAsPristine();
    this.fabricationItemForm.updateValueAndValidity();
  }

  getActionIdForResponses(evt: string | null): void {
    this.actionIdIdentifier = evt;
  }

  startFromSavedBanner(evt: any): void {
    this.fabricationItemForm.reset();
    this.outcomes.clear();

    if (evt.newSelection) {
      this.cbpService
        .getFabricationDetails(
          evt.newSelection,
          this.uiData?.brand_id as string,
          EFabricationEndpointType.Subpopulation
        )
        .pipe(
          tap((details) => {
            const item = details.item;
            this.populateForm(item);
          }),
          take(1)
        )
        .subscribe();
    }
  }

  populateForm(data: any): void {
    const formPayload = data.json_payload
      ? JSON.parse(data.json_payload).items[0]
      : data.items[0];

    formPayload.outcomes.forEach((outcome, outcomeIdx) => {
      this.addOutcome();
      const groupIsOr = outcome.source_group.is_or;

      outcome.source_group.sources.map((source, sourceIdx) => {
        this.addOutcomeSource(outcomeIdx);
        source.is_or = String(groupIsOr);

        const ruleIsOr = source.rule_group.is_or;

        source.rule_group.rules.map((rule, ruleIdx) => {
          this.addSourceRule(outcomeIdx, sourceIdx);
          rule.is_or = String(ruleIsOr);

          rule.criteria.parameters.forEach((param, paramIdx) => {
            this.addCriteriaParam(outcomeIdx, sourceIdx, ruleIdx);
          });
          return rule;
        });

        this.removeRule(outcomeIdx, sourceIdx, -1);
        return source;
      });

      this.removeSource(outcomeIdx, -1);
    });

    this.fabricationItemForm.patchValue(formPayload);
    this.fabricationItemForm.markAllAsTouched();
  }
}
