<div class="suzy-main-header" [ngClass]="{ 'inner-header': isInnerHeader }">
  <button igxButton="icon" *ngIf="routingUrl || useBackLocation || showCustomBackBtn" (click)="onBackClick()"
    class="header-button-icon" [attr.data-track]="trackBackButton ? trackBackButton : 'back-btn'">
    <span class="material-symbols-rounded material-icon-20" aria-label="Back">west</span>
  </button>
  <img class="header-image" *ngIf="imageUrl" [src]="imageUrl" />

  <h2 *ngIf="title" class="title">{{ title }}</h2>

  <button *ngIf="showEdit" (click)="onEditClick()" class="header-button-icon">
    <span class="material-symbols-rounded fill-material-symbols-rounded material-icon-20" aria-label="edit">edit</span>
  </button>

  <section *ngIf="showBadge" class="suzy-badge {{ badgeClass }}">{{ badgeLabel }}</section>

  <div class="header-right-buttons">
    <a *ngIf="showHelpCenter" class="suzy-secondary-text-dark" target="_blank" [href]="helpCenterUrl">{{
      'dataExplorer.helpCenter' | translate
      }}</a>

    <button type="button" *ngIf="brandId" class="suzy-secondary-dark"
      (click)="onOpenCustomBannerPoints($event)">{{ 'dataExplorer.customDataPoints' | translate }}</button>
      <button type="button" *ngIf="showManageDataButton && brandId" class="suzy-secondary-dark" (click)="onOpenManageData()">{{ 'dataExplorer.manageData' | translate }}</button>
  </div>
</div>