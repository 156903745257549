import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IExplorerUI, INavigationStatus } from '../../../models/ui/i-explorer-ui';
import { LibraryNavigationService } from '../../../services/library-navigation.service';
import { GridColumnDataType } from '@infragistics/igniteui-angular';
import { ELibraryRoutes } from '../../../enums/de-routes.enum';

@Component({
  selector: 'data-explorer-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent {
  @Input() navigationData!: INavigationStatus;
  @Input() brandId!: string;
  @Input() title!: string;
  @Input() routingUrl: any;
  @Input() badgeLabel!: string;
  @Input() showBadge!: boolean;
  @Input() badgeClass = 'bold-purple';
  @Input() useBackLocation!: boolean;
  @Input() trackBackButton!: boolean;
  @Input() showEdit!: boolean;
  @Input() imageUrl!: string;
  @Input() showCustomBackBtn!: boolean;
  @Input() isInnerHeader = false;
  @Input() showHelpCenter = false;
  @Input() helpCenterUrl = '';
  @Input() showManageDataButton!: boolean | undefined;

  @Output() editClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() backClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() openManageData = new EventEmitter<boolean>(false);
  @Output() openCustomBanners: EventEmitter<boolean> = new EventEmitter();

  public ui: IExplorerUI = {
    logged_in: false,
    building: false,
    brand_id: '',
    loading: false,
    loader: new Set<string>(),
    notice: '',
    warnings: [],
    emptyDataMessage: '',
    emptyDataWarning: '',
    is_global: false,
    additions: [],
    is_addition: false,
    is_percent: true,
    data_type: GridColumnDataType.Number,
    show_chart: false,
    chart_expanded: false,
    defaultTableViews: {
      loaded: false,
      loading: true,
      data: [],
    },
    tableViews: { loaded: false, loading: true, data: [] },
    tableViewNamesDict: { loaded: false, loading: true, data: [] },
    snackbar_msg: '',
    config: undefined,
    custom_banners_points: false,
    manage_tables: false,
    fabrications: [],
  };

  constructor(
    private libraryNavigation: LibraryNavigationService,
    private navigationStatus: LibraryNavigationService
  ) {}

  onBackClick(): void {
    this.backClicked.emit(this.libraryNavigation.DENavigationStatus.getValue());
  }

  onEditClick(): void {
    this.editClicked.emit();
  }

  onOpenManageData(): void {
    this.openManageData.emit(true);
  }

  onOpenCustomBannerPoints(evt: any): void {
    this.openCustomBanners.emit(evt);

    if (evt) {
      this.navigateTo(ELibraryRoutes.CBPTabs);
    }
  }

  navigateTo(navigate: ELibraryRoutes): void {
    switch (navigate) {
      case ELibraryRoutes.CBPTabs:
        this.navigationStatus.DENavigationStatus.next({
          title: 'customBannerPoints.headerTitle',
          backTo: ELibraryRoutes.Explorer,
          currentRoute: ELibraryRoutes.CBPTabs,
        });
        break;
    }
  }

  goToCustomBannerPointsUI(): void {
this.openCustomBanners.emit(true)
  }
}
