import { Dialog } from '@angular/cdk/dialog';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MissionKind } from '@asksuzy/typescript-sdk';
import {
  GridColumnDataType,
  IgxDialogComponent,
  IgxIconService,
  IgxSnackbarComponent,
} from '@infragistics/igniteui-angular';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  EMPTY,
  Subject,
  catchError,
  exhaustMap,
  filter,
  of,
  repeat,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { EFabricationEndpointType } from '../../enums/cbp-enum';
import { ELibraryRoutes } from '../../enums/de-routes.enum';
import { IFabrication } from '../../models/fabrication/IFabrication';
import { IAction } from '../../models/suzy/IAction';
import { IDPair } from '../../models/suzy/IDPair';
import { IMission } from '../../models/suzy/IMission';
import { DATA_TOKEN, ICBPFormInputs } from '../../models/ui/IInjectionToken';
import { ITableView, ITableViewName } from '../../models/ui/ITableView';
import { IBrowserIds } from '../../models/ui/browser-path';
import { DataExplorerConfig } from '../../models/ui/data-explorer-config.model';
import { IExplorerUI, INavigationStatus } from '../../models/ui/i-explorer-ui';
import { BrowserRouterService } from '../../services/browser-router.service';
import { CrosstabDataService } from '../../services/crosstab-data.service';
import { CustomBannerPointsActionsService } from '../../services/custom-banner-points-actions.service';
import { LibraryNavigationService } from '../../services/library-navigation.service';
import { LongPollingDataService } from '../../services/long-polling-data.service';
import { SegmentService } from '../../services/segment.service';
import { SuzyDataService } from '../../services/suzy-data.service';
import { SuzySDK } from '../../services/suzy-sdk';
import { TableViewService } from '../../services/table-view.service';
import { DataUtility } from '../../utilities/data-utility';
import { SuzyFonts } from '../../utilities/font-loader';
import { CbpConfirmActionDialogComponent } from '../custom-banner-points/cbp-confirm-action-dialog/cbp-confirm-action-dialog.component';
import { GridComponent } from '../grid/grid.component';
import { ManageTablesPortalComponent } from '../manage-tables/manage-tables-portal/manage-tables-portal.component';

@Component({
  selector: 'data-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss'],
})
export class ExplorerComponent implements OnInit, OnChanges, OnDestroy {
  private unsubscribeAll = new Subject<void>();
  private missionChanged = new Subject<void>();
  private tokenRefreshInterval!: ReturnType<typeof setInterval>;
  private pptDialogVisible!: boolean;

  @Input()
  get downloadSurveyAsPPT() {
    return () => this.onDownloadSurvey('ppt');
  }
  @Input()
  get downloadSurveyAsExcel() {
    return () => this.onDownloadSurvey('excel');
  }
  @Input()
  get cancelBuilder() {
    return () => this.onCancelBuilder();
  }

  @Input() config!: DataExplorerConfig;
  @Input() brandId!: string;
  @Input() mission!: IMission;
  @Input() action!: IAction;
  @Input() question!: string;
  @Output() toggleBuilder: EventEmitter<boolean> = new EventEmitter();
  @Output() expandChart: EventEmitter<boolean> = new EventEmitter();
  @Output() manageTableViews: EventEmitter<boolean> = new EventEmitter();
  @Output() openCBP: EventEmitter<boolean> = new EventEmitter();
  @Output() missionRemoved: EventEmitter<boolean> = new EventEmitter(false);
  @Output() exportSurvey: EventEmitter<string> = new EventEmitter();
  @Output() navigateToMissionDetails = new EventEmitter<IMission>();
  @Output() navigateToManageData = new EventEmitter<boolean>(false);

  @ViewChild('snackbar', { static: true })
  public snackbar!: IgxSnackbarComponent;

  public ui: IExplorerUI = {
    logged_in: false,
    building: false,
    brand_id: '',
    loading: false,
    loader: new Set<string>(),
    notice: '',
    warnings: [],
    emptyDataMessage: '',
    emptyDataWarning: '',
    is_global: false,
    additions: [],
    is_addition: false,
    is_percent: true,
    data_type: GridColumnDataType.Number,
    show_chart: false,
    chart_expanded: false,
    defaultTableViews: {
      loaded: false,
      loading: true,
      data: [],
    },
    tableViews: { loaded: false, loading: true, data: [] },
    tableViewNamesDict: { loaded: false, loading: true, data: [] },
    snackbar_msg: '',
    config: undefined,
    custom_banners_points: false,
    manage_tables: false,
    fabrications: [],
  };
  public selectedExportKind!: 'ppt' | 'excel';

  brandMissions: any[] = [];
  missionActions: any[] = [];
  selectedActionId!: string;
  questionLoading = false;
  activeAction = '';
  cbpActive = true;
  idsFromPath: IBrowserIds | null = null;

  @ViewChild('explorerGrid', { static: false }) explorerGridRef!: GridComponent;
  @ViewChild('surveyExporterDialog', {
    read: IgxDialogComponent,
    static: false,
  })
  private surveyExporterDialog!: IgxDialogComponent;

  @ViewChild('missionRemoveConfirm', {
    read: IgxDialogComponent,
    static: false,
  })
  private missionRemoveConfirm!: IgxDialogComponent;

  searchingMissions = new BehaviorSubject<boolean>(false);
  @ViewChildren('.de-routes') myElements!: QueryList<ElementRef>;
  constructor(
    private renderer: Renderer2,
    private translate: TranslateService,
    private suzySDK: SuzySDK,
    private crosstabs: CrosstabDataService,
    private tableViewService: TableViewService,
    private suzyData: SuzyDataService,
    private iconService: IgxIconService,
    private segmentService: SegmentService,
    private navigationStatus: LibraryNavigationService,
    private cbpActions: CustomBannerPointsActionsService,
    private dialog: Dialog,
    private libraryNavigation: LibraryNavigationService,
    private browserNavigationService: BrowserRouterService,
    private longPollingService: LongPollingDataService
  ) {}

  ngOnInit(): void {
    this.libraryNavigation.DENavigationStatus$.pipe(
      tap((navigationStatus: any) => {
        if (navigationStatus) {
          switch (navigationStatus.currentRoute) {
            case ELibraryRoutes.ManageTables:
              this.assignComponentPortal(ManageTablesPortalComponent, {
                uiData: this.ui,
                selectedTabIndex: 0,
              });
              break;
          }
        }
      }),
      takeUntil(this.unsubscribeAll)
    ).subscribe();

    this.iconService.addSvgIconFromText(
      'grid_rank',
      SuzyFonts.grid_rank(),
      'suzy'
    );
    this.iconService.addSvgIconFromText(
      'grid_scale',
      SuzyFonts.grid_scale(),
      'suzy'
    );
    this.iconService.addSvgIconFromText('maxdiff', SuzyFonts.maxdiff(), 'suzy');
    this.iconService.addSvgIconFromText(
      'download',
      SuzyFonts.download(),
      'suzy'
    );
    this.iconService.addSvgIconFromText('edit', SuzyFonts.edit(), 'suzy');
    this.iconService.addSvgIconFromText(
      'file_copy',
      SuzyFonts.file_copy(),
      'suzy'
    );
    this.iconService.addSvgIconFromText(
      'equalizer',
      SuzyFonts.equalizer(),
      'suzy'
    );
    this.iconService.addSvgIconFromText(
      'settings',
      SuzyFonts.settings(),
      'suzy'
    );
    this.iconService.addSvgIconFromText(
      'delete_custom',
      SuzyFonts.delete(),
      'suzy'
    );
    this.iconService.addSvgIconFromText(
      'calculate',
      SuzyFonts.calculate(),
      'suzy'
    );
    this.iconService.addSvgIconFromText('turf', SuzyFonts.turf(), 'suzy');
    this.iconService.addSvgIconFromText(
      'apply-table',
      SuzyFonts.apply_table(),
      'suzy'
    );
    this.iconService.addSvgIconFromText(
      'calculate-filled',
      SuzyFonts.calculate_filled(),
      'suzy'
    );
    this.iconService.addSvgIconFromText(
      'table-info',
      SuzyFonts.table_info(),
      'suzy'
    );
    this.iconService.addSvgIconFromText(
      'settings',
      SuzyFonts.settings(),
      'suzy'
    );
    this.iconService.addSvgIconFromText(
      'insights',
      SuzyFonts.insights(),
      'suzy'
    );

    this.myElements?.forEach((elementRef: ElementRef) => {
      const element = elementRef.nativeElement;
      this.renderer.setProperty(
        element,
        'innerHTML',
        element.console.log('hello')
      );
    });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit(): void {
    this.myElements.forEach((elementRef: ElementRef) => {
      const element = elementRef.nativeElement;
      this.renderer.setProperty(
        element,
        'innerHTML',
        element.console.log('hello')
      );
    });

    this.idsFromPath = this.browserNavigationService.extractUUIDsFromPath();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config']) {
      const tmpConfig = changes['config'].currentValue;
      if (tmpConfig) {
        this.initConfig(tmpConfig);
      }
    }

    if (changes['brandId']) {
      this.ui.brand_id = changes['brandId'].currentValue ?? undefined;
      this.suzySDK.tenant = changes['brandId'].currentValue ?? undefined;
      if (this.ui.config && this.ui.brand_id) {
        this.translate.get('guard').subscribe(() => {
          this.init();
        });
      } else {
        this.ui.brand_id = '';
        this.ui.logged_in = false;
        this.ui.defaultTableViews = {
          loaded: false,
          loading: false,
          data: [],
        };
        this.ui.tableViewNamesDict = {
          loaded: false,
          loading: false,
          data: [],
        };
        this.suzyData.clearPanelsAndSegmentsCache();
      }
      this.missionChanged.next();
    }

    if (
      changes['action'] ||
      changes['mission'] ||
      changes['brandId'] ||
      changes['config']
    ) {
      this.ui = {
        ...this.ui,
      };
    }

    if (changes['mission']) {
      this.ui.mission = changes['mission'].currentValue ?? undefined;
      this.ui.is_global =
        this.ui.mission?.mission_kind === MissionKind.external_cint;
      this.suzyData.clearBrandProfileQuestionsCache();
      this.missionChanged.next();
      this.ui.missionHasCleanedResponses = false;
      if (this.ui.mission) {
        this.suzyData
          .checkIfMissionHasCleanedResponses(this.ui.mission)
          .pipe(
            takeUntil(this.unsubscribeAll),
            takeUntil(this.missionChanged),
            take(1)
          )
          .subscribe((result) => {
            this.ui.missionHasCleanedResponses = result;
          });
        this.getSelectedMissionActions(this.ui.mission);
      }
    }

    if (changes['action']) {
      this.ui.action = changes['action'].currentValue ?? undefined;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    if (this.tokenRefreshInterval) {
      clearInterval(this.tokenRefreshInterval);
    }
    this.navigationStatus.DENavigationStatus.next(null);
    this.navigationStatus.DENavigationStatus.complete();

    /* Clear long polling info */
    this.longPollingService.polledData.next(null);
    this.longPollingService.polledData.complete();

    this.longPollingService.longPollingConfig.next(null);
    this.longPollingService.longPollingConfig.complete();

    /* Closing SignalR connection */
    /*     this.signalRService.stopConnection();
    this.signalRService.signalRHandlers.next(null);
    this.signalRService.signalRHandlers.complete(); */
  }

  assignComponentPortal(
    component: any,
    injectionTokenData?: ICBPFormInputs
  ): void {
    const tokenData = injectionTokenData
      ? this.createInjector(injectionTokenData)
      : null;

    if (component) {
      new ComponentPortal(component, null, tokenData);
    }
  }

  createInjector(data: ICBPFormInputs): Injector {
    return Injector.create({
      providers: [
        {
          provide: DATA_TOKEN,
          useValue: { ...data },
        },
      ],
    });
  }

  public onDownloadSurvey(exportKind: 'ppt' | 'excel'): void {
    if (
      this.pptDialogVisible ||
      this.ui?.loading ||
      !this.ui.mission?.mission_id
    ) {
      return;
    }
    const { mission_kind } = this.ui.mission;
    if (mission_kind === MissionKind.standard) {
      return;
    }

    if (this.surveyExporterDialog) {
      this.selectedExportKind = exportKind;
      setTimeout(() => {
        this.surveyExporterDialog.open();
      });
    }
  }

  public onCancelBuilder(): void {
    if (!this.explorerGridRef) {
      return;
    }

    this.explorerGridRef.toggleBuilder(false);
  }

  private init(): void {
    this.ui.brand_id = this.brandId;
    this.hackAutoLogin();
    // this.loadBrandTableViewNames();
    this.loadPanelsAndSegments();
    this.loadBrandFolders();
    this.loadMissions(this.ui.brand_id);
    this.getOutputCachingPropertyValue(this.brandId);
  }

  private hackAutoLogin(): void {
    let found = localStorage.getItem('suzy_login');
    if (!found) {
      const session = localStorage.getItem('session');
      if (session) {
        const sessionObj = JSON.parse(session);
        if (sessionObj?.apiKey && sessionObj.apiSecret) {
          found = JSON.stringify({
            api_key: sessionObj.apiKey,
            api_secret: sessionObj.apiSecret,
          });
        }
      }
    }
    if (found) {
      const keys = JSON.parse(found);
      this.suzySDK.tenant = this.brandId;
      this.suzySDK.setApiCredentials(keys.api_key, keys.api_secret);
      this.crosstabs
        .getAuthToken(keys.api_key, keys.api_secret)
        .pipe(takeUntil(this.unsubscribeAll), take(1))
        .subscribe((token) => {
          this.crosstabs.authToken = token;
          this.hackKeepTokenAlive(keys.api_key, keys.api_secret);

          // token should be populated before accessing defaultViewsTemplates

          this.loadDefaultTableViews();

          /* Get Long Polling Configuration */
          this.getStartLongPolling();

          setTimeout(() => {
            this.ui.logged_in = true;
          }, 0);
        });
    } else {
      throw new Error('Auto login failed! No api key/secret.');
    }
  }

  private hackKeepTokenAlive(key: string, secret: string): void {
    this.tokenRefreshInterval = setInterval(() => {
      this.crosstabs.generateToken(key, secret);
    }, 8 * 60 * 1000);
  }

  private loadDefaultTableViews(): void {
    this.tableViewService
      .getDefaultTableViews()
      .pipe(
        takeUntil(this.unsubscribeAll),
        repeat({ count: 10, delay: 1000 }),
        filter((items) => items?.length > 0),
        take(1)
      )
      .subscribe({
        next: (items: ITableView[]) => {
          this.ui.defaultTableViews.data = items;
          this.ui.defaultTableViews.loading = false;
          this.ui.defaultTableViews.loaded = true;
        },
        complete: () => {
          if (!this.ui.defaultTableViews.data.length) {
            this.ui.snackbar_msg = this.translate.instant(
              'crosstab.defaultTableViewLoadError'
            );
            this.snackbar.closed.pipe(take(1)).subscribe(() => {
              this.ui.snackbar_msg = '';
            });
            this.snackbar.open();
          }
        },
      });
  }

  private loadBrandTableViewNames(): void {
    this.tableViewService
      .getBrandTableViewNames(this.ui.brand_id)
      .pipe(
        takeUntil(this.unsubscribeAll),
        take(1),
        catchError(() => {
          return of([] as ITableViewName[]);
        })
      )
      .subscribe((items: ITableViewName[]) => {
        this.ui.tableViewNamesDict.data = items;
        this.ui.tableViewNamesDict.loading = false;
        this.ui.tableViewNamesDict.loaded = true;
      });
  }

  private loadPanelsAndSegments(): void {
    if (!this.brandId) {
      return;
    }

    this.suzyData
      .loadPanelsAndSegments(this.brandId)
      .pipe(
        takeUntil(this.unsubscribeAll),
        take(1),
        catchError(() => {
          return of(false);
        })
      )
      .subscribe();
  }

  private loadBrandFolders(): void {
    this.suzyData.clearBrandFoldersCache();
    if (this.ui.brandFoldersEnabled) {
      this.suzyData
        .loadBrandFolders(this.brandId)
        .pipe(
          takeUntil(this.unsubscribeAll),
          take(1),
          catchError(() => {
            return of([] as IDPair[]);
          })
        )
        .subscribe((items: IDPair[]) => {
          this.suzyData.setBrandFoldersCache(items ?? []);
        });
    }
  }

  protected onExportDialogClosed(result?: { success?: boolean }): void {
    if (result?.success) {
      this.snackbar.open(this.translate.instant('crosstab.downloadRequested2'));
      return;
    }
  }

  private initConfig(config: DataExplorerConfig): void {
    this.suzySDK.initialize(config.suzyURL);
    this.crosstabs.initialize(
      config.dataExplorerAPI.baseURL,
      config.dataExplorerAPI.validationURL,
      config.dataExplorerAPI.tokenURL,
      config.features.preparationEnabled,
      config.features.qualityEnabled
    );
    this.ui.advancedCalculationsEnabled =
      config.features.advancedCalculationsEnabled;
    this.ui.qualityEnabled = config.features.qualityEnabled;
    this.ui.statTestingEnabled = config.features.statTestingEnabled;
    this.ui.validationEnabled = config.features.validationEnabled;
    this.ui.publicLinkManageDataEnabled =
      config.features.publicLinkManageDataEnabled;
    this.ui.manageDataUrl = config.features.manageDataUrl ?? '';
    this.ui.brand_name = config.brandName ?? '';
    this.ui.config = config;
    this.ui.trimmedResponsesEnabled = config.features.trimmedResponsesEnabled;
    this.ui.brandFoldersEnabled = config.features.brandFoldersEnabled;
    if (config.segmentToken) {
      this.segmentService.load(config.segmentToken);
    }
  }

  private getOutputCachingPropertyValue(brandId: string): void {
    this.suzyData
      .brandGetPropertyValue(brandId, 'de_output_cache_enabled')
      .pipe(
        tap((value: any) => {
          this.crosstabs.outputCacheEnabled = value === 'true';
        }),
        take(1)
      )
      .subscribe();
  }

  private loadMissions(brandId, searchTerm: string = ''): void {
    this.searchingMissions.next(true);
    this.suzyData
      .searchMissions(searchTerm, brandId)
      .pipe(
        tap((missions) => {
          this.brandMissions = missions;
          this.searchingMissions.next(false);
        }),
        take(1),
        catchError(() => {
          this.searchingMissions.next(false);
          return EMPTY;
        })
      )
      .subscribe();
  }

  getSelectedMissionActions(selectedMission: IMission): void {
    if (selectedMission) {
      this.questionLoading = true;
      this.setMissionToUI(selectedMission);

      this.browserNavigationService.pushNavigationState(
        `/brand/${this.ui.brand_id}/insights/data-explorer/${selectedMission.mission_id}`
      );
      this.suzyData
        .getMissionActions(this.brandId, selectedMission.mission_id)
        .pipe(
          tap((actions: IAction[]) => {
            this.missionActions = actions;
            this.questionLoading = false;
            this.action =
              actions.find(
                (action: IAction) =>
                  action.action_id === this.idsFromPath?.action
              ) ?? actions[0];

            this.actionSelected(this.action);
          }),
          take(1),
          catchError((err) => {
            this.questionLoading = true;
            return err;
          })
        )
        .subscribe();
    } else {
      this.missionActions.length = 0;
    }
  }

  setMissionToUI(mission: IMission | undefined): void {
    this.ui.mission = mission ?? undefined;
    this.ui.is_global =
      this.ui.mission?.mission_kind === MissionKind.external_cint;
    this.suzyData.clearBrandProfileQuestionsCache();
    this.missionChanged.next();
    this.ui.missionHasCleanedResponses = false;
    if (this.ui.mission) {
      this.suzyData
        .checkIfMissionHasCleanedResponses(this.ui.mission)
        .pipe(
          takeUntil(this.unsubscribeAll),
          takeUntil(this.missionChanged),
          take(1)
        )
        .subscribe((result) => {
          this.ui.missionHasCleanedResponses = result;
        });
    } else {
      this.setActionToUI(undefined);
    }
  }

  actionSelected(action: IAction): void {
    if (this.selectedActionId === action?.action_id) {
      return;
    }
    this.activeAction = action?.action_id;
    this.setActionToUI(action);
  }

  setActionToUI(action: any | undefined): void {
    if (action) {
      this.ui = { ...this.ui, action };
      this.selectedActionId = this.ui.action?.action_id ?? '';
      this.browserNavigationService.pushNavigationState(
        `/brand/${this.ui.brand_id}/insights/data-explorer/${this.ui.mission?.mission_id}/action/${this.ui.action?.action_id}`
      );
    } else {
      delete this.ui.action;
      this.selectedActionId = '';
      this.missionActions.length = 0;
      this.ui = structuredClone(this.ui);
    }
  }

  openConfirm(remove: boolean): void {
    if (remove) {
      this.missionRemoveConfirm.open();
    }
  }

  /**
   * Remove mission data from the UI and emit an event to notify core app
   * @emits missionRemoved to notify core
   */
  removeMission(remove: boolean): void {
    if (remove) {
      this.missionRemoveConfirm.close();
      this.setMissionToUI(undefined);
      this.browserNavigationService.pushNavigationState(
        `/brand/${this.ui.brand_id}/insights/data-explorer`
      );
      // this.missionRemoved.emit(true);
    }
  }

  goBack(data: INavigationStatus): void {
    if (data.backTo) this.navigateTo(data.backTo);
  }

  searchMissions(searchTerm: any): void {
    this.loadMissions(this.brandId, searchTerm);
  }

  navigateToDetail(mission: any): void {
    this.navigateToMissionDetails.emit(mission);
  }

  goToCustomBannerPointsUI(): void {
    this.ui.custom_banners_points = true;
    this.navigationStatus.DENavigationStatus.next({
      title: 'crosstab.manageTableViews',
      backTo: ELibraryRoutes.Explorer,
      currentRoute: ELibraryRoutes.CBPTabs,
    });
    this.openCBP.emit(this.ui.custom_banners_points);
  }

  getHeaderTitle(): string {
    return (
      this.navigationStatus.DENavigationStatus.getValue()?.title ??
      'dataExplorer.headerTitle'
    );
  }

  builderToggle(evt: any): void {
    this.toggleBuilder.emit(evt);

    if (evt) {
      this.navigateTo(ELibraryRoutes.Builder);
    }
  }

  handleFabricationChange(fabrication: IFabrication): void {
    const storedFabs = this.cbpActions.builderSubpopulations.getValue();
    const found = storedFabs.find(
      (fab: any) => fab.fabrication_id === fabrication.fabrication_id
    );

    this.cbpActions.createOrEditFabrication(
      this.ui.brand_id,
      EFabricationEndpointType.Subpopulation,
      fabrication,
      !!found,
      this.ui.mission?.mission_id
    );

    if (
      this.navigationStatus.DENavigationStatus.getValue()?.backTo ===
      ELibraryRoutes.Builder
    ) {
      this.ui.custom_banners_points = false;
      this.ui.building = true;
      this.builderToggle(true);
    }
  }

  gotoManageData(evt: boolean): void {
    this.navigateToManageData.emit(evt);
  }

  navigateToSPForm(open: boolean = false): void {
    if (!this.ui.custom_banners_points) {
      this.ui.custom_banners_points = true;

      this.navigationStatus.DENavigationStatus.next({
        title: 'customBannerPoints.subPopulations.tabTitle',
        backTo: ELibraryRoutes.Builder,
        currentRoute: ELibraryRoutes.CBPSubpopulation,
      });
    }
  }

  navigateTo(navigate: ELibraryRoutes): void {
    if (this.cbpActions.formHasChanges.getValue()) {
      this.dialog.open(CbpConfirmActionDialogComponent, {
        data: {
          title: this.translate.instant(
            'customBannerPoints.formText.validations.confirmLeave.title'
          ),
          body: this.translate.instant(
            'customBannerPoints.formText.validations.confirmLeave.body'
          ),
          ctaBtn: this.translate.instant(
            'customBannerPoints.formText.validations.confirmLeave.ctaBtn'
          ),
          cancelBtn: this.translate.instant(
            'customBannerPoints.formText.validations.confirmLeave.cancelBtn'
          ),
          callback: () => this.discardChanges(),
        },
      });
    } else {
      switch (navigate) {
        case ELibraryRoutes.CBPTabs:
          this.navigationStatus.DENavigationStatus.next({
            title: 'customBannerPoints.headerTitle',
            backTo: ELibraryRoutes.Explorer,
            currentRoute: ELibraryRoutes.CBPTabs,
          });
          break;
        case ELibraryRoutes.ManageTables:
          this.navigationStatus.DENavigationStatus.next({
            title: 'customBannerPoints.headerTitle',
            backTo: ELibraryRoutes.Explorer,
            currentRoute: ELibraryRoutes.ManageTables,
          });
          break;
        case ELibraryRoutes.Builder:
          this.ui.custom_banners_points = false;
          this.ui.manage_tables = false;
          this.ui.building = true;
          this.navigationStatus.DENavigationStatus.next({
            title: 'builder.createTableView',
            backTo: ELibraryRoutes.Explorer,
            currentRoute: ELibraryRoutes.Builder,
          });
          break;
        case ELibraryRoutes.Explorer:
          this.navigationStatus.DENavigationStatus.next({
            title: 'dataExplorer.headerTitle',
            backTo: null,
            currentRoute: ELibraryRoutes.Explorer,
          });
          this.ui.building = false;
          this.ui.custom_banners_points = false;
          this.ui.manage_tables = false;
          break;
      }
    }
  }

  discardChanges(): void {
    this.cbpActions.formHasChanges.next(false);
    const navigation = this.navigationStatus.DENavigationStatus.getValue();
    if (navigation?.backTo) this.navigateTo(navigation.backTo);
  }

  openManageTableView(evt: any): void {
    /*   const ids = {
       missionId: this.ui.mission?.mission_id,
       actionId: this.ui.action?.action_id,
     };
     if (ids.missionId && ids.actionId) this.manageTableViews.emit(ids); */
    this.manageTableViews.emit(evt);

    if (evt) {
      this.navigateTo(ELibraryRoutes.ManageTables);
    }
  }

  getStartLongPolling(): void {
    /* Long Polling Service */
    this.longPollingService
      .getLongPollingConfiguration(this.ui.brand_id, DataUtility.generateGUID())
      .pipe(
        tap((response: any) => {
          this.longPollingService.longPollingConfig.next(response);
        }),
        exhaustMap((response: any) =>
          this.longPollingService.startPolling(response.item)
        ),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe();
  }
}
