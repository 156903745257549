import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { INavigationStatus } from '../models/ui/i-explorer-ui';

@Injectable({
  providedIn: 'root',
})
export class LibraryNavigationService {
  DENavigationStatus = new BehaviorSubject<INavigationStatus | null>(null);
  DENavigationStatus$ = this.DENavigationStatus.asObservable();
}
