/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PercentPipe } from '@angular/common';
import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SecurityContext,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActionKind,
  ActionKindVariant,
  ActionStructureMultipleChoiceKind,
  MissionKind,
} from '@asksuzy/typescript-sdk';
import {
  AbsolutePosition,
  ConnectedPositioningStrategy,
  GridColumnDataType,
  HorizontalAlignment,
  IColumnResizeEventArgs,
  ISelectionEventArgs,
  ISimpleComboSelectionChangingEventArgs,
  ISortingExpression,
  ISortingOptions,
  ISortingStrategy,
  IgxDialogComponent,
  IgxGridCell,
  IgxGridComponent,
  IgxOverlayService,
  IgxSnackbarComponent,
  OverlayCancelableEventArgs,
  RowType,
  SortingDirection,
  VerticalAlignment,
} from '@infragistics/igniteui-angular';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  filter,
  finalize,
  forkJoin,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import sanitize from 'sanitize-filename';
import { IBanner } from '../../models/data/request/IBanner';
import { ICrosstabConfig } from '../../models/data/request/ICrosstabConfig';
import { IQualitySetting } from '../../models/data/request/IQualitySetting';
import { ITableViewValidation } from '../../models/data/request/ITableViewValidation';
import { ICrosstabData } from '../../models/data/response/ICrosstabData';
import { ICrosstabDataSet } from '../../models/data/response/ICrosstabDataSet';
import { ICrosstabDimension } from '../../models/data/response/ICrosstabDimension';
import { ICrosstabResult } from '../../models/data/response/IItemResult';
import { ITableView } from '../../models/ui/ITableView';
import { IColumnInfo } from '../../models/ui/i-column-info';
import { ICrosstabConfigUI } from '../../models/ui/i-crosstab-config-ui';
import { IExplorerUI } from '../../models/ui/i-explorer-ui';
import {
  ESegmentTrackActionKind,
  ISegmentTrackActionData,
} from '../../models/ui/i-segment-track-action';
import { IStatTestingCell } from '../../models/ui/i-stat-testing-cell.model';
import { ITableInfo } from '../../models/ui/i-table-info.model';
import { CrosstabConfigService } from '../../services/crosstab-config.service';
import { CrosstabDataService } from '../../services/crosstab-data.service';
import { SegmentService } from '../../services/segment.service';
import { SuzyDataService } from '../../services/suzy-data.service';
import { TableViewService } from '../../services/table-view.service';
import { DataUtility } from '../../utilities/data-utility';
import { StatTestingDataUtility } from '../../utilities/stat-testing-data-utility';
import { BuilderComponent } from '../builder/builder.component';
import { IBuilderContext } from '../builder/i-builder-context';
import { DuplicateTableComponent } from './duplicate-table/duplicate-table.component';
import { GridColumnCustomSort } from './grid-column-custom-sort';

import { Dialog } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import {
  EFabricationEndpointType,
  EFabricationType,
} from '../../enums/cbp-enum';
import { ELibraryRoutes } from '../../enums/de-routes.enum';
import { IFabrication } from '../../models/fabrication/IFabrication';
import { IFabItemDetail } from '../../models/fabrication/IFabricationDetail';
import { CustomBannerPointsActionsService } from '../../services/custom-banner-points-actions.service';
import { CustomBannerPointsService } from '../../services/custom-banner-points.service';
import { LibraryNavigationService } from '../../services/library-navigation.service';
import { TableViewsRequestsService } from '../../services/table-views-requests.service';
import { CbpConfirmActionDialogComponent } from '../custom-banner-points/cbp-confirm-action-dialog/cbp-confirm-action-dialog.component';
import { BrowserRouterService } from '../../services/browser-router.service';
import { LongPollingDataService } from '../../services/long-polling-data.service';
import { SignalRResponse } from '../../models/ui/signalr-response';
import { ESignalREvents } from '../../enums/signalr-events.enum';

@Component({
  selector: 'data-explorer-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnChanges, OnDestroy, OnInit {
  private unsubscribeAll = new Subject<void>();
  private duplicateOverlayClosed = new Subject<void>();
  private fetchDataCancelled = new Subject<void>();

  dropDownOverlaySettings = {
    positionStrategy: new ConnectedPositioningStrategy({
      horizontalDirection: HorizontalAlignment.Left,
      horizontalStartPoint: HorizontalAlignment.Right,
      verticalStartPoint: VerticalAlignment.Bottom,
    }),
  };

  fabricationInit = {
    fabricationType: EFabricationType.Subpopulation,
    translations: {
      title: 'customBannerPoints.subPopulations.form.title',
      subtitle: 'customBannerPoints.subPopulations.form.subtitleBuilder',
      labelName: 'customBannerPoints.subPopulations.form.labelName',
      validations: {
        nameRequired:
          'customBannerPoints.subPopulations.form.validations.nameRequired',
        nameExists:
          'customBannerPoints.subPopulations.form.validations.nameExists',
        cantValidateName:
          'customBannerPoints.subPopulations.form.validations.cantValidateName',
      },
      namePlaceholder: 'customBannerPoints.subPopulations.form.namePlaceholder',
    },
  };

  tableViewIsAsync = false;
  sendingAsyncNotification = false;

  @Input() question? = '';
  @Input() actions: any[] = [];
  @Input() ui!: IExplorerUI;
  @Input() tableViewIdfromPath?: string;

  @Output() builderToggle: EventEmitter<boolean> = new EventEmitter();
  @Output() expandChart: EventEmitter<boolean> = new EventEmitter();
  @Output() manageTableViews: EventEmitter<boolean> = new EventEmitter();
  @Output() readonly openSPForm = new EventEmitter<boolean>(false);
  @Output() readonly saveFabrication = new EventEmitter<IFabrication>();

  @ViewChild('builder', { read: BuilderComponent, static: false })
  private builder!: BuilderComponent;

  @ViewChild('grid', { static: false })
  private grid!: IgxGridComponent;

  @ViewChild('comparisonView', { read: TemplateRef, static: false })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public comparisonView!: TemplateRef<any>;

  @ViewChild('percentView', { read: TemplateRef, static: false })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public percentView!: TemplateRef<any>;

  @ViewChild('confirm_dialog', { read: IgxDialogComponent, static: false })
  private confirmDialog!: IgxDialogComponent;

  @ViewChild('chartContent')
  chartContent;

  @ViewChild('grid_snackbar', { static: true })
  public snackbar!: IgxSnackbarComponent;

  @ViewChild('PPTExport', { static: false })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private PPTExport!: TemplateRef<any>;

  @ViewChild('excelExport', { static: false })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private excelExport!: TemplateRef<any>;

  @ViewChild('tableViewValidatorDialog', {
    read: IgxDialogComponent,
    static: false,
  })
  private tableViewValidatorDialog!: IgxDialogComponent;

  @ViewChild('tableViewForm', { static: false })
  private tableViewForm!: ElementRef;

  @ViewChild('gridContainer', { static: false })
  gridContainer!: ElementRef;

  @ViewChild('tableInformationDialog', {
    read: IgxDialogComponent,
    static: false,
  })
  private tableInformationDialog!: IgxDialogComponent;

  @ViewChild('crosstabWontProcessDialog', {
    read: IgxDialogComponent,
    static: false,
  })
  private crosstabWontProcessDialog!: IgxDialogComponent;

  @ViewChild('crosstabMayProcessDialog', {
    read: IgxDialogComponent,
    static: false,
  })
  private crosstabMayProcessDialog!: IgxDialogComponent;

  @ViewChild('builderSPForm', {
    read: IgxDialogComponent,
    static: false,
  })
  private builderSPForm!: IgxDialogComponent;

  openSPDialog = false;
  actionVariant = ActionKindVariant;

  public config!: ICrosstabConfigUI;
  private defaultConfig!: ICrosstabConfigUI;
  public buildConfig?: ICrosstabConfigUI;
  public buildContext?: IBuilderContext;
  public raw_data?: ICrosstabData;
  private duplicateTableViewOverlayId?: string;
  private readonly emptyColField = 'cempty';
  private readonly infoColId = 'info-col';
  private readonly emptyRowField = 'rempty';
  private readonly infoRowId = 'info-row';
  private readonly largeMissionResponseCountTreshold = 10000;
  public canFlipStackedBarChart!: boolean;
  public exportingPPT!: boolean;
  public exportingExcel!: boolean;
  public validationData?: ITableViewValidation;
  private columnsKeyMap?: Array<{
    kind: 'fromCountToPercent' | 'fromPercentToCount';
    map: Array<{ sourceKey: string; destKey: string }>;
  }>;
  private gridCellWidths: Array<{
    countField: string;
    percentField: string;
    width: string;
  }> = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public grid_data: any = {
    visible: true,
    columns: [],
    rows: [],
    data: [],
    grouping: [],
    cellTemplate: null,
    depth: 1,
    defaultSort: [] as Array<ISortingExpression>,
    appliedSort: [] as Array<ISortingExpression>,
    dataDefaultSort: [],
    isSingleCol: false,
    singleColColumns: [],
  };

  public chartType = 'multiple';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public chartData: Array<any> = [];
  downloadingImage = false;
  downloadRenderDelay = 3000;
  actionKind = ActionKind;

  tableViewFormGroup!: FormGroup;
  customSortingStrategy!: ISortingStrategy;
  groupSortingStrategy!: ISortingStrategy;
  public readonly singleSortOption: ISortingOptions = { mode: 'single' };
  defaultSortingExpressions!: ISortingExpression[];
  tableInfo?: ITableInfo;
  segmentTrackingData?: ISegmentTrackActionData;
  fabricationToEdit!: IFabrication | IFabItemDetail | undefined;
  builderDimensions!: unknown | undefined;

  // Sets up totals rows to be bold by adding totalsRow class
  public totalsRowCondition = (row: RowType) =>
    DataUtility.bannerSummaryDimensions.some((x) => x === row.data.rbd_1__s);
  public removeEmptyRow = (row: RowType) => row.data.rbd_1 === undefined;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public rowClasses = {
    totalsRow: this.totalsRowCondition,
    removeEmptyRow: this.removeEmptyRow,
  };

  constructor(
    private translate: TranslateService,
    private crosstabs: CrosstabDataService,
    private suzy: SuzyDataService,
    private configs: CrosstabConfigService,
    private tableViewService: TableViewService,
    private formBuilder: FormBuilder,
    private percentPipe: PercentPipe,
    private renderer: Renderer2,
    private segmentService: SegmentService,
    private sanitizer: DomSanitizer,
    @Inject(IgxOverlayService) public overlayService: IgxOverlayService,
    private navigationStatus: LibraryNavigationService,
    private cbpActions: CustomBannerPointsActionsService,
    private cbpService: CustomBannerPointsService,
    private dialog: Dialog,
    private tableViewsRequests: TableViewsRequestsService,
    private browserNavigationService: BrowserRouterService,
    private longPollingService: LongPollingDataService
  ) {
    document.addEventListener('click', this.offClickHandler.bind(this), {
      capture: true,
    }),
      (this.defaultConfig = this.tableViewService.getBaseCrosstabConfig());
    this.config = this.tableViewService.getBaseCrosstabConfig();

    this.tableViewFormGroup = this.formBuilder.group({
      TableViewName: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(200)]),
      ],
      TableViewId: [''],
    });
  }

  offClickHandler(e) {
    if (
      e.srcElement &&
      e.srcElement.href &&
      e.srcElement.href.startsWith('blob:')
    ) {
      e.srcElement.target = '_blank';
    }
  }

  ngOnInit(): void {
    this.customSortingStrategy = new GridColumnCustomSort();
    this.groupSortingStrategy = new GridColumnCustomSort(true);

    this.longPollingService.polledData$
      .pipe(
        tap((response: any) => {
          // Update requires_processing status on polled data change
          if (response && response.items.length > 0) {
            response.items.forEach((element) => {
              if (element.name === ESignalREvents.TableViewCompleted) {
                this.ui.tableViews.data.map((item: ITableView) => {
                  if (item.table_view_id === element.payload.table_view_id) {
                    item.requires_processing =
                      element.payload.requires_processing;
                    item.updated_utc = element.payload.updated_utc;
                  }
                  return item;
                });
              }
              if (element.payload.table_view_id === this.ui.activeTableViewId) {
                this.applyConfiguration();
              }
            });
          }
        }),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.duplicateOverlayClosed.complete();
    this.fetchDataCancelled.next();
    this.fetchDataCancelled.complete();
    if (this.duplicateTableViewOverlayId) {
      this.overlayService.detach(this.duplicateTableViewOverlayId);
      delete this.duplicateTableViewOverlayId;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['ui']) {
      return;
    }

    const uiChange = changes['ui'];
    this.tableViewIsAsync = false;
    this.config.crosstab.brand_id = this.ui.brand_id;

    if (uiChange.currentValue && uiChange.currentValue.action) {
      this.suzy.processMissionText(uiChange.currentValue.action);
    }

    if (
      !uiChange.currentValue?.mission &&
      (this.grid_data?.data?.length ||
        this.ui.loading ||
        this.ui.canRetryDataFetch)
    ) {
      this.ui.tableViews.data = [];
      this.ui.activeTableView = undefined;
      this.ui.activeTableViewId = undefined;
      this.config = this.tableViewService.getBaseCrosstabConfig();
      this.defaultConfig = this.tableViewService.getBaseCrosstabConfig();
      this.resetData();
      this.applyLoadingMessage();
    } else if (this.ui.mission && uiChange.currentValue?.action) {
      this.prepareGridForSelectedAction();
    } else if (this.grid_data?.data?.length) {
      this.ui.activeTableView = undefined;
      this.ui.activeTableViewId = undefined;
      this.config = this.tableViewService.getBaseCrosstabConfig();
      this.defaultConfig = this.tableViewService.getBaseCrosstabConfig();
      this.resetData();
    }
  }

  comparisonGenerateClass(cell: IgxGridCell): string | undefined {
    if (cell.column.pinned) {
      return undefined;
    }

    const isStatLetterCell =
      cell.value &&
      typeof cell.value === 'object' &&
      StatTestingDataUtility.isStatLetterCell(cell.value);
    if (!isStatLetterCell) {
      return undefined;
    }

    const statCell: IStatTestingCell = cell.value;
    if (statCell.kind !== 'ref' || !statCell.statLetter) {
      return undefined;
    }
    const classes: Array<string> = ['comparison'];
    classes.push(
      `comp_${statCell.statLetterGroup}_${statCell.statLetter.toLowerCase()}`
    );
    return classes.join(' ');
  }

  comparisonExtractValue(cell: IgxGridCell): string {
    const { value } = cell;

    const isStatLetterCell =
      value &&
      typeof value === 'object' &&
      StatTestingDataUtility.isStatLetterCell(value);

    if (isStatLetterCell) {
      const statCell: IStatTestingCell = value;
      if (statCell.kind === 'ref') {
        return statCell.statLetter;
      } else {
        const dataType = cell.row.data?.rbd_data_type ?? 'percentage';
        const item =
          this.ui.is_percent && dataType === 'percentage'
            ? this.percentPipe.transform(statCell.value)
            : statCell.value;
        return `${item}${
          statCell.statLetter ?? ''
            ? '<sup>&nbsp;' + statCell.statLetter + '</sup>'
            : ''
        }`;
      }
    } else {
      if (this.ui.is_percent) {
        if (
          cell.column.field.startsWith('c') &&
          !(
            cell.column.dataType === 'number' ||
            cell.row.data?.rbd_data_type === 'number'
          )
        ) {
          return this.percentPipe.transform(value) ?? '';
        }
      }

      return value;
    }
  }

  comparisonHover(cell: IgxGridCell, isLeave?: boolean) {
    if (cell.column.pinned) {
      return;
    }
    const isStatLetterCell =
      cell.value &&
      typeof cell.value === 'object' &&
      StatTestingDataUtility.isStatLetterCell(cell.value);

    if (!isStatLetterCell) {
      return;
    }

    const statCell: IStatTestingCell = cell.value;
    if (statCell.kind === 'ref') {
      return;
    }

    statCell.statLetters?.forEach((statLetter) => {
      const refToFind = `.comp_${
        statCell.statLetterGroup
      }_${statLetter.toLowerCase()}`;
      const refCell = this.gridContainer?.nativeElement
        ? this.gridContainer.nativeElement.querySelector(refToFind)
        : undefined;
      if (refCell) {
        if (isLeave) {
          this.renderer.removeClass(refCell, 'active');
        } else {
          this.renderer.addClass(refCell, 'active');
        }
      }
    });
  }

  addCrosstabLoader(name: string) {
    this.ui.loader.add(name);
    this.ui.loading = this.ui.loader.size > 0;
    this.applyLoadingMessage();
  }

  removeCrosstabLoader(name: string) {
    this.ui.loader.delete(name);
    this.ui.loading = this.ui.loader.size > 0;
    this.applyLoadingMessage();
  }

  applyLoadingMessage() {
    this.ui.emptyDataWarning = '';
    if (this.ui.canRetryDataFetch) {
      this.ui.emptyDataMessage = this.translate.instant(
        'crosstab.tableViewLoadCanceled'
      );
      return;
    }

    if (this.ui.tableViews.loading) {
      this.ui.emptyDataMessage = this.translate.instant('crosstab.loadingData');
      return;
    }

    if (!this.ui.action?.action_id) {
      this.ui.emptyDataMessage = this.translate.instant(
        'crosstab.selectSurveyToStart'
      );
      return;
    }

    if (this.ui.loader.has('apply')) {
      this.ui.emptyDataMessage = this.translate.instant(
        'crosstab.calculatingRequest'
      );
      return;
    }

    if (this.ui.tableViews.loaded && !this.ui.activeTableViewId) {
      this.ui.emptyDataMessage = this.translate.instant(
        'crosstab.selectOrCreateTableViewToStart'
      );
      return;
    }

    if (this.grid_data?.data?.length === 0) {
      this.ui.emptyDataMessage = this.translate.instant(
        'crosstab.selectSurveyToStart'
      );
    }
    return;
  }

  toggleBuilder(save: boolean = false): void {
    if (!this.ui.building) {
      this.ui.building = true;
      this.tableViewIsAsync = false;

      this.navigationStatus.DENavigationStatus.next({
        title: 'builder.createTableView',
        backTo: ELibraryRoutes.Explorer,
        currentRoute: ELibraryRoutes.Builder,
      });

      this.buildContext = {
        brand_id: this.ui.brand_id,
        global: this.ui.is_global,
        action: this.ui.action,
        mission: this.ui.mission,
        qualityEnabled: this.ui.qualityEnabled ?? false,
        statTestingEnabled: this.ui.statTestingEnabled ?? false,
        advancedCalculationsEnabled:
          this.ui.advancedCalculationsEnabled ?? false,
        trimmedResponsesEnabled: this.ui.trimmedResponsesEnabled ?? false,
        publicLinkManageDataEnabled:
          this.ui.publicLinkManageDataEnabled ?? false,
        manageDataUrl: this.ui.manageDataUrl ?? '',
        missionHasCleanedResponses: this.ui.missionHasCleanedResponses,
        brandFoldersEnabled: this.ui.brandFoldersEnabled ?? false,
      };

      if (
        this.ui?.action?.action_kind === ActionKind.multiplechoice ||
        this.ui?.action?.action_kind === ActionKind.turf
      ) {
        this.chartType = 'multiple';
      } else if (
        this.ui?.action?.action_kind === ActionKind.gridrankscale ||
        this.ui?.action?.action_kind === ActionKind.gridcustom ||
        this.ui?.action?.action_kind === ActionKind.grid
      ) {
        this.chartType = 'stacked';
      }

      if (!save && this.ui.activeTableView) {
        /* Open Table View */
        this.buildConfig = JSON.parse(JSON.stringify(this.config));
      } else {
        /* Create Table View */
        this.buildConfig = JSON.parse(JSON.stringify(this.defaultConfig));
        if (this.buildConfig) {
          this.tableViewService.formatDimensionLabels(
            this.buildConfig.crosstab,
            this.ui.action
          );
        }
        this.ui.activeTableViewId = '';
      }

      if (!save && this.ui.validationEnabled) {
        this.validationData = {
          config: JSON.parse(JSON.stringify(this.buildConfig?.crosstab)),
          metadata: {
            user_id: '',
            table_name: this.ui.activeTableView?.display_name ?? '',
            is_valid: false,
            notes: '',
          },
        };
        this.ui.canValidateTableView = true;
      } else {
        delete this.ui.canValidateTableView;
      }
      this.createTableViewForm(save);

      this.ui.building = true;
      this.builderToggle.emit(this.ui.building);
      this.scrollToTop();

      return;
    }
    if (!save) {
      delete this.ui.canValidateTableView;
      this.ui.building = false;

      this.buildConfig = undefined;
      this.cbpActions.builderSubpopulations.next([]);
      this.ui.activeTableViewId = this.ui.activeTableView?.table_view_id ?? '';
      this.builder.dissever();
      this.builderToggle.emit(this.ui.building);
      this.scrollToTop();
      return;
    }

    if (!this.columnAndRowBannersExist()) {
      return;
    }

    if (this.isUnevenBanners()) {
      return;
    }

    if (this.isSameDimExistsInRowAndCol()) {
      return;
    }

    if (this.tableViewFormGroup.invalid) {
      this.tableViewFormGroup.controls['TableViewName'].markAsDirty();
      this.tableViewFormGroup.controls[
        'TableViewName'
      ].updateValueAndValidity();
      this.scrollToTop();
      return;
    }

    delete this.ui.canValidateTableView;
    this.builder.dissever();
    this.saveAndApplyTableView();
    return;
  }

  isUnevenBanners(): boolean {
    if (this.builder.dragdrop?.isUnevenRowBanners() || false) {
      const endMessage = this.translate.instant('builder.previewRow');
      const dialogData = {
        title: this.translate.instant('builder.tableCannotBeSaved'),
        left: '',
        right: this.translate.instant('builder.confirm_right'),

        message: this.translate.instant('builder.pleaseChooseNestedOrNon', {
          param: endMessage,
        }),
        callback: () => {
          this.ui.confirm_dialog = undefined;
        },
      };

      this.ui.confirm_dialog = dialogData;
      this.confirmDialog.open();
      return true;
    }
    return false;
  }

  isSameDimDialog() {
    const dialogData = {
      title: this.translate.instant('builder.tableCannotBeSaved'),
      left: '',
      right: this.translate.instant('builder.confirm_right'),
      message: this.translate.instant('builder.sameDimInRowAndCol'),
      callback: () => {
        this.ui.confirm_dialog = undefined;
      },
    };

    this.ui.confirm_dialog = dialogData;
    this.confirmDialog.open();
  }

  isSameDimExistsInRowAndCol(): boolean {
    if (!this.builder.dragdrop?.isSameDimExistsInRowAndCol()) {
      return false;
    }
    this.isSameDimDialog();
    // if (
    //   this.ui.action?.action_kind_variant === 25 &&
    //   this.ui.action?.multiple_choice.max_choices ===
    //     this.ui.action?.multiple_choice.min_choices
    // ) {
    //   if (this.builder.dragdrop?.isSameDimExistsInRowAndCol()) {
    //     this.isSameDimDialog();
    //     return true;
    //   }
    // }
    // if (
    //   this.ui.action?.action_kind_variant === this.actionVariant.openended ||
    //   this.ui.action?.action_kind_variant ===
    //     this.actionVariant.gridrankscale ||
    //   this.ui.action?.action_kind_variant === this.actionVariant.gridcustom ||
    //   this.ui.action?.action_kind_variant === this.actionVariant.grid_scale ||
    //   this.ui.action?.action_kind_variant === this.actionVariant.grid_rank ||
    //   this.ui.action?.action_kind_variant === this.actionVariant.grid_open ||
    //   this.ui.action?.action_kind_variant === this.actionVariant.auto_assign ||
    //   this.ui.action?.action_kind_variant ===
    //     this.actionVariant.openended_video ||
    //   this.ui.action?.action_kind_variant === this.actionVariant.maxdiff
    // ) {
    //   if (this.builder.dragdrop?.isSameDimExistsInRowAndCol()) {
    //     this.isSameDimDialog();
    //     return true;
    //   }
    // }
    return true;
  }

  columnAndRowBannersExist(): boolean {
    const hasRowBanners = this.builder?.dragdrop?.hasRowBanners();
    const hasColumnBanners =
      this.builder?.dragdrop?.hasColumnBanners() ||
      this.builder?.configHasTotalColumn();
    const warnings: string[] = [];

    if (!hasRowBanners) {
      warnings.push('builder.tableHasMissingRowContent');
    }

    if (!hasColumnBanners) {
      warnings.push('builder.tableHasMissingColumnContent');
    }

    if (!warnings.length) {
      return true;
    }

    this.translate
      .get(warnings)
      .subscribe((items: { [key: string]: string }) => {
        const messages = Object.keys(items).map((key) => items[key]);
        messages.push('');

        const dialogData = {
          title: this.translate.instant('builder.tableCannotBeSaved'),
          left: '',
          right: this.translate.instant('builder.confirm_right'),
          message: messages.join('. '),
          callback: () => {
            this.ui.confirm_dialog = undefined;
          },
        };

        this.ui.confirm_dialog = dialogData;
        this.confirmDialog.open();
      });
    return false;
  }

  selectAddition(addition: string): void {
    if (addition !== 'standard' && !this.raw_data?.additions[addition]) {
      addition = 'standard';
    }

    if (addition === 'standard') {
      this.renderDataAsCounts(this.raw_data!);
    } else if (addition === 'respondent_percentage_column') {
      this.renderDataAsPercentage(this.raw_data!);
    }
  }

  applyConfiguration(actionIdsToRefresh?: string[]): void {
    this.resetData();
    this.ui.canCancelDataFetch = true;
    this.ui.canRetryDataFetch = false;
    this.ui.notice = 'loading..';
    this.addCrosstabLoader('apply');
    const responseCount = this.suzy.getResponseCountForMission(this.ui.mission);
    if (responseCount > this.largeMissionResponseCountTreshold) {
      this.ui.emptyDataWarning = 'crosstab.largeMissionWarning';
    } else {
      this.ui.emptyDataWarning = '';
    }
    const isPercentDefaultAction =
      this.ui.mission && this.ui.action
        ? this.configs.isPercentDefaultAction(this.ui.action)
        : false;

    if (this.gridContainer) {
      const gridHeader = this.gridContainer.nativeElement.querySelector(
        '.igx-grid-thead--compact'
      );
      if (gridHeader) {
        this.renderer.removeStyle(gridHeader, 'min-height');
      }
    }

    const getActionText$ = this.suzy
      .processMissionText(this.ui.action)
      .pipe(take(1));
    const getMissionText$ = this.suzy
      .processMissionText(this.ui.mission)
      .pipe(take(1));
    forkJoin([getActionText$, getMissionText$])
      .pipe(take(1))
      .subscribe(([actionText, missionText]) => {
        this.segmentTrackingData = <ISegmentTrackActionData>{
          brand_id: this.ui.brand_id,
          brand_name: this.ui.brand_name ?? '',
          primary_action_id: this.ui.action?.action_id ?? '',
          primary_action_kind: this.ui.action
            ? ActionKind[this.ui.action?.action_kind]
            : '',
          primary_action_text: this.sanitizer.sanitize(
            SecurityContext.HTML,
            actionText
          ),
          primary_mission_id: this.ui.mission ? this.ui.mission.mission_id : '',
          primary_mission_kind: this.ui.mission
            ? MissionKind[this.ui.mission.mission_kind]
            : '',
          primary_mission_name: this.sanitizer.sanitize(
            SecurityContext.HTML,
            missionText
          ),
          tableview_id: this.ui.activeTableView
            ? this.ui.activeTableView.table_view_id
            : '',
          tableview_name: this.ui.activeTableView
            ? this.ui.activeTableView.display_name
            : '',
        };

        /* Config for tableview caching */
        this.config.crosstab.ui_table_config = {
          // id: this.ui.activeTableView!.config!.ui_table_config!.id!,
          id: this.ui.activeTableView!.table_view_id,
          name: this.ui.activeTableView!.display_name,
        };
      });

    const fetchData: ICrosstabConfig = JSON.parse(
      JSON.stringify(this.config.crosstab)
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchData.columns.forEach((col: any) => {
      delete col.show_sub_totals;
    });

    let qualitySettings!: IQualitySetting;

    if (
      this.ui.config?.features.qualityEnabled &&
      this.ui.mission!.estimated_response_count
    ) {
      qualitySettings = {
        supported: true,
        known_record_count: this.ui.mission!.estimated_response_count,
        preferred_queue: '',
      };
    }

    const allowStale = false;

    this.crosstabs
      .fetchData(
        fetchData,
        this.ui.activeTableView!.table_view_id ?? this.ui.activeTableView!.id,
        allowStale,
        actionIdsToRefresh,
        {
          ...qualitySettings,
        }
      )
      .pipe(
        tap((response: any) => {
          if (response.is_asynchronous) {
            this.fetchDataCancelled.next();
            this.removeCrosstabLoader('apply');
            this.clearFailedTableViewPref();
            this.tableViewIsAsync = true;

            if (this.segmentTrackingData) {
              this.segmentService.trackAction(
                ESegmentTrackActionKind.QueueRedLane,
                this.segmentTrackingData
              );
            }
          }
        }),
        takeUntil(this.fetchDataCancelled),
        take(1),
        finalize(() => {
          this.ui.canCancelDataFetch = false;
        }),
        catchError(() => {
          this.crosstabWontProcessDialog.open();
          this.removeCrosstabLoader('apply');
          this.clearFailedTableViewPref();
          return EMPTY;
        })
      )
      .subscribe({
        next: (data: any) => {
          if (!data || data.item) {
            return;
          }
          this.tableViewIsAsync = false;

          this.removeCrosstabLoader('apply');
          this.adjustColsData(data);
          this.adjustRowsData(data);
          this.raw_data = data;
          if (data.primary && data.additions['respondent_percentage_column']) {
            this.columnsKeyMap = [
              {
                kind: 'fromCountToPercent',
                map: this.getKeysMap(
                  data.primary,
                  data.additions['respondent_percentage_column']
                ),
              },
              {
                kind: 'fromPercentToCount',
                map: this.getKeysMap(
                  data.additions['respondent_percentage_column'],
                  data.primary
                ),
              },
            ];
          } else {
            this.columnsKeyMap = undefined;
          }
          this.ui.notice = undefined;

          if (isPercentDefaultAction) {
            this.renderDataAsPercentage(data);
          } else {
            this.renderDataAsCounts(data);
          }

          this.checkIsSingleChart();
          this.saveTableViewPref();
        },
        error: (err) => {
          let error = err.toString();
          let shouldClearFailedTableViewPref = true;
          this.removeCrosstabLoader('apply');
          if (error === 'No Data' || error === '204') {
            error = this.translate.instant('crosstab.noResponses');
            this.saveTableViewPref();
            shouldClearFailedTableViewPref = false;
          } else if (error === '501') {
            error = this.translate.instant('crosstab.timeoutError');
          } else if (error === '500' || error === 'ServerError') {
            error = this.translate.instant('crosstab.serverError');
          }
          this.ui.notice = error;
          this.ui.emptyDataMessage = error;
          if (shouldClearFailedTableViewPref) {
            this.clearFailedTableViewPref();
          }
        },
      });

    this.ui.canDuplicateTableView =
      this.ui.mission && this.ui.activeTableView
        ? this.tableViewService.canDuplicateTableView(
            this.ui.mission,
            this.ui.activeTableView
          )
        : false;

    this.canFlipStackedBarChart =
      [
        ActionKind.gridcustom,
        ActionKind.gridrankscale,
        ActionKind.grid,
      ].findIndex((x) => this.ui.action && x === this.ui.action.action_kind) >=
        0 &&
      this.config.crosstab.columns.findIndex((banners) => {
        return banners.dimensions.some(
          (dim) =>
            dim.field?.field === 'action_setting_id_root' &&
            dim.field?.source === 'answer'
        );
      }) >= 0;
  }

  tryTableView(confirm: boolean): void {
    if (confirm) this.applyConfiguration();
  }

  segmentTrackEvent(track: boolean): void {
    if (track && this.segmentTrackingData) {
      this.segmentService.trackAction(
        ESegmentTrackActionKind.QueueYellowLane,
        this.segmentTrackingData
      );
    }
  }

  abortTrying(dontTry: boolean): void {
    if (dontTry) {
      this.removeCrosstabLoader('apply');
      this.clearFailedTableViewPref();
      this.fetchDataCancelled.next();
    }
  }

  renderDataAsCounts(data: ICrosstabData): void {
    let columnsMap = this.columnsKeyMap?.find(
      (x) => x.kind === 'fromPercentToCount'
    )?.map;
    if (!columnsMap) {
      columnsMap = this.getKeysMap(
        data.additions['respondent_percentage_column'],
        data.primary
      );
    }

    this.ui.addition = '';
    this.ui.is_addition = false;
    this.ui.is_percent = false;
    this.ui.data_type = GridColumnDataType.Number;
    this.renderData(this.config.crosstab, data, {}, columnsMap);
  }

  renderDataAsPercentage(data: ICrosstabData): void {
    if (!data.additions['respondent_percentage_column']) {
      this.renderDataAsCounts(data);
      return;
    }

    let columnsMap = this.columnsKeyMap?.find(
      (x) => x.kind === 'fromCountToPercent'
    )?.map;
    if (!columnsMap) {
      columnsMap = this.getKeysMap(
        data.additions['respondent_percentage_column'],
        data.primary
      );
    }

    let renderDataOptions = {};
    if (
      data.additions['stat_test_column'] &&
      this.config.crosstab.columns?.length
    ) {
      const shapedData: ICrosstabData = {
        names: data.names,
        primary: data.additions['stat_test_column'],
        additions: {},
      };
      this.renderData(this.config.crosstab, shapedData, {}, []);
      renderDataOptions = {
        cellTemplate: this.comparisonView,
        comparison: {
          rows: JSON.parse(JSON.stringify(this.grid_data.rows)),
          columns: JSON.parse(JSON.stringify(this.grid_data.columns)),
          data: JSON.parse(JSON.stringify(this.grid_data.data)),
          rawData: data.additions['stat_test_column'],
        },
      };

      this.resetData();
    } else {
      renderDataOptions = { cellTemplate: this.percentView };
    }

    this.ui.addition = '';
    this.ui.is_addition = false;
    this.ui.is_percent = true;
    this.ui.data_type = GridColumnDataType.String;
    const shapedData: ICrosstabData = {
      names: data.names,
      primary: data.additions['respondent_percentage_column'],
      additions: {},
    };
    this.renderData(
      this.config.crosstab,
      shapedData,
      renderDataOptions,
      columnsMap
    );
  }

  renderData(
    config: ICrosstabConfig,
    data: ICrosstabData,
    options: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cellTemplate?: any;
      comparison?: {
        rows: Array<Array<ICrosstabDimension>>;
        columns: Array<IColumnInfo>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: Array<any>;
        rawData: ICrosstabDataSet;
      };
    },
    columnsMap: { sourceKey: string; destKey: string }[]
  ): void {
    const groupExpresssions: Array<ISortingExpression> = [];
    let rowDepth = 0;
    for (const row_banner of config.rows) {
      if (row_banner.dimensions.length > rowDepth) {
        rowDepth = row_banner.dimensions.length;
      }
    }

    if (
      config.rows.length > 1 ||
      (config.rows.length === 1 && config.rows[0].dimensions?.length > 1)
    ) {
      // more than one banner, allow collapse per banner
      groupExpresssions.push({
        dir: SortingDirection.Asc,
        fieldName: 'rbd_' + (0).toString(),
        ignoreCase: false,
        strategy: this.groupSortingStrategy,
      });
    }

    for (let b = 0; b < rowDepth - 1; b++) {
      // skip last dimension: shouldn't be grouped by it
      groupExpresssions.push({
        dir: SortingDirection.Asc,
        fieldName: 'rbd_' + (b + 1).toString(),
        ignoreCase: false,
        strategy: this.groupSortingStrategy,
      });
    }

    let columnDepth = 0;
    for (const column of data.primary.columns) {
      if (column && column.length > columnDepth) {
        columnDepth = column.length;
      }
    }

    const renderRows: ICrosstabDimension[][] = JSON.parse(
      JSON.stringify(data.primary.rows)
    );

    const renderColumns = this.prepareNestedColumns(
      this.config,
      columnDepth,
      rowDepth,
      data
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderData: Array<any> = JSON.parse(
      JSON.stringify(data.primary.data)
    );

    if (
      options.comparison &&
      options.comparison.rawData &&
      options.comparison.columns
    ) {
      const { rawData, columns } = options.comparison;
      const colKeysMap = this.getKeysMap(rawData, data.primary);
      const rowKeysMap = this.getKeysMap(rawData, data.primary, true);
      const rowBanners = [] as string[];
      options.comparison.rows.forEach((rows) => {
        const bannerId = rows[0].bannerId;
        if (bannerId && !rowBanners.some((x) => x === bannerId)) {
          rowBanners.push(bannerId);
        }
      });

      const statLetterRowsMap = StatTestingDataUtility.getStatLetterRowsMap(
        rowBanners,
        rawData
      );

      if (!statLetterRowsMap.length) {
        return;
      }

      const mergeDataSource = StatTestingDataUtility.getStatTestingMergeData(
        rawData,
        columns,
        colKeysMap,
        rowKeysMap,
        statLetterRowsMap
      );

      const primaryRowsKeys = [] as { ix: number; key: string }[];
      data.primary.rows.forEach((rows, rowsIx) => {
        primaryRowsKeys.push({
          ix: rowsIx,
          key: rows.map((row) => row.k).join(','),
        });
      });

      mergeDataSource.forEach((source) => {
        const rowIx =
          primaryRowsKeys.find((x) => x.key === source.rowKey)?.ix ??
          source.rowIx;
        if (rowIx < 0 || renderData.length < rowIx) {
          return;
        }
        const datum = renderData[rowIx];
        if (!datum || datum[source.columnKey] === undefined) {
          return;
        }
        if (source.kind === 'ref') {
          datum[source.columnKey] = {
            statLetter: source.statLetter,
            statLetterGroup: source.statLetterGroup,
            kind: 'ref',
          } as IStatTestingCell;
          return;
        }

        const tmpValue = datum[source.columnKey];
        datum[source.columnKey] = {
          value: tmpValue,
          statLetter: source.statLetter,
          statLetters: source.statLetters,
          statLetterGroup: source.statLetterGroup,
          kind: 'value',
        } as IStatTestingCell;
      });

      //Note: Empty stat letters row cell fields which don't have a stat letter
      //Should not be needed when there is a BE fix
      renderRows.forEach((rows, rowsIx) => {
        if (rows[0].s === 'stat_test_column') {
          const rowData = renderData[rowsIx];
          Object.keys(rowData)
            .filter((x) => x.startsWith('c'))
            .forEach((datumKey) => {
              const datum = rowData[datumKey];
              const isStatLetterValue =
                typeof datum === 'object' &&
                StatTestingDataUtility.isStatLetterCell(datum);
              if (!isStatLetterValue) {
                rowData[datumKey] = {
                  kind: 'ref',
                  statLetter: '',
                  statLetterGroup: '',
                } as IStatTestingCell;
              }
            });
        }
      });
      //-------
    }

    let defaultSort = this.getDefaultSortExpression(renderColumns);
    if (this.grid_data.appliedSort.length) {
      if (this.grid_data.appliedSort[0].dir === SortingDirection.None) {
        defaultSort = [];
      } else {
        const { fieldName } = this.grid_data.appliedSort[0];
        const sourceFieldNames: string[] = fieldName.split(',');
        const destFieldNames: string[] = [];
        sourceFieldNames.forEach((fieldName) => {
          const mappedFieldName = columnsMap.find(
            (x) => x.sourceKey === fieldName
          );
          if (mappedFieldName?.destKey) {
            destFieldNames.push(mappedFieldName.destKey);
          }
        });

        if (sourceFieldNames.length === destFieldNames.length) {
          this.grid_data.appliedSort[0].fieldName = destFieldNames.join(',');
          defaultSort = [this.grid_data.appliedSort[0]];
        } else {
          defaultSort = [];
        }
      }
    }

    const singleColColumns = new Array<IColumnInfo>();
    const isSingleCol =
      columnDepth === 1 &&
      !renderColumns.some((cols) => (cols.header ?? '').trim() !== '');
    if (isSingleCol) {
      renderColumns.forEach((cols) => {
        if (cols.columns && (cols.columns?.length ?? 0) > 0) {
          singleColColumns.push(cols.columns[0]);
        }
      });
    }

    this.grid_data = {
      visible: true,
      columns: renderColumns,
      rows: renderRows,
      data: renderData,
      dataDefaultSort: renderData,
      grouping: groupExpresssions,
      depth: columnDepth,
      cellTemplate: options?.cellTemplate,
      defaultSort,
      appliedSort: defaultSort,
      singleColColumns,
      isSingleCol,
    };
    // requestAnimationFrame(this.autoSizeColumn.bind(this)); //TODO:COULD: Autosize After load?
  }

  resetData(): void {
    if (this.ui.show_chart) {
      this.ui.show_chart = false;
      this.ui.chart_expanded = false;
    }

    this.grid_data = {
      visible: true,
      columns: [],
      rows: [],
      data: [],
      grouping: [],
      depth: 1,
      cellTemplate: null,
      defaultSort: [],
      appliedSort: [],
      dataDefaultSort: [],
      singleColColumns: [],
      isSingleCol: false,
    };
    this.gridCellWidths = [];
    this.columnsKeyMap = undefined;
    this.segmentTrackingData = undefined;
    this.fetchDataCancelled.next();
    if (this.ui.canCancelDataFetch) {
      this.fetchDataCancelled.next();
    }
    this.ui.canCancelDataFetch = false;
    this.ui.canRetryDataFetch = false;
  }

  prepareNestedColumns(
    config: ICrosstabConfigUI,
    columnDepth: number,
    rowDepth: number,
    data: ICrosstabData
  ): Array<IColumnInfo> {
    const result: Array<IColumnInfo> = [];

    const crosstabConfig: ICrosstabConfig = JSON.parse(
      JSON.stringify(this.config.crosstab)
    ); // make a copy for safety

    // Row Headers
    const columnInfo: IColumnInfo = {
      header: ' ', // space so it doesn't try to be smart
      title: '',
      pinned: true,
      field: 'rbd_' + rowDepth.toString(),
      sortable: true,
      resizable: true,
      filterable: false,
      columns: [],
    };

    let columnInfoParent = columnInfo.columns;
    for (let i = 1; i < columnDepth; i++) {
      columnInfoParent!.push({
        header: ' ', // space so it doesn't try to be smart
        title: '',
        pinned: true,
        field: 'rbd_' + rowDepth.toString(),
        sortable: true,
        resizable: true,
        filterable: false,
        columns: [],
      });
      columnInfoParent = columnInfoParent![0].columns;
    }

    const outerColumnInfo: IColumnInfo = {
      header: ' ', // space so it doesn't try to be smart
      title: '',
      pinned: true,
      field: 'rbd_' + rowDepth.toString(),
      sortable: true,
      resizable: true,
      filterable: false,
      columns: [columnInfo],
    };

    result.push(outerColumnInfo);

    const rowBannerIds = data.primary.rows.map((rows) => rows[0].bannerId);
    const configBannerIdsMap = rowBannerIds.filter(
      (value, index, array) => array.indexOf(value) === index
    );
    crosstabConfig.rows.forEach((row, rowIx) => {
      row.identifier = configBannerIdsMap[rowIx];
    });

    // Data
    for (let i = 0; i < data.primary.data.length; i++) {
      const element = data.primary.data[i];

      for (let x = 0; x < rowDepth - 1; x++) {
        // skip last dimension: shouldn't be grouped by it
        element['rbd_' + (x + 1).toString()] = '';
      }
      for (let b = 0; b < crosstabConfig.rows.length; b++) {
        const rowBanner = crosstabConfig.rows[b];
        if (rowBanner.identifier === data.primary.rows[i][0].bannerId) {
          element['rbd_' + (0).toString()] =
            data.primary.rows[i][0].bannerLabel ?? '';
        }

        for (let d = 0; d < rowBanner.dimensions.length; d++) {
          if (data.primary.rows[i].length > d) {
            element['rbd_' + (d + 1).toString()] = data.primary.rows[i][d].name;
            element['rbd_' + (d + 1).toString() + '__id'] =
              data.primary.rows[i][d].id;
            element['rbd_' + (d + 1).toString() + '__s'] =
              data.primary.rows[i][d].s ?? '';
            element['b_' + (b + 1).toString() + '__d_' + (d + 1).toString()] =
              data.primary.rows[i][d].name;
            if (d + 1 === rowBanner.dimensions.length) {
              element['rbd_data_type'] =
                DataUtility.alwaysFormattedAsNumber.some(
                  (x) => x === (data.primary.rows[i][d].s ?? '')
                )
                  ? 'number'
                  : this.ui.is_percent
                  ? 'percentage'
                  : 'number';
            }
          }
        }
      }
    }

    let bannerIx = 0;
    const columnBanners = [...config.crosstab.columns];

    const hasTotals =
      (config.crosstab.expansions ?? []).findIndex((x) =>
        DataUtility.isTotalsColumnDim(x.name)
      ) >= 0;

    if (hasTotals) {
      (config.crosstab.expansions ?? [])
        .filter((x) => DataUtility.isTotalsColumnDim(x.name))
        .forEach((expansion) => {
          columnBanners.unshift({
            name: expansion.label ?? '',
            dimensions: [],
            identifier: expansion.name,
          } as IBanner);
        });
    }

    const root: IColumnInfo = {
      header: 'root',
      title: 'root',
      field: '',
      pinned: false,
      sortable: false,
      resizable: true,
      filterable: false,
      columns: [] as Array<IColumnInfo>,
    };

    const bannerIds = data.primary.columns.map(
      (cols) => cols[0].bannerId ?? cols[0].id
    );
    const colBannerIds: string[] = [];
    bannerIds.forEach((id) => {
      if (id && !colBannerIds.some((y) => y === id)) {
        colBannerIds.push(id);
      }
    });

    for (const banner of columnBanners) {
      const name = banner.dimensions.map((x) => x.label).join(', ');
      const node: IColumnInfo = {
        header: name,
        title: name,
        field: '',
        pinned: false,
        sortable: false,
        resizable: true,
        filterable: false,
        columns: [],
      };
      root.columns!.push(node);

      const bannerColumns = data.primary.columns.filter((x) => {
        return (
          undefined !==
          x.find(
            (y) =>
              y.bannerId === `${colBannerIds[bannerIx]}` ||
              y.id === `${colBannerIds[bannerIx]}`
          )
        );
      });

      this.buildColumnHierarchary(node, 0, bannerColumns, columnDepth);
      bannerIx++;
    }

    for (const column of root.columns!) {
      result.push(column);
    }

    return result;
  }

  buildColumnHierarchary(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parent: any,
    depth: number,
    allColumns: ICrosstabDimension[][],
    maxDepth: number
  ): void {
    const results = DataUtility.groupWithMeta(
      allColumns,
      (item) => item[depth]?.k,
      (item) => item[depth]
    );
    for (const key in results) {
      if (Object.prototype.hasOwnProperty.call(results, key)) {
        const entry = results[key];
        const node: IColumnInfo = {
          header: entry.meta?.name ?? entry.meta?.id,
          title: entry.meta?.name ?? entry.meta?.id,
          source: entry.meta?.s ?? '',
          field: key,
          pinned: false,
          sortable: true,
          resizable: true,
          filterable: false,
          columns: [],
        };
        this.applyHeaderCellFormats(node);

        if (!parent.columns) {
          parent.columns = [];
        }
        parent.columns.push(node);

        node['_parent'] = parent;

        const nextDepth = depth + 1;
        if (nextDepth < maxDepth) {
          this.buildColumnHierarchary(node, nextDepth, entry.items, maxDepth);
        } else {
          // resolve identifiers
          const ids = [] as Array<string>;
          let test = node;
          while (test) {
            if (test.field && !test.field.startsWith(this.emptyColField)) {
              ids.push(test.field);
            }
            test = test['_parent'];
          }
          ids.reverse();
          node.field = ids.join(',');

          // clean node
          delete node.columns;

          // format node
          this.applyDataCellFormats(node);
        }

        delete node['_parent'];
      }
    }
  }

  applyHeaderCellFormats(column: IColumnInfo) {
    //TODO: Use configuration to control these settings
    column.cellStyles = {
      'justify-content': 'center',
    };
  }

  applyDataCellFormats(column: IColumnInfo) {
    column.dataType = this.ui.data_type;

    //TODO: Use configuration to control these sizings
    column.cellStyles = {
      'justify-content': 'center',
    };

    const fieldKeys = this.getFieldKeys(column.field);
    if (
      column.field &&
      this.gridCellWidths.some(
        (x) =>
          x.countField === fieldKeys.countField &&
          x.percentField === fieldKeys.percentField
      )
    ) {
      column.width =
        this.gridCellWidths.find(
          (x) =>
            x.countField === fieldKeys.countField &&
            x.percentField === fieldKeys.percentField
        )?.width ?? 'auto';
    } else {
      if (column.header && column.header.length > 0) {
        let colWidth = 56 + column.header.length * 6;
        if (colWidth < 90) {
          colWidth = 90;
        }
        if (colWidth > 240) {
          colWidth = 240;
        }
        column.width = colWidth.toString() + 'px';
      } else {
        column.width = 'auto';
      }

      if (fieldKeys.countField && fieldKeys.percentField) {
        this.gridCellWidths.push({
          countField: fieldKeys.countField,
          percentField: fieldKeys.percentField,
          width: column.width,
        });
      }
    }

    if (column.source === 'means_column') {
      column.dataType = 'number';
    }
  }

  showChartSwitch(flag: boolean): void {
    this.ui.show_chart = flag;
    if (this.segmentTrackingData) {
      this.segmentService.trackAction(
        ESegmentTrackActionKind.chartClicked,
        this.segmentTrackingData
      );
    }
  }

  comboAdditionSelected(args: ISimpleComboSelectionChangingEventArgs): void {
    if (args.newSelection) {
      this.selectAddition(args.newSelection);
    }
  }

  prepareGridForSelectedAction(): void {
    const { brand_id, mission, action } = this.ui;
    if (!mission || !action) {
      return;
    }

    this.backToTable();
    this.ui.tableViews = { loading: true, loaded: false, data: [] };
    this.ui.activeTableViewId = '';
    this.ui.activeTableView = undefined;
    this.resetData();

    this.config = this.tableViewService.getBaseCrosstabConfig();
    this.config.crosstab.brand_id = brand_id;
    this.config.crosstab.action_id_primary = action.action_id;
    this.ui.is_openend = action.action_kind === ActionKind.openended;
    this.config.crosstab.primary_action = {
      action_id: action.action_id,
      brand_id: brand_id,
    };

    this.addCrosstabLoader('tableViews');
    this.tableViewService
      .getTableViewsForAction(
        brand_id,
        mission,
        action
        // this.ui.defaultTableViews?.data ?? []
      )
      .pipe(takeUntil(this.unsubscribeAll), take(1))
      .subscribe((items) => {
        /* table view list */
        this.ui.tableViews.data = items ?? [];
        this.ui.tableViews.loaded = true;
        this.ui.tableViews.loading = false;
        this.removeCrosstabLoader('tableViews');

        this.defaultConfig = this.tableViewService.getBaseCrosstabConfig();

        if (this.ui.tableViews.data.length) {
          let defaultTableView;

          if (this.tableViewIdfromPath) {
            defaultTableView = this.ui.tableViews.data.find(
              (tv: ITableView) => tv.table_view_id === this.tableViewIdfromPath
            );
          } else {
            defaultTableView = items[0];
          }

          this.tableViewIdfromPath = undefined;

          // this.ui.organization_id = items[0].organization_id
          this.ui.activeTableViewId = defaultTableView.table_view_id;
          this.ui.activeTableView = defaultTableView;
          const crosstabConfig = {
            ...this.config.crosstab,
            ...defaultTableView.config,
          };

          this.browserNavigationService.pushNavigationState(
            `/brand/${this.ui.brand_id}/insights/data-explorer/${this.ui.mission?.mission_id}/action/${this.ui.action?.action_id}/tableview/${this.ui.activeTableViewId}`
          );

          if (defaultTableView.isGlobal) {
            crosstabConfig.brand_id = this.ui.brand_id;
            crosstabConfig.action_id_primary = this.ui.action?.action_id ?? '';
            this.defaultConfig = {
              meta: {},
              crosstab: {
                ...crosstabConfig,
                brand_id: '',
                action_id_primary: '',
              },
            };
          }

          const savedTableViewPref =
            this.tableViewService.getSavedTableViewPref(action.action_id);
          if (savedTableViewPref) {
            const savedTableView = this.ui.tableViews.data.find(
              (x) => x.table_view_id === savedTableViewPref
            );
            if (
              savedTableView &&
              savedTableView.id !== this.ui.activeTableViewId
            ) {
              this.switchToTableView(savedTableView);
              return;
            }
          }
          this.config.crosstab = crosstabConfig;
          this.tableViewService.formatDimensionLabels(
            this.config.crosstab,
            this.ui.action
          );
          this.applyConfiguration();
        } else {
          this.applyLoadingMessage();
        }
      });
  }

  onSelectTableView(e: ISelectionEventArgs): void {
    const { data } = this.ui.tableViews;
    const selectedTableView = data.find(
      (x) => x.table_view_id === e.newSelection.value
    );
    if (!selectedTableView) {
      return;
    }
    this.switchToTableView(selectedTableView);
  }

  private switchToTableView(tableView: ITableView): void {
    this.tableViewIsAsync = false;
    this.fetchDataCancelled.next();

    this.ui.activeTableView = tableView;
    this.ui.activeTableViewId = tableView.table_view_id;
    this.config = this.tableViewService.getBaseCrosstabConfig();
    this.config.crosstab = {
      ...this.tableViewService.getCrosstabConfigFromTableView(tableView.config),
    };
    this.config.crosstab.brand_id = this.ui.brand_id;
    this.config.crosstab.action_id_primary = this.ui.action?.action_id ?? '';
    this.tableViewService.formatDimensionLabels(
      this.config.crosstab,
      this.ui.action
    );
    this.applyConfiguration();

    this.browserNavigationService.pushNavigationState(
      `/brand/${this.ui.brand_id}/insights/data-explorer/${this.ui.mission?.mission_id}/action/${this.ui.action?.action_id}/tableview/${this.ui.activeTableViewId}`
    );
  }

  createTableViewForm(isCreate: boolean): void {
    const tableViewName = isCreate
      ? ''
      : this.ui.activeTableView?.display_name ?? '';
    const tableViewId = isCreate
      ? ''
      : this.ui.activeTableView?.table_view_id ?? '';
    this.tableViewFormGroup = this.formBuilder.group({
      TableViewName: [
        tableViewName,
        Validators.compose([Validators.required, Validators.maxLength(200)]),
      ],
      TableViewId: [tableViewId],
    });

    if (!isCreate) {
      this.tableViewFormGroup.patchValue({
        TableViewName: this.ui.activeTableView?.display_name ?? '',
        TableViewId: this.ui.activeTableView?.table_view_id ?? '',
      });
    }
  }

  saveAndApplyTableView(): void {
    if (
      !this.buildConfig?.crosstab ||
      !this.ui.mission ||
      !this.ui.action ||
      this.ui.savingTableView
    ) {
      return;
    }

    const isEdit =
      (this.ui.activeTableViewId ?? '').length &&
      this.tableViewFormGroup.controls['TableViewId'].value ===
        this.ui.activeTableViewId;

    const currentTableViewId =
      this.tableViewFormGroup.controls['TableViewId'].value ?? '';
    const tableViewName =
      this.tableViewFormGroup.controls['TableViewName'].value;
    const tableViewNames = (this.ui.tableViews.data ?? [])
      .filter((x) => !x.isGlobal && x.table_view_id !== currentTableViewId)
      .map((x) => x.display_name);

    if (tableViewNames.some((x) => x === tableViewName)) {
      const dialogData = {
        title: '',
        left: '',
        right: this.translate.instant('builder.confirm_right'),
        message: this.translate.instant('crosstab.duplicateTableViewName', {
          tableView: tableViewName,
        }),
        callback: () => {
          this.ui.confirm_dialog = undefined;
        },
      };

      this.ui.confirm_dialog = dialogData;
      this.confirmDialog.open();
      return;
    }

    this.builder.prepareSave();

    const tableViewData: ITableView = {
      ...this.ui.activeTableView,
      display_name: tableViewName,
      brand_id: this.ui.brand_id,
      mission_id: this.ui.mission?.mission_id,
      action_id: this.ui.action?.action_id,
      entity: 'crosstab.v1',
      key: this.tableViewService.getInterfaceConfigKeyForAction(
        this.ui.mission,
        this.ui.action
      ),
      config: this.tableViewService.prepareTableViewConfig(
        this.buildConfig.crosstab
      ),
      id: isEdit ? this.ui.activeTableViewId ?? '' : '',
      table_view_id: isEdit ? this.ui.activeTableViewId ?? '' : '',
      isGlobal: false,
    };

    const save$ = isEdit
      ? this.tableViewService.updateTableView(this.ui.brand_id, tableViewData)
      : this.tableViewService.createTableView(this.ui.brand_id, tableViewData);

    this.ui.savingTableView = true;
    save$.pipe(takeUntil(this.unsubscribeAll), take(1)).subscribe((resp) => {
      if (!isEdit) {
        this.ui.tableViews.data.push(resp);
        this.ui.activeTableViewId = resp.table_view_id ?? '';
      } else {
        const selectedOptionIx = this.ui.tableViews.data.findIndex(
          (x) => x.table_view_id === resp.table_view_id
        );
        if (selectedOptionIx >= 0) {
          this.ui.tableViews.data[selectedOptionIx] = { ...resp };
        }
      }
      this.ui.building = false;
      this.ui.activeTableView = resp;
      this.tableViewIsAsync = false;
      this.config = this.tableViewService.getBaseCrosstabConfig();
      this.config.crosstab = {
        ...this.tableViewService.getCrosstabConfigFromTableView(resp.config),
      };
      this.config.crosstab.brand_id = this.ui.brand_id;
      this.config.crosstab.action_id_primary = this.ui.action?.action_id ?? '';
      this.applyConfiguration();
      this.ui.savingTableView = false;
      if (this.ui.activeTableView) {
        this.updateTableViewNamesDict(this.ui.activeTableView, false);
      }

      this.builderToggle.emit(this.ui.building);
      this.scrollToTop();
      this.cbpActions.builderSubpopulations.next([]);
    });
  }

  onConfirmTableViewDelete(): void {
    if (!this.confirmDialog) {
      return;
    }
    const deleteTableView = () => {
      if (!this.ui.activeTableView) {
        return;
      }
      this.ui.savingTableView = true;
      const tableViewToDelete: ITableView = JSON.parse(
        JSON.stringify(this.ui.activeTableView)
      );

      if (this.segmentTrackingData) {
        this.segmentService.trackAction(
          ESegmentTrackActionKind.tableViewDeleteClicked,
          this.segmentTrackingData
        );
      }
      this.tableViewService
        .deleteTableView(this.ui.brand_id, tableViewToDelete.table_view_id)
        .pipe(takeUntil(this.unsubscribeAll), take(1))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .subscribe((resp: any) => {
          if (!resp?.success) {
            this.ui.savingTableView = false;
            this.builderToggle.emit(this.ui.building);
            this.scrollToTop();
            return;
          }
          this.tableViewService.removeTableViewPref(
            this.ui.action?.action_id ?? ''
          );
          this.ui.tableViews.data =
            this.ui.tableViews.data.filter(
              (x) => x.table_view_id !== this.ui.activeTableViewId
            ) ?? [];
          this.ui.building = false;

          if (!this.ui.tableViews.data.length) {
            this.ui.activeTableView = undefined;
            this.ui.activeTableViewId = undefined;
            this.config = this.tableViewService.getBaseCrosstabConfig();
            this.resetData();
            this.applyLoadingMessage();
            this.builderToggle.emit(this.ui.building);
            this.scrollToTop();
            return;
          }

          this.ui.activeTableView = this.ui.tableViews.data[0];
          this.ui.activeTableViewId = this.ui.activeTableView.table_view_id;
          this.config = this.tableViewService.getBaseCrosstabConfig();
          this.config.crosstab = {
            ...this.tableViewService.getCrosstabConfigFromTableView(
              this.ui.activeTableView.config
            ),
          };
          this.config.crosstab.brand_id = this.ui.brand_id;
          this.config.crosstab.action_id_primary =
            this.ui.action?.action_id ?? '';
          this.applyConfiguration();
          this.updateTableViewNamesDict(tableViewToDelete, true);
          this.ui.savingTableView = false;
          this.builderToggle.emit(this.ui.building);
          this.scrollToTop();
        });
    };

    const dialogData = {
      title: this.translate.instant('crosstab.deleteTableViewConfirmTitle'),
      left: this.translate.instant('builder.cancel'),
      right: this.translate.instant('crosstab.delete'),
      message: this.translate.instant('crosstab.deleteTableViewConfirm', {
        tableView: this.ui.activeTableView?.display_name ?? '',
      }),
      callback: deleteTableView,
    };

    this.ui.confirm_dialog = dialogData;
    this.confirmDialog.open();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  onConfirmCallback(accept: boolean, dialog: IgxDialogComponent, ev?: any) {
    dialog.close();

    if (accept) {
      if (this.ui.confirm_dialog?.callback) {
        this.ui.confirm_dialog.callback();
      }
    }
    setTimeout(() => {
      this.ui.confirm_dialog = undefined;
    }, 500);
  }

  downloadChartAsImage(): void {
    this.downloadingImage = true;
    this.ui.snackbar_msg = this.translate.instant('crosstab.downloadRequested');
    this.showSnackbar();
    setTimeout(() => {
      html2canvas(this.chartContent.nativeElement).then((canvas) => {
        canvas.toBlob((blob) => {
          let fileName = '';
          if (this.ui.action && this.ui.action.strippedSearchText) {
            fileName = this.ui.action.strippedSearchText;
          }
          FileSaver.saveAs(blob, `${sanitize(fileName.substring(0, 150))}.png`);
        });
      });
      this.downloadingImage = false;
      if (this.segmentTrackingData) {
        this.segmentService.trackAction(
          ESegmentTrackActionKind.chartDownloaded,
          this.segmentTrackingData
        );
      }
    }, this.downloadRenderDelay);
  }

  adjustChartWidth(expand: boolean): void {
    this.ui.chart_expanded = expand;
    this.expandChart.emit(expand);
  }

  backToTable(): void {
    this.adjustChartWidth(false);
    this.ui.show_chart = false;
  }

  onQueueExport(exportKind: 'excel' | 'ppt'): void {
    if (
      !this.ui.brand_id ||
      !this.ui?.action?.mission_id ||
      !this.ui?.mission?.mission_id
    ) {
      return;
    }

    this.config.crosstab.ui_state = this.config.crosstab.ui_table_config?.id;

    const gridData = { ...this.grid_data };
    delete gridData.cellTemplate;
    const fetchData: ICrosstabConfig = JSON.parse(
      JSON.stringify(this.config.crosstab)
    );
    fetchData.columns.forEach((col: IBanner) => {
      col.identifier = DataUtility.generateGUID();
    });
    fetchData.rows.forEach((row: IBanner) => {
      row.identifier = DataUtility.generateGUID();
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let queueDownload$!: Observable<any>;

    switch (exportKind) {
      case 'excel':
        this.exportingExcel = true;
        queueDownload$ = this.suzy.exportToExcel(
          this.ui.brand_id,
          this.ui.action.action_id,
          this.ui.mission.mission_id,
          JSON.stringify(fetchData),
          this.ui.is_percent,
          this.ui.activeTableView?.display_name
        );
        break;
      case 'ppt':
        this.exportingPPT = true;
        queueDownload$ = this.suzy.exportToPPT(
          this.ui.brand_id,
          this.ui.action.action_id,
          this.ui.mission.mission_id,
          JSON.stringify(fetchData),
          this.ui.is_percent,
          this.ui.activeTableView?.display_name
        );
        break;
      default:
        return;
    }

    if (!queueDownload$) {
      return;
    }

    queueDownload$.subscribe({
      next: (response) => {
        if (response.success) {
          this.ui.snackbar_msg = this.translate.instant(
            'crosstab.downloadRequested2'
          );
          this.showSnackbar();
        }
        this.exportingPPT = false;
        this.exportingExcel = false;
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      error: (error) => {
        this.exportingPPT = false;
        this.exportingExcel = false;
      },
    });

    setTimeout(() => {
      this.renderer.removeClass(
        this.PPTExport.elementRef.nativeElement,
        'igx-drop-down__item--selected'
      );
      this.renderer.removeClass(
        this.excelExport.elementRef.nativeElement,
        'igx-drop-down__item--selected'
      );
    }, 1000);

    if (this.segmentTrackingData) {
      const trackData = <ISegmentTrackActionData>{
        ...this.segmentTrackingData,
        data_export_kind_name: exportKind === 'excel' ? 'excel' : 'powerpoint',
      };
      this.segmentService.trackAction(
        ESegmentTrackActionKind.individualExport,
        trackData
      );
    }
  }

  private adjustRowsData(data: ICrosstabData): void {
    if (!this.config.crosstab.rows?.length) {
      return;
    }

    const rowsToAdjust = [
      ...DataUtility.bannerSummaryDimensions,
      ...DataUtility.tableSummaryDimensions,
    ];
    const adjustRows = (rowsData: ICrosstabDimension[][]) => {
      const rowsToProcess = rowsData.filter(
        (x) => x.length === 1 && rowsToAdjust.some((k) => k === x[0].s)
      );
      if (!rowsToProcess?.length) {
        return;
      }

      rowsToProcess.forEach((rows) => {
        if (!rows.length) {
          return;
        }
        const row = rows[0];
        const { bannerId } = row;
        if (!bannerId) {
          return;
        }

        const bannerRefRow = rowsData.find((rows) => {
          return rows.some(
            (row) =>
              row.bannerId === bannerId &&
              !DataUtility.isBannerSummaryDim(row.s)
          );
        });
        if (!bannerRefRow?.length) {
          return;
        }

        const bannerDepth = bannerRefRow.length;
        for (let depth = 1; depth < bannerDepth; depth++) {
          const cloneRow: ICrosstabDimension = JSON.parse(JSON.stringify(row));
          cloneRow.k = this.emptyRowField;
          cloneRow.b = `${bannerRefRow[0].b}_i`;
          cloneRow.id = this.infoRowId;
          cloneRow.n = '';
          cloneRow.name = '';
          if (depth === bannerDepth - 1) {
            cloneRow.name = 'Summary Metrics';
          }

          rows.unshift(cloneRow);
        }
      });
    };

    adjustRows(data.primary.rows);

    if (data.additions && Object.keys(data.additions).length) {
      const additionKeys = Object.keys(data.additions);
      additionKeys.forEach((key) => {
        adjustRows(data.additions[key].rows);
      });
    }
  }

  private adjustColsData(data: ICrosstabData): void {
    if (!this.config.crosstab.columns?.length) {
      return;
    }

    const adjustUnevenCols = (colsData: ICrosstabDimension[][]) => {
      const maxColsDepth = Math.max(...colsData.map((o) => o.length));
      colsData.forEach((cols, ix) => {
        if (cols.length === maxColsDepth) {
          return;
        }
        const numColsToAdd = maxColsDepth - cols.length;
        for (let loop = 0; loop < numColsToAdd; loop++) {
          const cloneCol: ICrosstabDimension = JSON.parse(
            JSON.stringify(cols[0])
          );
          cloneCol.k = `${this.emptyColField}_${ix}_${loop}`;
          cloneCol.b = `${cloneCol.b}_i`;
          cloneCol.n = '';
          cloneCol.name = '';
          cloneCol.s = '';
          cols.unshift(cloneCol);
        }
      });
    };

    adjustUnevenCols(data.primary.columns);

    if (data.additions && Object.keys(data.additions).length) {
      const additionKeys = Object.keys(data.additions);
      additionKeys.forEach((key) => {
        adjustUnevenCols(data.additions[key].columns);
      });
    }
  }

  onDuplicateTableViewClick(): void {
    if (this.duplicateTableViewOverlayId) {
      return;
    }
    const settings = IgxOverlayService.createAbsoluteOverlaySettings(
      AbsolutePosition.Center
    );
    settings.closeOnEscape = false;
    settings.closeOnOutsideClick = false;
    const { positionStrategy } = settings;
    if (positionStrategy) {
      positionStrategy.settings.minSize = { width: 500, height: 500 };
    }

    this.duplicateTableViewOverlayId = this.overlayService.attach(
      DuplicateTableComponent,
      settings
    );

    this.overlayService.opening
      .pipe(
        takeUntil(this.unsubscribeAll),
        filter((x) => x.id === this.duplicateTableViewOverlayId),
        take(1)
      )
      .subscribe((e: OverlayCancelableEventArgs) => {
        if (!e.componentRef) {
          return;
        }
        this.initDuplicateTableView(e.componentRef);
      });
    this.overlayService.show(this.duplicateTableViewOverlayId);
  }

  private initDuplicateTableView(
    ref: ComponentRef<DuplicateTableComponent>
  ): void {
    const { instance } = ref;
    if (!instance || !this.ui.activeTableView) {
      return;
    }

    instance.ui = this.ui;
    instance.saveComplete
      .pipe(
        takeUntil(this.unsubscribeAll),
        takeUntil(this.duplicateOverlayClosed),
        take(1)
      )
      .subscribe((numDuplicate: number) => {
        this.overlayService.hide(this.duplicateTableViewOverlayId ?? '');
        setTimeout(() => {
          this.ui.snackbar_msg =
            numDuplicate > 1
              ? this.translate.instant('crosstab.duplicateSuccess', {
                  numDuplicate,
                })
              : this.translate.instant('crosstab.duplicateSuccessSingle', {
                  numDuplicate,
                });
          this.showSnackbar();
        }, 500);
      });
    instance.cancelClick
      .pipe(
        takeUntil(this.unsubscribeAll),
        takeUntil(this.duplicateOverlayClosed),
        take(1)
      )
      .subscribe(() => {
        this.overlayService.hide(this.duplicateTableViewOverlayId ?? '');
      });

    this.overlayService.closed
      .pipe(
        takeUntil(this.unsubscribeAll),
        filter((x) => x.id === this.duplicateTableViewOverlayId),
        take(1)
      )
      .subscribe(() => {
        this.overlayService.detach(this.duplicateTableViewOverlayId ?? '');
        delete this.duplicateTableViewOverlayId;
      });
  }

  private updateTableViewNamesDict(
    tableView: ITableView,
    isDelete?: boolean
  ): void {
    const { mission_id, action_id, table_view_id, display_name } = tableView;
    if (!mission_id || !action_id || !table_view_id || !display_name) {
      return;
    }
    const namesDictIx = this.ui.tableViewNamesDict.data.findIndex(
      (x) =>
        x.mission_id === mission_id &&
        x.action_id === action_id &&
        x.table_view_id === table_view_id
    );
    if (namesDictIx >= 0) {
      if (isDelete) {
        this.ui.tableViewNamesDict.data.splice(namesDictIx, 1);
      } else {
        this.ui.tableViewNamesDict.data[namesDictIx].display_name =
          display_name;
      }
    } else if (!isDelete) {
      this.ui.tableViewNamesDict.data.push({
        mission_id,
        action_id,
        table_view_id,
        display_name,
      });
    }
  }

  private getKeysMap(
    primary: ICrosstabDataSet,
    addition: ICrosstabDataSet,
    isRow?: boolean
  ): Array<{ sourceKey: string; destKey: string }> {
    const resultMap: Array<{ sourceKey: string; destKey: string }> = [];
    const sourceMap = isRow ? primary.map.r : primary.map.c;
    const destMap = isRow ? addition.map.r : addition.map.c;
    const fieldKeyPrefix = isRow ? 'r' : 'c';

    const findKeyForId = (
      id: string,
      map: { [key: string]: string }
    ): string => {
      const found = Object.keys(map)
        .filter((x) => x.startsWith(fieldKeyPrefix))
        .find((x) => {
          const val = map[x];
          return val === id;
        });
      return found ?? '';
    };

    Object.keys(sourceMap)
      .filter((x) => x.startsWith(fieldKeyPrefix))
      .forEach((sourceKey) => {
        const sourceId = sourceMap[sourceKey];
        const destKey = findKeyForId(sourceId, destMap);
        resultMap.push({ sourceKey, destKey });
      });

    return resultMap;
  }

  private getDefaultSortExpression(
    columns: IColumnInfo[]
  ): ISortingExpression[] {
    if (!this.ui.action || columns.length < 2) {
      return [];
    }

    const { action_kind } = this.ui.action;
    if (
      ![
        ActionKind.multiplechoice,
        ActionKind.auto_assign,
        ActionKind.turf,
      ].some((x) => x === action_kind)
    ) {
      return [];
    }

    if (
      action_kind === ActionKind.multiplechoice &&
      this.ui.action.multiple_choice?.multiple_choice_kind !==
        ActionStructureMultipleChoiceKind.standard
    ) {
      return [];
    }

    const getFirstColFieldName = (col: IColumnInfo): string => {
      if ((col.columns?.length ?? 0) > 0) {
        return getFirstColFieldName(col.columns![0]);
      }

      return col.field;
    };

    const firstCol = columns[1];
    const fieldName = getFirstColFieldName(firstCol);
    if (fieldName) {
      return [
        {
          fieldName,
          dir: SortingDirection.Desc,
          strategy: this.customSortingStrategy,
        },
      ];
    }
    return [];
  }

  onGridSort(
    sortingExpression: ISortingExpression | ISortingExpression[]
  ): void {
    const sort: ISortingExpression = Array.isArray(sortingExpression)
      ? sortingExpression[0]
      : sortingExpression;
    this.grid_data.appliedSort = [sort];
    if (sort.dir === SortingDirection.None) {
      this.grid_data.defaultSort = [];
      this.grid_data.data = JSON.parse(
        JSON.stringify(this.grid_data.dataDefaultSort)
      );
      return;
    }
    this.grid_data.defaultSort = [sort];
  }

  onValidateTableView(): void {
    if (!this.tableViewValidatorDialog) {
      return;
    }

    this.tableViewValidatorDialog.open();
  }

  onValidationSaved(result: ICrosstabResult): void {
    this.ui.snackbar_msg = result.message ?? '';
    this.showSnackbar();
  }

  showSnackbar(): void {
    setTimeout(() => {
      this.snackbar.closed.pipe(take(1)).subscribe(() => {
        this.ui.snackbar_msg = '';
      });
      this.snackbar.open();
    }, 0);
    setTimeout(() => {
      this.snackbar.close();
    }, this.downloadRenderDelay);
  }

  private scrollToTop(): void {
    if (!document) {
      return;
    }

    const newTop = this.tableViewForm
      ? this.tableViewForm.nativeElement.offsetTop - 50
      : 0;
    setTimeout(() => {
      const psScroll = document.getElementById('content-ps-scroll');
      if (psScroll) {
        psScroll.scrollTop = newTop;
      } else {
        window.scroll(0, newTop > 0 ? newTop : 0);
      }
    }, 0);
  }

  onGridResize(event: IColumnResizeEventArgs): void {
    const setGridColWidth = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      columns: any,
      field: string,
      newWidth: string
    ): boolean => {
      if (columns.field === field) {
        columns.width = newWidth;
        return true;
      }
      if (columns.columns?.length) {
        let updated = false;
        columns.columns.forEach((childColumns) => {
          updated = setGridColWidth(childColumns, field, newWidth);
          if (updated) {
            return;
          }
        });
        return updated;
      }

      return false;
    };

    if (event.column.field && event.newWidth) {
      const fieldKeys = this.getFieldKeys(event.column.field);
      const found = this.gridCellWidths.find(
        (x) =>
          x.countField === fieldKeys.countField &&
          x.percentField === fieldKeys.percentField
      );

      if (found) {
        found.width = event.newWidth;
        this.grid_data.columns?.forEach((columns) => {
          setGridColWidth(columns, event.column.field, event.newWidth);
        });
      }
    }
  }

  private getFieldKeys(field: string): {
    countField: string | undefined;
    percentField: string | undefined;
  } {
    let countField: string | undefined, percentField: string | undefined;
    const mapKind = this.ui.is_percent
      ? 'fromPercentToCount'
      : 'fromCountToPercent';
    if (this.ui.is_percent) {
      percentField = field;
      countField = percentField
        .split(',')
        .map((field) => {
          return this.columnsKeyMap
            ?.find((x) => x.kind === mapKind)
            ?.map?.find((x) => x.sourceKey === field)?.destKey;
        })
        .filter((x) => x !== undefined)
        .join(',');
    } else {
      countField = field;
      percentField = countField
        .split(',')
        .map((field) => {
          return this.columnsKeyMap
            ?.find((x) => x.kind === mapKind)
            ?.map?.find((x) => x.sourceKey === field)?.destKey;
        })
        .filter((x) => x !== undefined)
        .join(',');
    }

    return { countField, percentField };
  }

  showTableInformation(): void {
    this.tableInfo = {
      brandId: this.ui.brand_id,
      missionId: this.ui.mission?.mission_id ?? '',
      isPercentView: this.ui.is_percent,
      filterStatus: this.tableViewService.crosstabConfigHasAdvancedFilters(
        this.config.crosstab
      ),
      filterTags: [],
      calculationsStatus: this.tableViewService.crosstabConfigHasCalculations(
        this.ui.advancedCalculationsEnabled ?? false,
        this.config.crosstab
      ),
      calculationsTags: [],
      preparation: this.raw_data?.preparation ?? { items: [] },
      updated_utc: this.ui.activeTableView?.updated_utc,
    };
    this.tableInfo.filterTags = this.tableViewService.getAdvancedFiltersTags(
      this.tableInfo.filterStatus,
      this.ui.is_global
    );
    this.tableInfo.calculationsTags = this.tableViewService.getCalculationsTags(
      this.tableInfo.calculationsStatus
    );
    this.tableInformationDialog.closed
      .pipe(takeUntil(this.unsubscribeAll), take(1))
      .subscribe(() => {
        this.tableInfo = undefined;
      });
    this.tableInformationDialog.open();
    if (this.segmentTrackingData) {
      this.segmentService.trackAction(
        ESegmentTrackActionKind.tableInfoModalClicked,
        this.segmentTrackingData
      );
    }
  }

  onRefreshData(actionIds: Array<string>): void {
    if (!actionIds.length) {
      return;
    }
    this.applyConfiguration(actionIds);
  }

  onCloneTableViewClick(): void {
    if (
      !this.ui.activeTableView ||
      !this.ui.mission ||
      !this.ui.action ||
      this.ui.cloning
    ) {
      return;
    }

    this.ui.cloning = true;
    if (this.segmentTrackingData) {
      this.segmentService.trackAction(
        ESegmentTrackActionKind.tableViewDuplicated,
        this.segmentTrackingData
      );
    }

    const tableViewName =
      this.generateClonedTableViewName() ??
      this.translate.instant('crosstab.clonedTableViewName', {
        name: this.ui.activeTableView.display_name,
      });
    const tableViewData: ITableView = {
      display_name: tableViewName,
      brand_id: this.ui.brand_id,
      mission_id: this.ui.mission?.mission_id,
      action_id: this.ui.action?.action_id,
      entity: 'crosstab.v1',
      key: this.tableViewService.getInterfaceConfigKeyForAction(
        this.ui.mission,
        this.ui.action
      ),
      config: this.ui.activeTableView.config,
      id: '',
      table_view_id: DataUtility.generateGUID(),
      isGlobal: false,
    };

    const save$ = this.tableViewService.createTableView(
      this.ui.brand_id,
      tableViewData
    );

    save$.pipe(takeUntil(this.unsubscribeAll), take(1)).subscribe((resp) => {
      this.ui.tableViews.data.push(resp);
      this.ui.activeTableViewId = resp.table_view_id ?? '';
      this.ui.activeTableView = resp;
      this.config = this.tableViewService.getBaseCrosstabConfig();
      this.config.crosstab = {
        ...this.tableViewService.getCrosstabConfigFromTableView(resp.config),
      };
      this.config.crosstab.brand_id = this.ui.brand_id;
      this.config.crosstab.action_id_primary = this.ui.action?.action_id ?? '';
      this.applyConfiguration();
      if (this.ui.activeTableView) {
        this.updateTableViewNamesDict(this.ui.activeTableView, false);
      }
      this.ui.cloning = false;
      this.ui.snackbar_msg = this.translate.instant(
        'crosstab.cloneTableViewSuccess'
      );
      this.showSnackbar();
    });
  }

  private generateClonedTableViewName(): string | undefined {
    if (!this.ui.action || !this.ui.activeTableView) {
      return;
    }
    const newName = this.translate.instant('crosstab.clonedTableViewName', {
      name: this.ui.activeTableView.display_name,
    });
    const found = this.ui.tableViewNamesDict.data.filter(
      (x) =>
        x.mission_id === this.ui.action?.mission_id &&
        x.action_id === this.ui.action.action_id &&
        x.display_name.startsWith(newName)
    );
    if (found?.length) {
      for (let ix = 1; ix <= found.length + 1; ix++) {
        const tmpName = `${newName} (${ix})`;
        const tmpNameExists =
          this.ui.tableViewNamesDict.data.findIndex(
            (x) =>
              x.mission_id === this.ui.action?.mission_id &&
              x.action_id === this.ui.action.action_id &&
              x.display_name === tmpName
          ) >= 0;
        if (!tmpNameExists) {
          return tmpName;
        }
      }
      return `${newName} (${found.length + 2})`;
    }
    return newName;
  }

  private checkIsSingleChart(): void {
    if (
      this.grid_data.columns[this.grid_data.columns.length - 1].columns
        .length === 1 &&
      this.grid_data.depth === 1
    ) {
      let numColsWithoutTotals = 0;
      let hasTotalsColumn = false;
      this.grid_data.columns.forEach((cols, colIx) => {
        if (colIx === 0) {
          return;
        }
        const isTotals = this.isTotalColumn(cols.columns[0]);
        hasTotalsColumn = hasTotalsColumn || isTotals;
        if (!isTotals) {
          numColsWithoutTotals++;
        }
      });
      if (hasTotalsColumn) {
        this.ui.is_single_chart = numColsWithoutTotals <= 1;
      } else {
        this.ui.is_single_chart = numColsWithoutTotals === 1;
      }
    } else {
      this.ui.is_single_chart = false;
    }
  }

  private isTotalColumn(col: IColumnInfo): boolean {
    return [
      ...DataUtility.totalsColumnDimensions,
      ...DataUtility.bannerSummaryDimensions,
    ].some((x) => (col.source ?? '') === x);
  }

  onManageTableViewsClick(): void {
    this.ui.manage_tables = true;
    this.navigationStatus.DENavigationStatus.next({
      title: 'crosstab.manageTableViews',
      backTo: ELibraryRoutes.Explorer,
      currentRoute: ELibraryRoutes.ManageTables,
    });
    this.manageTableViews.emit(this.ui.manage_tables);
  }

  onCancelFetchDataClick(): void {
    if (!this.ui.canCancelDataFetch) {
      return;
    }

    this.fetchDataCancelled.next();
    this.removeCrosstabLoader('apply');
    this.ui.canRetryDataFetch = true;
    this.applyLoadingMessage();
  }

  onRetryFetchDataClick(): void {
    if (!this.ui.canRetryDataFetch) {
      return;
    }
    this.applyConfiguration();
  }

  private saveTableViewPref(): void {
    if (!this.ui.activeTableView) {
      return;
    }
    if (!this.ui.activeTableView.isGlobal) {
      this.tableViewService.saveTableViewPref(
        this.ui.action?.action_id ?? '',
        this.ui.activeTableView.table_view_id
      );
    } else {
      this.tableViewService.removeTableViewPref(
        this.ui.action?.action_id ?? ''
      );
    }
  }

  private clearFailedTableViewPref(): void {
    if (!this.ui.activeTableView) {
      return;
    }
    const pref = this.tableViewService.getSavedTableViewPref(
      this.ui.action?.action_id ?? ''
    );
    if (!pref) {
      return;
    }

    if (pref === this.ui.activeTableView.table_view_id) {
      this.tableViewService.removeTableViewPref(
        this.ui.action?.action_id ?? ''
      );
    }
  }

  openForm(open: boolean): void {
    this.builderSPForm.open();
    this.openSPDialog = open;
    document.body.classList.add('no-scroll');
    this.builderDimensions = this.cbpActions.extractDimensions(
      this.builder.dimensionProviders,
      this.ui.action
    );
  }

  closeSPDialog(): void {
    this.builderSPForm.close();
    this.openSPDialog = false;
    this.fabricationToEdit = undefined;
    this.builderDimensions = undefined;
    document.body.classList.remove('no-scroll');
    this.cbpActions.formHasChanges.next(false);
  }

  sendSPFormValues(formValues: IFabrication): void {
    this.saveFabrication.emit(formValues);
    this.closeSPDialog();
  }

  editForm(id: string): void {
    this.cbpService
      .getFabricationDetails(
        id,
        this.ui.brand_id,
        EFabricationEndpointType.Subpopulation,
        this.ui.mission?.mission_id
      )
      .pipe(
        tap((fabDetail) => {
          this.fabricationToEdit = fabDetail.item;
          this.openForm(true);
        }),
        take(1),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe();
  }

  verifyBeforeClose(close: boolean): void {
    if (close) {
      if (this.cbpActions.formHasChanges.getValue()) {
        this.dialog.open(CbpConfirmActionDialogComponent, {
          data: {
            title: this.translate.instant(
              'customBannerPoints.formText.validations.confirmLeave.title'
            ),
            body: this.translate.instant(
              'customBannerPoints.formText.validations.confirmLeave.body'
            ),
            ctaBtn: this.translate.instant(
              'customBannerPoints.formText.validations.confirmLeave.ctaBtn'
            ),
            cancelBtn: this.translate.instant(
              'customBannerPoints.formText.validations.confirmLeave.cancelBtn'
            ),
            callback: () => this.closeSPDialog(),
          },
        });
      } else {
        this.closeSPDialog();
      }
    }
  }

  sendAsyncNotification(evt: any): void {
    this.sendingAsyncNotification = true;

    if (this.ui.activeTableView) {
      this.tableViewsRequests
        .notifyAsync(
          this.ui.activeTableView.brand_id!,
          this.ui.activeTableView?.table_view_id
        )
        .pipe(
          tap((subscription) => {
            this.sendingAsyncNotification = false;
            this.ui.activeTableView!.self_subscription = subscription.item;
            this.ui.tableViews.data.map((item: ITableView) => {
              if (
                item.table_view_id === this.ui.activeTableView?.table_view_id
              ) {
                item.self_subscription = subscription.item;
              }
              return item;
            });
            if (this.segmentTrackingData) {
              this.segmentService.trackAction(
                ESegmentTrackActionKind.AsyncNotifyMe,
                this.segmentTrackingData
              );
            }
          }),
          take(1),
          catchError((error: HttpErrorResponse) => {
            this.sendingAsyncNotification = false;
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  confirmNotificationCanceling(evt: any): void {
    this.dialog.open(CbpConfirmActionDialogComponent, {
      data: {
        title: this.translate.instant('asyncNotification.cancel.title'),
        body: this.translate.instant('asyncNotification.cancel.caption'),
        ctaBtn: this.translate.instant('asyncNotification.cancel.cta'),
        cancelBtn: this.translate.instant('asyncNotification.cancel.cancel'),
        callback: () => this.cancelAsyncNotification(),
      },
    });
  }

  cancelAsyncNotification(): void {
    this.sendingAsyncNotification = true;

    if (this.ui.activeTableView) {
      this.tableViewsRequests
        .cancelAsyncNotification(
          this.ui.activeTableView.brand_id!,
          this.ui.activeTableView?.table_view_id
        )
        .pipe(
          tap((subscription) => {
            this.sendingAsyncNotification = false;
            this.ui.activeTableView!.self_subscription = undefined;
            this.ui.tableViews.data.map((item: ITableView) => {
              if (
                item.table_view_id === this.ui.activeTableView?.table_view_id
              ) {
                item.self_subscription = undefined;
              }
              return item;
            });
          }),
          take(1),
          catchError((error: HttpErrorResponse) => {
            this.sendingAsyncNotification = false;
            return EMPTY;
          })
        )
        .subscribe();
    }
  }
}
