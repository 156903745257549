import { Dialog } from '@angular/cdk/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Subject, take, takeUntil, tap } from 'rxjs';
import { EFabricationEndpointType } from '../../../enums/cbp-enum';
import { IFabItem } from '../../../models/fabrication/IFabricationResponse';
import { DATA_TOKEN, ICBPFormInputs } from '../../../models/ui/IInjectionToken';
import { CustomBannerPointsActionsService } from '../../../services/custom-banner-points-actions.service';
import { CustomBannerPointsService } from '../../../services/custom-banner-points.service';
import { LibraryNavigationService } from '../../../services/library-navigation.service';
import { CbpConfirmActionDialogComponent } from '../../custom-banner-points/cbp-confirm-action-dialog/cbp-confirm-action-dialog.component';
import { TableViewService } from '../../../services/table-view.service';

@Component({
  selector: 'manage-tables-portal',
  templateUrl: './manage-tables-portal.component.html',
  styleUrls: ['./manage-tables-portal.component.scss'],
})
export class ManageTablesPortalComponent implements OnInit, OnDestroy {
  derivedQuestions!: IFabItem[];
  subpopulations!: IFabItem[];
  brandId = '';
  mission: any;
  action: any;
tableView!: any[];
  unsubscribe = new Subject<void>();

  constructor(
    private navigationStatus: LibraryNavigationService,
    private cbpRequests: CustomBannerPointsService,
    private cbpActions: CustomBannerPointsActionsService,
    private dialog: Dialog,
    private translate: TranslateService,
    private tableViews: TableViewService,
    @Inject(DATA_TOKEN) public formInputs: ICBPFormInputs
  ) {
    if (this.formInputs && this.formInputs.uiData) {
      this.brandId = formInputs.uiData?.brand_id ?? '';
      this.mission = formInputs.uiData?.mission ?? '';
      this.action = formInputs.uiData?.action ?? '';
      
    }
  }

  ngOnInit(): void {
    this.cbpActions.bannerSubpopulations$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.subpopulations = res;
      });
    
    this.getTableViews();
  }

  getTableViews() {
    this.tableViews.getTableViewsForAction(this.brandId, this.mission, this.action)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        // Return tables views except first default table view
        this.tableView = res.slice(1);
      });

  }

  ngOnDestroy(): void {
    this.cbpActions.selectedSavedBanner.next(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getSubpopulations(): void {
    this.cbpRequests
      .getFabricationsByType(
        this.brandId,
        EFabricationEndpointType.Subpopulation
      )
      .pipe(
        map((item: any) => item.items),
        tap((res) => {
          this.cbpActions.bannerSubpopulations.next(res);
          this.subpopulations = res;
        }),
        take(1)
      )
      .subscribe();
  }

  getDerivedQuestions(): void {
    this.cbpRequests
      .getFabricationsByType(
        this.brandId,
        EFabricationEndpointType.DerivedQuestions
      )
      .pipe(
        map((item: any) => item.items),
        tap((res) => (this.derivedQuestions = res)),
        take(1)
      )
      .subscribe();
  }


  confirmDelete(fabId: string): void {
    this.dialog.open(CbpConfirmActionDialogComponent, {
      data: {
        title: this.translate.instant(
          'customBannerPoints.formText.validations.confirmDelete.title'
        ),
        ctaBtn: this.translate.instant(
          'customBannerPoints.formText.validations.confirmDelete.ctaBtn'
        ),
        cancelBtn: this.translate.instant(
          'customBannerPoints.formText.validations.confirmDelete.cancelBtn'
        ),
        callback: () => this.deleteFabrication(fabId),
      },
    });
  }

  deleteFabrication(fabId: string): void {
    // this.cbpRequests
    //   .deleteFabrication(
    //     fabId,
    //     this.brandId,
    //     EFabricationEndpointType.Subpopulation
    //   )
    //   .pipe(
    //     tap(() => this.getSubpopulations()),
    //     take(1)
    //   )
    //   .subscribe();

    this.tableViews
      .deleteTableView(this.brandId, fabId)
      .pipe(
        tap(() => this.getTableViews()),
        takeUntil(this.unsubscribe),
        take(1)
      )
      .subscribe();
  }
}
