<ng-container *ngIf="mission.mission_kind !== undefined">
  <ng-container *ngIf="
      mission.mission_kind !== missionKind.screening &&
      mission.mission_kind !== missionKind.survey &&
      mission.mission_kind !== missionKind.splittesting &&
      mission.mission_kind !== missionKind.focusgroup &&
      mission.mission_kind !== missionKind.maxdiff &&
      mission.mission_kind !== missionKind.external_cint &&
      mission.mission_kind !== missionKind.external_link
    ">
    <ng-container *ngIf="mission.first_action?.action_kind === actionKind.multiplechoice">
      <span class="material-symbols-rounded"
        *ngIf="mission.first_action?.multiple_choice?.multiple_choice_kind === multipleChoiceKind.standard">
        radio_button_checked</span>
      <span class="material-symbols-rounded"
        *ngIf="mission.first_action?.multiple_choice?.multiple_choice_kind === multipleChoiceKind.rating">
        more_horiz</span>
    </ng-container>
    <span class="material-symbols-rounded"
      *ngIf="mission.first_action?.action_kind === actionKind.openended">sort</span>
    <span class="material-symbols-rounded"
      *ngIf="!hidePhoto && mission.first_action?.action_kind === actionKind.photoacquisition">
      photo_camera</span>
    <ng-container *ngIf="
        mission.first_action?.action_kind === actionKind.grid ||
        mission.first_action?.action_kind === actionKind.gridcustom ||
        mission.first_action?.action_kind === actionKind.gridrankscale
      ">

      <igx-icon *ngIf="mission.first_action?.grid?.grid_kind === gridKind.rank" name="grid_rank"
        family="suzy"></igx-icon>
      <igx-icon *ngIf="mission.first_action?.grid?.grid_kind === gridKind.scale" name="grid_scale"
        family="suzy"></igx-icon>

      <span class="material-symbols-rounded"
        *ngIf="mission.first_action?.grid?.grid_kind === gridKind.open">grid_on</span>
    </ng-container>

    <igx-icon *ngIf="mission.first_action?.action_kind === actionKind.turf" name="turf" family="suzy"></igx-icon>
  </ng-container>
  <span class="material-symbols-rounded" *ngIf="mission.mission_kind === missionKind.screening">filter_list</span>
  <span class="material-symbols-rounded" *ngIf="mission.mission_kind === missionKind.survey">thumbs_up_down</span>

  <span class="material-symbols-rounded" *ngIf="mission.mission_kind === missionKind.splittesting">
    arrow_split
  </span>
  <span class="material-symbols-rounded" *ngIf="mission.mission_kind === missionKind.focusgroup">forum</span>

  <igx-icon *ngIf="mission.mission_kind === missionKind.maxdiff" name="maxdiff" family="suzy"></igx-icon>
  <igx-icon *ngIf="mission.mission_kind === missionKind.external_cint" name="global_mission" family="suzy"></igx-icon>


  <span *ngIf="mission.mission_kind === missionKind.external_link" class="suzy-icon"
    aria-label="Public link">public-link</span>
</ng-container>

<ng-container *ngIf="mission && mission.mission_kind === undefined">
  <ng-container *ngIf="mission.action_kind === actionKind.multiplechoice">
    <span class="material-symbols-rounded"
      *ngIf="mission.multiple_choice.multiple_choice_kind === multipleChoiceKind.standard">
      radio_button_checked</span>
    <span class="material-symbols-rounded"
      *ngIf="mission.multiple_choice.multiple_choice_kind === multipleChoiceKind.rating">more_horiz
    </span>
  </ng-container>
  <span class="material-symbols-rounded" *ngIf="mission.action_kind === actionKind.openended">sort</span>
  <span class="material-symbols-rounded" *ngIf="!hidePhoto && mission.action_kind === actionKind.photoacquisition">
    photo_camera</span>
  <ng-container *ngIf="
      mission.action_kind === actionKind.grid ||
      mission.action_kind === actionKind.gridcustom ||
      mission.action_kind === actionKind.gridrankscale
    ">
    <igx-icon *ngIf="mission.grid?.grid_kind === gridKind.rank" name="grid_rank" family="suzy"></igx-icon>
    <igx-icon *ngIf="mission.grid?.grid_kind === gridKind.scale" name="grid_scale" family="suzy"></igx-icon>

    <span class="material-symbols-rounded" *ngIf="mission.grid?.grid_kind === gridKind.open">grid_on</span>

  </ng-container>
  <span class="material-symbols-rounded" *ngIf="mission.action_kind === actionKind.auto_assign">people</span>
  <igx-icon *ngIf="mission.action_kind === actionKind.maxdiff" name="maxdiff" family="suzy"></igx-icon>
  <igx-icon *ngIf="mission.action_kind === actionKind.turf" name="turf" family="suzy"></igx-icon>
</ng-container>
